.language-switcher {
    align-items: center;
    color: $white;
    display: flex;
    font-size: 1.2rem;
    height: 4rem;
    margin-left: 2rem;
    position: relative;

    &-current {
        align-items: center;
        color: $white;
        cursor: pointer;
        display: flex;
        padding: .3rem 1.6rem .3rem .3rem;
        will-change: opacity;
        position: relative;
        white-space: nowrap;

        &:after {
            border: 2px solid $white;
            border-top: none;
            border-left: none;
            content: "";
            display: block;
            height: 7px;
            position: absolute;
            right: 0;
            top: 50%;
            transform-origin: 50% 50%;
            transform: translateY(calc(-50% - 2px)) rotate(45deg);
            width: 7px;
        }

        svg {
            border: 2px solid currentColor;
            border-radius: 50%;
            display: none;
            overflow: hidden;
            padding: 3px;
        }

        &:active,
        &:focus,
        &:hover {
            color: $white;
            opacity: .75;
            text-decoration: none;
        }
    }

    &-dropdown {
        background: $white;
        border-radius: $border-radius-small;
        box-shadow: 0 3px 5px rgba(0, 0, 0, .25);
        display: none;
        list-style-type: none;
        margin: 0;
        padding: 1.6rem 2.4rem;
        position: absolute;
        right: 0;
        top: 5.6rem;
        width: 20rem;
        z-index: 100;

        &.is-active {
            display: block;
        }

        &:before {
            border: 5px solid $white;
            border-left-color: transparent;
            border-right-color: transparent;
            border-top-color: transparent;
            content: "";
            height: 10px;
            position: absolute;
            right: 17px;
            top: -10px;
            width: 10px;
        }
    }

    &-item {
        padding: 0;

        a {
            align-items: center;
            color: $gray-medium;
            display: flex;
            font-size: 1.4rem;
            padding: .8rem 0;

            &:active,
            &:focus,
            &:hover {
                color: $color-text;
            }
        }
    }
}

.main-nav {
    &.not-frontpage,
    &.main-nav-half-colors {
        .language-switcher {
            color: $dark;

            &-current {
                color: $dark;

                &:after {
                    border-color: $dark;
                }

                &:active,
                &:focus,
                &:hover {
                    color: $dark;
                }
            }

            &-dropdown {
                box-shadow: 0 3px 20px rgba(0, 0, 0, .25);
            }
        }
    }
}

@media (max-width: 1180px) {
    .language-switcher {  
        margin-left: auto;
    }
}

@media (max-width: 640px) {
    .language-switcher {      
        &-item {    
            a {
                padding: 5px 0;
            }
        }
    }
}