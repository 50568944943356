.channel-lp {
    background: $white;
    overflow-x: hidden;

    &-wrapper {
        max-width: calc(100vw - 20rem);
        width: 100%;
    }

    &-bg {
        margin: 0 -2rem;
        overflow: hidden;
        position: relative;

        &-image {
            background-size: cover;
            background-position: center center;
            filter: blur(15px);
            height: 100%;
            position: absolute;
            transform: scale(1.05);
            width: 100%;

            &-overlay {
                height: 100%;
                position: absolute;
                width: 100%;
            }
        }
    }

    &-intro {
        align-items: flex-end;
        display: flex;
        margin: 0 auto;
        max-width: calc(100vw - 20rem);
        padding: 250px 0 100px 0;
        position: relative;
        z-index: 1;

        .btn {
            align-items: center;
            display: flex;

            svg {
                fill: $white;
                left: -10px;
                position: relative;
                top: 0;
            }

            &:active,
            &:focus,
            &:hover {
                transform: translateY(-2px);
            }
        }
    }

    &-image {
        border-radius: $border-radius-small;
        box-shadow: 0 3px 20px rgba(0, 0, 0, .25);
        height: 280px;
        overflow: hidden;
        position: relative;
        width: 280px;
        z-index: 1;

        img {
            display: block;
            height: 100%;
            object-fit: cover;
            width: 100%;
        }
    }

    &-content {
        padding-left: 80px;
        width: 50%;

        &-title {
            line-height: 1;
            margin: 0 0 25px 0;
        }

        &-desc {
            margin: 25px 0 35px 0;
        }
    }

    &-social {
        font-size: 1.4rem;
        margin-left: 2.5rem;
        margin-top: -.2rem;
        position: relative;

        &-icon {
            align-items: center;
            border: 1px solid $gray;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            height: 3rem;
            width: 3rem;

            & > svg {
                fill: $gray;
            }
        }

        &-tooltip {
            bottom: 3rem;
            left: 50%;
            opacity: 0;
            padding: 0 0 2rem 0;
            pointer-events: none;
            position: absolute;
            text-align: left;
            transform: translateX(-50%) translateY(10px);
            transition: all .25s ease-out;

            &:after {
                border: 1rem solid rgba(0, 0, 0, .66);
                border-left-color: transparent;
                border-right-color: transparent;
                border-bottom-color: transparent;
                bottom: 0rem;
                content: "";
                height: 2rem;
                left: 50%;
                position: absolute;
                transform: translateX(-50%);
                width: 2rem;
            }

            &-wrapper {
                background: rgba(0, 0, 0, .66);
                border-radius: 8px;
                list-style-type: none !important;
                margin: 0;
                padding: 1.5rem 2rem;

                li {
                    color: $white;
                    display: block;
                    list-style-type: none !important;
                    padding: .5rem 0;

                    &:first-child {
                        padding: 0;
                    }

                    a {
                        background-position: 1rem center;
                        background-repeat: no-repeat;
                        background-size: 1.6rem 1.6rem;
                        border-radius: 5px;
                        color: $white;
                        display: block;
                        height: 3rem;
                        line-height: 2rem;
                        margin: 0 -1rem;
                        padding: .5rem 1.5rem .5rem 4rem;
                        overflow: hidden;
                        width: 15rem;

                        &[href*="facebook.com"] {
                            background-image: url('data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2032%2032%22%3E%3Cpath%20%20fill%3D%22%23FFFFFF%22%20d%3D%22M30.234%200H1.766A1.766%201.766%200%200%200%200%201.766v28.468A1.766%201.766%200%200%200%201.766%2032h15.326V19.608h-4.17v-4.83h4.17v-3.56c0-4.134%202.524-6.385%206.21-6.385a34.216%2034.216%200%200%201%203.727.19v4.32h-2.557c-2.005%200-2.394.953-2.394%202.35v3.084h4.78l-.622%204.83h-4.16V32h8.154A1.766%201.766%200%200%200%2032%2030.234V1.766A1.766%201.766%200%200%200%2030.234%200z%22%20%2F%3E%3C%2Fsvg%3E');
                        }

                        &[href*="twitter.com"] { // #686869
                            background-image: url('data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2024.999%2020.316%22%3E%3Cpath%20fill%3D%22%23FFFFFF%22%20d%3D%22M25%202.405a10.253%2010.253%200%200%201-2.947.808A5.144%205.144%200%200%200%2024.308.376%2010.27%2010.27%200%200%201%2021.05%201.62a5.134%205.134%200%200%200-8.74%204.68A14.56%2014.56%200%200%201%201.74.94a5.134%205.134%200%200%200%201.587%206.846%205.108%205.108%200%200%201-2.323-.642v.064a5.132%205.132%200%200%200%204.114%205.03%205.142%205.142%200%200%201-2.316.088%205.134%205.134%200%200%200%204.79%203.562%2010.29%2010.29%200%200%201-6.37%202.2A10.44%2010.44%200%200%201%200%2018.012a14.586%2014.586%200%200%200%2022.454-12.29q0-.333-.015-.662A10.423%2010.423%200%200%200%2025%202.405z%22%20%2F%3E%3C%2Fsvg%3E');
                        }
                        
                        &:hover {
                            background-color: rgba(0, 0, 0, .5);
                            text-decoration: none;
                        }
                    }
                }
            }
        }

        &:hover {
            .channel-lp-social-tooltip {
                opacity: 1;
                pointer-events: auto;
                transform: translateX(-50%) translateY(0);
            }
        }
    }

    &-buttons {
        align-items: center;
        display: flex;
        position: relative;
        z-index: 1;
    }

    &-related-header {
        font-size: 2.8rem!important;
        margin: 6rem 0 2rem 0;
    }

    &-footer {
        .calmradio-related-channels {
            display: flex;
            flex-wrap: wrap;
            margin: 0 -8px;
            padding-bottom: 10rem;
            width: calc(100% + 16px);

            a {
                background-size: cover;
                background-repeat: no-repeat;
                background-position: center center;
                border-radius: $border-radius-small;
                box-shadow: 0 5px 8px rgba(0, 0, 0, .25);
                display: block;
                margin: 8px;
                overflow: hidden;
                width: calc(16.666666% - 16px);

                img {
                    height: auto;
                    width: 100%;
                }

                &:active,
                &:focus,
                &:hover {
                    opacity: .66;
                }
            }
        }
    }

    &[data-header-color="light"] {
        .channel-lp-content-title,
        .channel-lp-content-desc {
            color: $white;
        }

        .channel-lp-social {    
            &-icon {
                border: 1px solid $white;
    
                & > svg {
                    fill: $white;
                }
            }
    
            &-tooltip {   
                &:after {
                    border: 1rem solid rgba(255, 255, 255, .5);
                    border-left-color: transparent;
                    border-right-color: transparent;
                    border-bottom-color: transparent;
                }
    
                &-wrapper {
                    background: rgba(255, 255, 255, .5);
    
                    li {
                        color: $gray;
    
                        a {
                            color: $gray;
    
                            &[href*="facebook.com"] {
                                background-image: url('data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2032%2032%22%3E%3Cpath%20%20fill%3D%22%23686869%22%20d%3D%22M30.234%200H1.766A1.766%201.766%200%200%200%200%201.766v28.468A1.766%201.766%200%200%200%201.766%2032h15.326V19.608h-4.17v-4.83h4.17v-3.56c0-4.134%202.524-6.385%206.21-6.385a34.216%2034.216%200%200%201%203.727.19v4.32h-2.557c-2.005%200-2.394.953-2.394%202.35v3.084h4.78l-.622%204.83h-4.16V32h8.154A1.766%201.766%200%200%200%2032%2030.234V1.766A1.766%201.766%200%200%200%2030.234%200z%22%20%2F%3E%3C%2Fsvg%3E');
                            }
    
                            &[href*="twitter.com"] { // #686869
                                background-image: url('data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2024.999%2020.316%22%3E%3Cpath%20fill%3D%22%23686869%22%20d%3D%22M25%202.405a10.253%2010.253%200%200%201-2.947.808A5.144%205.144%200%200%200%2024.308.376%2010.27%2010.27%200%200%201%2021.05%201.62a5.134%205.134%200%200%200-8.74%204.68A14.56%2014.56%200%200%201%201.74.94a5.134%205.134%200%200%200%201.587%206.846%205.108%205.108%200%200%201-2.323-.642v.064a5.132%205.132%200%200%200%204.114%205.03%205.142%205.142%200%200%201-2.316.088%205.134%205.134%200%200%200%204.79%203.562%2010.29%2010.29%200%200%201-6.37%202.2A10.44%2010.44%200%200%201%200%2018.012a14.586%2014.586%200%200%200%2022.454-12.29q0-.333-.015-.662A10.423%2010.423%200%200%200%2025%202.405z%22%20%2F%3E%3C%2Fsvg%3E');
                            }
                            
                            &:active,
                            &:focus,
                            &:hover {
                                background-color: rgba(255, 255, 255, .5);
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 1800px) {
    .channel-lp {
        padding: 0 2rem;

        &-wrapper {
            max-width: calc(100vw - 10rem);
        }
    
        &-intro {
            max-width: calc(100vw - 10rem);
        }
    }
}

@media (max-width: 1280px) {
    .channel-lp {
        &-footer {
            .calmradio-related-channels {    
                a {
                    width: calc(25% - 16px);
                }
            }
        }

        &-wrapper {
            max-width: calc(100vw - 5rem);
        }
    
        &-intro {
            max-width: calc(100vw - 5rem);
        }

        &-content {
            padding-left: 60px;
            width: calc(100% - 340px);
        }
    }
}

@media (max-width: 1040px) {
    .channel-lp {
        &-intro {
            padding: 180px 0 80px;
        }

        &-footer {
            .calmradio-related-channels {    
                a {
                    width: calc(33.333333% - 16px);
                }
            }
        }

        &-content {
            padding-left: 40px;
            width: calc(100% - 320px);

            .btn-small {
                padding-left: 3rem;
                padding-right: 3rem;
            }
        }
    }
}

@media (max-width: 900px) {
    .channel-lp {
        &-intro {
            flex-wrap: wrap;
            padding: 150px 0 50px;
        }

        &-image {
            margin: 0 auto 5rem auto;
        }

        &-buttons {
            justify-content: center;
        }

        &-content {
            padding: 0;
            text-align: center;
            width: 100%;

            .btn {
                margin: 0;
            }
        }

        &-footer {
            .calmradio-related-channels {
                a {
                    width: calc(33.333333% - 16px);
                }
            }
        }
    }
}

@media (max-width: 720px) {
    .channel-lp {
        &-intro {
            padding: 120px 0 50px;
        }
        &-footer {
            .calmradio-related-channels {    
                a {
                    width: calc(50% - 16px);
                }
            }
        }
    }
}

@media (max-width: 640px) {
    .channel-lp {
        &-related-header {
            font-size: 2rem!important;
            margin: 4rem 0 2rem 0;
        }

        &-footer {
            .calmradio-related-channels {
                a {
                    width: calc(50% - 16px);
                }
            }
        }
    }
}

@media (max-width: 480px) {
    .channel-lp {
        &-footer {
            .calmradio-related-channels {
                a {
                    width: calc(50% - 16px);
                }
            }
        }
    }
}
