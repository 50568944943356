.cr-mobile-menu {
    background: $white;
    box-shadow: 0 0 8px rgba(0, 0, 0, .125);
    height: 100%;
    left: -320px;
    max-width: calc(100% - 75px);
    opacity: 0;
    overflow: auto;
    position: fixed;
    top: 0;
    transition: all .3s ease-out;
    width: 300px;
    z-index: 1000000;

    &.is-open {
        left: 0;
        opacity: 1;
    }

    .logo {
        background: url('../images/logo-dark.png') no-repeat;
        background-size: contain;
        background-position: center left;
        display: block;
        height: 84px;
        margin: -3px 10px 0 10px;
        overflow: hidden;
        position: relative;
        text-indent: -200px;
        top: 2px;
        width: 205px;
    }

    &-nav {
        margin: 0;
        padding: 20px;

        .nav {
            margin: 0;
        }

        li {
            border-bottom: 1px solid $border-light;

            a {
                color: $color-text;
                display: block;
                padding: 10px 0;

                &:active,
                &:focus,
                &:hover {
                    color: $blue;
                }

                &.btn {
                    color: $white;
                    text-decoration: none!important;
                }
            }

            &:last-child {
                border-bottom: none;
            }

            &:last-child {
                margin: 0 0 3rem 0;
            }
        }
    }
}

@media (min-width: 1181px) {
    .cr-mobile-menu {
        left: -320px!important;
        opacity: 0!important;
    }
}