.heading-popup {
    background: $white;
    box-shadow: 0 0 20px rgba(0, 0, 0, .25);
    display: none;
    height: calc(100vh - 8rem);
    left: 4rem;
    opacity: 0;
    padding: 6rem 9rem;
    position: fixed;
    top: 4rem;
    transform: scale(.5);
    transition: all .25s ease-out;
    width: calc(100vw - 8rem);
    will-change: opacity, transform;
    z-index: 101;

    &.is-active {
        opacity: 1;
        transform: scale(1);
    }

    &-title {
        font-size: 1.8rem;
        font-weight: 400;
        line-height: 1;
        margin: 0 0 3rem 0;

        a {
            color: $color-text-light;
            font-size: 1.2rem;
            font-weight: 300;
            line-height: 1.4;
            margin-left: 1rem;
            text-transform: uppercase;

            &:active,
            &:focus,
            &:hover {
                color: $blue;
                text-decoration: none;
            }
        }
    }

    &-option {
        padding: .5rem 0;

        label {
            display: inline-block;
            font-size: 1.3rem;
            width: 12rem;
        }

        &-color {
            cursor: pointer;
            opacity: 0;
            width: 24px;
        }
    }

    &-close {
        height: 2.4rem;
        position: absolute;
        right: 9rem;
        top: 6rem;
        width: 2.4rem;

        &:before,
        &:after {
            background: $color-text-light;
            content: "";
            height: 1px;
            position: absolute;
            top: 50%;
            transform: rotate(-45deg);
            transform-origin: center center;
            width: 2.4rem;
        }

        &:after {
            transform: rotate(45deg);
        }

        &:hover {
            &:before,
            &:after {
                background: $blue;
            }
        }
    }

    &-videos {
        align-items: flex-start;
        display: flex;
        flex-wrap: wrap;
        max-height: calc(100vh - 36rem);
        margin: 4rem -2rem 0 -2rem;
        overflow: auto;

        figure {
            margin-bottom: 6rem;
            padding: 0 2rem;
            position: relative;
            width: 16.666666%;

            img {
                border-radius: 6px;
                cursor: pointer;
                left: 2rem;
                max-width: 100%;
                position: absolute;
                top: 0;
                transition: opacity .5s ease-out;
                width: calc(100% - 4rem);
                z-index: 1;

                &.is-inactive {
                    opacity: 0.01;
                }

                &.is-active {
                    & + .heading-popup-videos-placeholder {
                        background-color: rgba(0, 0, 0, .5);
                        border-radius: 6px;
                        z-index: 2;

                        &:after {
                            content: "";
                            background-image: url('data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20fill%3D%22%23ffffff%22%20d%3D%22M17.235%2C9.964L8.059%2C3.395C7.7%2C3.157%2C7.344%2C3%2C6.912%2C3C5.856%2C3%2C5%2C3.865%2C5%2C4.932v13.136C5%2C19.135%2C5.856%2C20%2C6.912%2C20c0.432%2C0%2C0.795-0.166%2C1.147-0.386l9.176-6.577C17.697%2C12.684%2C18%2C12.13%2C18%2C11.5C18%2C10.87%2C17.697%2C10.316%2C17.235%2C9.964z%22%2F%3E%3C%2Fsvg%3E');
                            height: 24px;
                            left: 50%;
                            position: absolute;
                            top: 50%;
                            transform: translateX(-50%) translateY(-50%);
                            width: 24px;
                        }
                    }

                    &:hover + .heading-popup-videos-placeholder {
                        background-color: rgba(0, 0, 0, 0);
                    }
                }
            }

            figcaption {
                color: $color-text-light;
                font-size: 1.3rem;
                font-weight: 400;
                line-height: 1;
                padding: 2rem 0 0 0;
            }
        }

        &-placeholder {
            background-color: $color-bg-light;
            border-radius: 6px;
            display: block;
            padding-bottom: 56.25%;
            position: relative;
            pointer-events: none;
            transition: all .5s ease-out;
        }
    }
}

.heading-configure-button {
    align-items: center;
    border: 1px solid rgba(255, 255, 255, .6);
    border-radius: $border-radius-full;
    bottom: 6rem;
    display: flex;
    height: 3.6rem;
    justify-content: center;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    right: 23.85rem;
    transition: opacity .5s ease-out;
    width: 3.6rem;
    will-change: opacity;
    z-index: 96;

    &.is-active {
        opacity: 1;
        pointer-events: auto;
    }

    & > svg {
        fill: $white;
    }

    &:active,
    &:focus,
    &:hover {
        border: 1px solid rgba(255, 255, 255, 1);
    }
}

.cr-player-external-ui + .heading-configure-button.is-active {
    opacity: 0;
}

.cr-player-external-ui.is-active + .heading-configure-button.is-active {
    opacity: 1;
}

.colorpicker {
    &-preview {
        background-clip: content-box;
        border: 1px solid $border;
        border-radius: 50%;
        display: inline-block;
        height: 24px;
        left: -2px;
        margin-right: -30px;
        padding: 2px;
        pointer-events: none;
        position: relative;
        top: 6px;
        width: 24px!important;

        & + input {
            text-indent: 25px;
        }
    }
}

.cp {
    &-color-picker {
        background: #fff;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
        position: absolute;
        overflow: hidden;
        padding: 0;
        color: #bbb;
        height: 220px;
        font-size: 12px;
        font-weight: 400;
        cursor: default;
        margin-bottom: 2px!important;
        width: 220px;
        z-index: 1000;
    }

    &-alpha{
        display: none;
    }

    &-white{
        height:100%;
        width:100%;
        background:linear-gradient(rgba(0,0,0,0),#000);
    }

    &-xy {
        &-slider {
            background: linear-gradient(
                    to right,
                    #FFF,
                    rgba(255, 255, 255, 0)
            );
            float: left;
            height: 125px;
            width: 220px;

            &:active {
                cursor:none;
            }
        }

        &-cursor {
            background: transparent;
            border: 2px solid #fff;
            border-radius: 50%;
            height: 16px;
            margin: -8px 0 0 -8px;
            position: absolute;
            top: 0;
            width: 16px;
        }
    }

    &-z {
        &-slider{
            background: linear-gradient(
                    to right,
                    red 0,
                    #f0f 17%,
                    #00f 33%,
                    #0ff 50%,
                    #0f0 67%,
                    #ff0 83%,
                    red 100%
            );
            border-radius: 7px;
            float:right;
            height:14px;
            left: 20px;
            position: absolute;
            top: 145px;
            width:180px;
        }

        &-cursor{
            background: #fff;
            border-radius: 50%;
            box-shadow: 0 0 2px rgba(0, 0, 0, .5);
            height: 16px;
            margin: -1px 0 0 -8px;
            position:absolute;
            width:16px;
        }
    }

    &-panel,
    &-panel input {
        color: #333;
        font-size: 16px;
    }

    &-panel {
        left: 50%;
        margin-left: -80px;
        position: absolute;
        top: 175px;

        &-hidden {
            display: none;
        }

        .cp-HEX {
            border: 1px solid $border;
            box-shadow: none;
            line-height: 24px;
            padding: 3px;
            text-align: center;
            width:160px;
        }
    }
}

@media (max-width: 1400px) {
    .heading-popup {
        height: calc(100vh - 4rem);
        left: 2rem;
        padding: 3rem 3rem;
        top: 2rem;
        width: calc(100vw - 4rem);
    }

    .heading-popup-close {
        right: 3rem;
        top: 3rem;
    }

    .heading-popup-title {
        margin-bottom: 1rem;
    }

    .heading-popup-videos {
        max-height: calc(100vh - 22rem);
        margin: 2rem -1rem 0;
    }
}

@media (max-width: 1280px) {
    .heading-popup-videos figure {
        margin-bottom: 3rem;
        padding: 0 1rem;
    }

    .heading-popup-videos figure img {
        left: 1rem;
        width: calc(100% - 2rem);
    }

    .heading-popup-videos figure {
        width: 25%;
    }
}
