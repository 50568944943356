.couponize-screen {
    align-items: center;
    background: $blue;
    display: flex;
    justify-content: center;
    height: 100vh;
    min-height: 450px;
    position: relative;
    
    &-wrapper {
        padding: 0 2rem;
        position: relative;
        text-align: center;   
        width: 100%;
        z-index: 1;
    }

    &-title {
        color: $white;
    }

    &-desc {
        color: $white;
        font-size: 2.2rem;
        margin: 3rem auto 6rem auto;
        max-width: 640px;
        width: 100%;
    }

    .btn {
        border-color: $white;
        &:hover,
        &:active,
        &:focus {
            color: inherit;
        }
    }

    .special-page-bg {
        max-width: 1700px;
        opacity: .666666;
    }

    
    /*
     * Module CalmRadio Activation Screen Button - related CSS code
     */
    .mod-calmradio-couponize-screen-button {
        &-btn {
            border: none;
            margin: 20px 0 12px 0;
        }

        &-disclaimer {
            color: $white;
            display: block;
        }
    }
}

@media (max-width: 720px) {
    .couponize-screen-desc {
        font-size: 1.6rem;
        margin: 2rem auto 2rem;
    }
}
