.heading-frontpage.hide-ui {
    .cr-player,
    .cr-player-external-ui,
    .heading-configure-button {
        opacity: 0!important;
    }
}

.heading-frontpage.has-top-promo {
    .cr-player {
        height: calc(100% - 3.5rem);
    }

    .top-promotions {
        background: rgba(235,235,235,0.2)!important;
    }
}

.cr-player {
    height: 100%;
    left: 50%;
    max-width: 100%;
    opacity: 1;
    padding-top: 17.5rem;
    position: absolute;
    transform: translateX(-50%);
    transition: opacity 1s ease-out;
    width: 1600px;
    will-change: opacity;
    z-index: 95;

    &-album {
        margin: 0 auto;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        transition: opacity .25s ease-out;
        width: 100%;
        will-change: opacity;

        &.is-transparent {
            opacity: .25;
        }

        &-cover {
            margin: 0 auto;
            opacity: 0;
            position: relative;
            transform: scale(.75);
            transition: all .25s ease-out;
            width: 37.5vh;
            z-index: 2;

            &.is-visible {
                opacity: 1;
                transform: scale(1);
            }

            &-image {
                border-radius: $border-radius-small;
                box-shadow: 0 -2rem 5rem rgba(0, 0, 0, .25);
                display: block;
                height: 37.5vh;
                margin: 0 auto;
                overflow: hidden;
                position: relative;
                width: auto;
                z-index: 2;

                &-replacement {
                    border-radius: $border-radius-small;
                    display: block;
                    height: 37.5vh;
                    left: 50%;
                    margin: 0 auto;
                    opacity: 1;
                    overflow: hidden;
                    position: absolute;
                    transition: all .5s ease-out;
                    transform: translateX(-50%);
                    top: 0;
                    width: auto;
                    z-index: 3;

                    &.is-hidden {
                        opacity: 0;
                        visibility: hidden;
                    }
                }
            }
        }

        &-loader {
            border: 1px solid transparent;
            height: 64px;
            left: 50%;
            position: absolute;
            top: 50%;
            transform: translateX(-50%) translateY(-50%);
            width: 64px;

            svg {
                display: none!important;
            }

            &:before {
                animation: content-loader 0.8s ease-in-out infinite;
                border-radius: 50%;
                border: 2px solid rgba(255, 255, 255, .5);
                border-top-color: $white;
                content: '';
                height: 64px;
                left: 0;
                position: absolute;
                top: 0;
                width: 64px;
            }
        }

        &-fav,
        &-hidden,
        &-like,
        &-dislike {
            align-items: center;
            appearance: none;
            border: none;
            background: rgba(0,0,0,0.2);
            display: flex;
            height: 36px;
            justify-content: center;
            width: 36px;
            z-index: 4;

            & > svg {
                fill: $white;
            }

            &:active,
            &:focus,
            &:hover {
                opacity: .75;
                transform: none;
            }

            &.is-hidden {
                display: none;
            }
        }

        &-hidden {
            display: inline-flex;
            margin-left: 1rem;
            margin-top: -.3rem;

            & > svg {
                position: relative;
                top: 1px;

                &.is-border {
                    display: block;
                }

                &.is-solid {
                    display: none;
                }
            }

            &.is-hidden-channel {
                & > svg {
                    &.is-border {
                        display: none;
                    }

                    &.is-solid {
                        display: block;
                    }
                }
            }
        }

        &-fav {
            display: inline-flex;
            margin-left: 1rem;
            margin-top: -.3rem;

            & > svg {
                position: relative;
                top: 1px;

                &.is-border {
                    display: block;
                }

                &.is-solid {
                    display: none;
                }
            }

            &.is-favorited {
                & > svg {
                    &.is-border {
                        display: none;
                    }

                    &.is-solid {
                        display: block;
                    }
                }
            }
        }

        &-like {
            bottom: -5rem;
            left: calc(50% - 4rem);
            position: absolute;

            & > svg {
                position: relative;
                top: 1px;

                &.is-border {
                    display: block;
                }

                &.is-solid {
                    display: none;
                }
            }

            &.is-liked {
                & > svg {
                    cursor: auto;

                    &.is-border {
                        display: none;
                    }

                    &.is-solid {
                        display: block;
                    }
                }
            }
        }

        &-dislike {
            bottom: -5rem;
            left: calc(50% + .4rem);
            position: absolute;

            & > svg {
                position: relative;
                top: 1px;

                &.is-border {
                    display: block;
                }

                &.is-solid {
                    display: none;
                }
            }

            &.is-disliked {
                & > svg {
                    cursor: auto;

                    &.is-border {
                        display: none;
                    }

                    &.is-solid {
                        display: block;
                    }
                }
            }
        }

        // HIDE like/dislike
        &-like,
        &-dislike {
            display: none;
        }

        &-info {
            margin: 0 0 3rem 0;
            opacity: .8;

            p {
                color: $white;
                font-size: 1.3rem;
                font-weight: 400;
                margin: 0;
                text-align: center;
                text-shadow: none;
                -webkit-font-smoothing: subpixel-antialiased;

                strong {
                    display: inline-block;
                    margin-bottom: .5rem;

                    &:empty {
                        & ~ button {
                            opacity: 0;
                        }
                    }
                }

                &.is-hidden {
                    display: none;
                }

                &:first-child {
                    font-size: 2rem;
                    margin-bottom: 2rem;
                }
            }
        }

        &:after {
            background: transparent url('../images/waves.png') no-repeat center center;
            content: "";
            max-width: 100%;
            padding-bottom: 23.4375%;
            position: absolute;
            top: 70%;
            transform: translateY(-70%);
            width: 1600px;
            z-index: 1;
        }
    }

    &-multimixer,
    &-play,
    &-share,
    &-sleep,
    &-bitrate,
    &-fullscreen,
    &-switch {
        align-items: center;
        appearance: none;
        background: transparent;
        border: 1px solid rgba(255, 255, 255, .6);
        border-radius: 50%;
        display: flex;
        height: 36px;
        justify-content: center;
        left: 50%;
        margin-left: -18px;
        position: absolute;
        width: 36px;
        z-index: 1;

        &:active,
        &:focus,
        &:hover {
            border-color: $white;
            transform: none;
        }

        & > svg {
            fill: $white;
        }

        &.is-hidden {
            opacity: 0;
            pointer-events: none;
        }
    }

    &-fullscreen {
        margin-left: -18px;
    }

    &-bitrate {
        margin-left: -64px;
    }

    &-sleep {
        margin-left: -110px;
    }

    &-audio {
        display: none;
    }

    &-multimixer {
        left: 0;
        margin-left: 0;
        top: 24px;

        .is-hidden {
            display: none;
            visibility: hidden;
        }

        &-popup {
            bottom: 0;
            color: $white;
            font-size: 1.6rem;
            font-weight: 300;
            left: 16px;
            opacity: 0;
            pointer-events: none;
            position: absolute;
            transform: translateX(-50%) translateY(20px);
            transition: all .25s ease-out;
            visibility: hidden;
            width: 540px;

            &-wrapper {
                background: rgba(255, 255, 255, .2);
                border-radius: $border-radius-small;
                margin-bottom: 60px;
                padding: 2.5rem;
                position: relative;
                text-align: left;
                width: 100%;

                &:after {
                    border: 1rem solid rgba(255, 255, 255, .2);
                    border-left-color: transparent;
                    border-right-color: transparent;
                    border-bottom-color: transparent;
                    bottom: -2rem;
                    content: "";
                    height: 2rem;
                    left: 50%;
                    position: absolute;
                    transform: translateX(-50%);
                    width: 2rem;
                }
            }

            &.is-hidden {
                opacity: 0;
                pointer-events: none;
                transform: translateX(-50%) translateY(-20px);
                visibility: hidden;
            }

            strong {
                display: block;
                font-size: 1.8rem;
                font-weight: 400;
                margin-bottom: 2rem;
            }

            &-item {
                align-items: center;
                display: flex;
                margin: 2rem 0;
                position: relative;

                &-cover {
                    border-radius: $border-radius-small;
                    height: 6rem;
                    margin-right: 3.2rem;
                    position: relative;
                    width: 7rem;

                    img {
                        border-radius: $border-radius-small;
                        height: 6rem;
                        margin: 0;
                        width: 7rem;
                    }

                    svg {
                        display: none;
                    }

                    &-remove {
                        background: $white;
                        border: 1px solid $red;
                        border-radius: $border-radius-full;
                        cursor: pointer;
                        display: none;
                        height: 2rem;
                        position: absolute;
                        right: -1rem;
                        top: -1rem;
                        width: 2rem;
                        z-index: 1;

                        &:after {
                            background: $red;
                            content: "";
                            display: block;
                            height: .2rem;
                            left: .4rem;
                            position: absolute;
                            top: .8rem;
                            width: 1rem;
                        }
                    }

                    &:hover {
                        .cr-player-multimixer-popup-item-cover-remove {
                            display: block;
                        }
                    }

                    &.is-empty {
                        border: 1px solid rgba(255, 255, 255, .7);
                        cursor: pointer;
                        position: relative;

                        svg {
                            border: 1px solid $white;
                            border-radius: $border-radius-full;
                            display: block;
                            fill: $white;
                            height: 2rem;
                            left: 50%;
                            opacity: .7;
                            padding: .2rem;
                            position: absolute;
                            top: 50%;
                            transform: translateX(-50%) translateY(-50%);
                            transition: all .25s ease-out;
                            width: 2rem;
                        }

                        &:hover {
                            border: 1px solid $white;

                            svg {
                                opacity: 1;
                                transform: translateX(-50%) translateY(-50%) scale(1.2);
                            }
                        }

                        .cr-player-multimixer-popup-item-cover-remove {
                            display: none!important;
                        }

                        & + .cr-player-multimixer-popup-item-config {
                            & > .rangeslider {
                                opacity: .5;

                                .rangeslider__fill,
                                .rangeslider__handle {
                                    opacity: 0;
                                    pointer-events: none;
                                }

                                .rangeslider,
                                .rangeslider__fill {
                                    box-shadow: none;
                                }
                            }
                        }
                    }
                }

                &-config {
                    width: calc(100% - 10.4rem);

                    .rangeslider {
                        width: 100%;
                    }

                    .rangeslider,
                    .rangeslider__fill {
                        box-shadow: none;
                    }
                }

                &-name {
                    display: block;
                }

                &-range {
                    width: 100%;
                }
            }
        }
    }

    &-multimixer:hover {
        .cr-player-multimixer-popup {    
            opacity: 1;
            pointer-events: auto;
            transform: translateX(-50%) translateY(0);
            visibility: visible;
        }
    }

    &-share:hover {
        .cr-player-share-popup {    
            opacity: 1;
            pointer-events: auto;
            transform: translateX(-50%) translateY(0);
            visibility: visible;
        }
    }

    &-bitrate:not(.is-free-user):hover {
        .cr-player-bitrate-popup {    
            opacity: 1;
            pointer-events: auto;
            transform: translateX(-50%) translateY(0);
            visibility: visible;
        }
    }

    &-sleep:hover {
        .cr-player-sleep-popup {    
            opacity: 1;
            pointer-events: auto;
            transform: translateX(-50%) translateY(0);
            visibility: visible;
        }
    }

    &-play {
        border: 2px solid $white;
        height: 64px;
        margin-left: -32px;
        top: 10px;
        width: 64px;

        svg {
            stroke: $white;
            transform: translateX(2px) scale(1.325);

            &.is-hidden {
                display: none;
                visibility: hidden;
            }

            &.cr-player-play-icon-pause {
                transform: translateX(0) scale(1.325);
            }
        }

        &.is-loading {
            border: 1px solid transparent;
            height: 64px;
            position: relative;
            width: 64px;

            svg {
                display: none!important;
            }

            &:before {
                animation: content-loader 0.8s ease-in-out infinite;
                border-radius: 50%;
                border: 2px solid rgba(255, 255, 255, .5);
                border-top-color: $white;
                content: '';
                height: 64px;
                left: -1px;
                position: absolute;
                top: -1px;
                width: 64px;
            }
        }

        &.is-inactive {
            opacity: .5;
            pointer-events: none;
        }
    }

    &-share-popup,
    &-sleep-popup,
    &-bitrate-popup {
        bottom: 0;
        color: $white;
        font-size: 1.6rem;
        font-weight: 300;
        left: 50%;
        opacity: 0;
        pointer-events: none;
        position: absolute;
        transform: translateX(-50%) translateY(20px);
        transition: all .25s ease-out;
        visibility: hidden;
        width: 230px;

        &-wrapper {
            background: rgba(255, 255, 255, .2);
            border-radius: $border-radius-small;
            margin-bottom: 60px;
            padding: 2.5rem;
            position: relative;
            text-align: left;
            width: 100%;

            &:after {
                border: 1rem solid rgba(255, 255, 255, .2);
                border-left-color: transparent;
                border-right-color: transparent;
                border-bottom-color: transparent;
                bottom: -2rem;
                content: "";
                height: 2rem;
                left: 50%;
                position: absolute;
                transform: translateX(-50%);
                width: 2rem;
            }
        }

        strong {
            font-size: 1.8rem;
            font-weight: 400;
        }

        p {
            border-radius: $border-radius-small;
            cursor: pointer;
            font-size: 1.6rem;
            font-weight: 300;
            margin: .75rem -1.25rem;
            padding: .75rem 1.25rem .75rem 4.85rem;
            position: relative;
            transition: all .25s ease-out;

            &:hover {
                background: rgba(255, 255, 255, .2);
            }

            svg {
                fill: $white;
                left: 1.4rem;
                position: absolute;
                top: 48%;
                transform: translateY(-50%);
            }

            & > .cr-player-sleep-in,
            & > .cr-player-bitrate-in {
                border: 1px solid $white;
                border-radius: $border-radius-full;
                display: block;
                height: 1.8rem;
                left: 1.25rem;
                position: absolute;
                top: 1rem;
                width: 1.8rem;

                &.is-active {
                    &:after {
                        background: $white;
                        border-radius: $border-radius-full;
                        content: "";
                        height: 1rem;
                        left: 3px;
                        position: absolute;
                        top: 3px;
                        width: 1rem;
                    }
                }
            }
        }
    }

    &-share {
        left: auto;
        right: 0;
        top: 24px;
        top: 24px;
    }

    &-switch {
        border: none;
        left: calc(50% + 60px);
        top: 24px;

        &-prev {
            left: calc(50% - 60px);
        }

        &.is-inactive {
            opacity: .5;
            pointer-events: none;
        }
    }

    &-ui {
        bottom: 6rem;
        height: 60px;
        left: 50%;
        margin: 0 auto;
        position: absolute;
        transform: translateX(-50%);
        width: 600px;
        z-index: 5;
    }

    &-external-ui {
        bottom: 6rem;
        height: 36px;
        opacity: 1;
        position: absolute;
        right: 10rem;
        transition: opacity 1s ease-out;
        width: 36px;
        will-change: opacity;
        z-index: 96;
    }

    &-ui,
    &-external-ui {
        opacity: 0;
        pointer-events: none;
        transition: opacity .5s ease-out;
        will-change: opacity;

        &.is-active {
            opacity: 1;
            pointer-events: auto;
        }
    }

    &-email-sharing {
        background: rgba(0, 0, 0, .66);
        height: 100%;
        left: 0;
        opacity: 1;
        pointer-events: auto;
        position: fixed;
        top: 0;
        transition: all .25s ease-out;
        width: 100vw;
        z-index: 1000;

        &.is-hidden {
            opacity: 0;
            pointer-events: none;
            visibility: hidden;

            .cr-player-email-sharing-form {
                transform: translateX(-50%) translateY(-50%);
            }
        }

        &-form {
            background: $white;
            border-radius: $border-radius-small;
            left: 50%;
            padding: 5rem;
            position: absolute;
            top: 50%;
            transform: translateX(-50%) translateY(-40%);
            transition: all .25s ease-out;
            width: 640px;

            h2 {
                font-size: 3.2rem;
                margin: 0 0 2rem 0;
            }

            .btn-send {
                float: right;
            }

            .cr-player-email-sharing-track-info {
                overflow: hidden;
                padding: 20px 0 35px 0;

                dt {
                    float: left;
                    margin-right: 10px;

                    img {
                        height: 64px;
                        width: 64px;
                    }
                }
            }

            .error-message {
                color: $color-danger;
                display: block;
                font-size: 1.2rem;
                text-align: right;

                &.is-hidden {
                    display: none;
                    visibility: hidden;
                }
            }
        }
    }

    &-download-app {
        display: none;
        padding: 0 2rem;
        position: relative;
        z-index: 5;

        &-title {
            color: $white;
            font-size: 4rem;
            text-align: center;
        }

        &-desc {
            color: $white;
            font-size: 2rem;
            text-align: center;
        }

        &-links {
            margin-top: 3rem;
            text-align: center;

            a {
                display: inline-block;
                margin-right: 2rem;

                img {
                    display: block;
                    height: 4.2rem;
                    width: auto;
                }
            }
        }
    }

    &-loading-error,
    &-tunein-error {
        align-content: center;
        background: rgba(0, 0, 0, .85);
        bottom: 0;
        display: flex;
        justify-content: center;
        left: 0;
        top: 0;
        position: absolute;
        width: 100%;
        z-index: 99;

        &.is-hidden {
            display: none;
        }

        &-wrapper {
            align-content: center;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
        }

        &-heading {
            color: $white;
            display: block;
            font-weight: normal;
            margin: 0 0 3rem 0;
            text-align: center;
            width: 100%;
        }
    }

    &-seeking-bar {
        cursor: pointer;
        display: none;
        height: 24px;
        overflow: hidden;
        pointer-events: none;
        position: absolute;
        width: 200px;

        &:after {
            background: rgba(255, 255, 255, .5);
            border-radius: 2px;
            content: '';
            height: 4px;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            width: 100%;
        }

        &-progress {
            background: $white;
            border-radius: 2px;
            height: 4px;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            width: auto;
        }
    }

    &-ui.is-in-story-mode {
        .cr-player-seeking-bar {
            display: block;
            left: -50px;
            pointer-events: auto;
            top: -50px;
            transform: none;
            width: calc(100% + 100px);
        }
    }
}

body.player-not-loaded {
    .cr-player {
        &-album-loader {
            display: none;
        }

        &-download-app {
            display: block;
        }

        &-album:after {
            padding-bottom: 300px;
            top: 50%;
            transform: translateY(-50%);
        }
    }

    .cr-player-album-loader {
        &:after {
            content: "";
            display: none;
        }
    }

    .cr-player-album-info,
    .cr-player-album-cover,
    .cr-player-ui,
    .cr-player-external-ui,
    .heading-configure-button {
        display: none;
    }

    .heading-frontpage.hide-ui .cr-player,
    .heading-frontpage.hide-ui .cr-player-external-ui,
    .heading-frontpage.hide-ui .heading-configure-button {
        opacity: 1!important;
    }
}

@keyframes content-loader {
    to {
        transform: rotate(360deg);
    }
}

@media (max-width: 1440px) {
    .cr-player-external-ui {
        right: 4rem;
    }

    .heading-configure-button {
        right: 17.75rem;
    }
}

@media (max-width: 1280px) {
    .cr-player-ui {
        width: 450px;
    }
}

@media (max-width: 1020px) {
    .cr-player-ui {
        width: 440px;
    }

    .cr-player-external-ui {
        right: 3.5rem;
    }

    .heading-configure-button {
        right: 17.25rem;
    }
}

@media (max-height: 800px) {
    .cr-player {
        padding-top: 9rem;
    }

    body.player-loaded {
        .cr-player-album {
            top: 42.5%;
        }
    }

    .cr-player-ui,
    .heading-configure-button,
    .cr-player-external-ui {
        bottom: 3rem;
    }
}

@media (max-height: 700px) {
    body.player-loaded {
        .cr-player-album {
            top: 45%;
        }
    }
}

@media (max-height: 640px) {
    .cr-player-album-info p {
        font-size: 1.1rem;
    }

    .cr-player-album-info p:first-child {
        font-size: 1.6rem;
        margin-bottom: 1rem;
    }

    .cr-player-album-info {
        margin-bottom: 1rem;
    }
}

@media (max-height: 580px) {
    .cr-player-ui,
    .heading-configure-button,
    .cr-player-external-ui {
        bottom: 2rem;
    }

    .cr-player-album-cover,
    .cr-player-album-cover-image {
        height: 25vh;
    }

    .cr-player-multimixer-popup {
        font-size: 1.3rem;
        padding: 1.5rem;
    }

    .cr-player-multimixer-popup-item {
        margin: 1rem 0;
    }

    .cr-player-multimixer-popup-item-cover,
    .cr-player-multimixer-popup-item-cover img {
        height: 4rem;
        width: 4rem;
    }
}

@media (max-height: 480px) {
    body.player-loaded {
        .heading {
            min-height: 480px;
        }
    }
}

@media (max-width: 900px) {
    .cr-player {
        &-album-loader {
            display: none;
        }

        &-download-app {
            display: block;
        }

        &-album:after {
            padding-bottom: 300px;
            top: 50%;
            transform: translateY(-50%);
        }
    }

    .cr-player-album-loader {
        &:after {
            content: "";
            display: none;
        }
    }

    .cr-player-album-info,
    .cr-player-album-cover,
    .cr-player-ui,
    .cr-player-external-ui,
    .heading-configure-button {
        display: none;
    }

    .heading-frontpage.hide-ui .cr-player,
    .heading-frontpage.hide-ui .cr-player-external-ui,
    .heading-frontpage.hide-ui .heading-configure-button {
        opacity: 1!important;
    }
}

@media (max-width: 600px) {
    .cr-player {
        &-download-app {
            &-title {
                font-size: 3rem;
            }

            &-desc {
                font-size: 1.4rem;
            }

            &-links {
                a {
                    margin: 1rem;
                }
            }
        }
    }
}

@media (max-width: 480px), (max-height: 480px) {
    .cr-player {
        &-download-app {
            padding-left: 1rem;
            padding-right: 1rem;

            &-title {
                font-size: 2rem;
            }

            &-desc {
                font-size: 1.4rem;
            }

            &-links {
                margin-top: 1rem;

                a {
                    margin: .75rem;

                    img {
                        display: block;
                        height: 40px;
                        width: auto;
                    }
                }
            }
        }
    }
}
