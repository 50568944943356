body {
    .mod-login {
        .pane {
            display: none;
        }
    }
}

.mod-calmradio-login {
    background: $white;
    box-shadow: 0 -.5rem 4rem rgba(0, 0, 0, .065);
    border-radius: $border-radius-small;
    left: 50%;
    padding: 4rem 0 0 0;
    position: absolute;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    transition: all .25s ease-out;
    visibility: visible;
    width: 60rem;
    z-index: 1;

    .mod-calmradio-login-header {
        padding: 0 4rem;
    }

    #login_form {
        padding: 4rem 4rem 12.2rem 4rem;
    }

    #login_form,
    .username-reminder-form,
    .password-reminder-form {
        overflow: hidden;
        position: relative;

        .grecaptcha-badge {
            bottom: 118px!important;
            position: absolute!important;
            visibility: visible!important;
        }

        .nog-captcha-container {
            align-items: center;
            display: flex;
            margin-top: 4rem;

            img {
                height: 46px;
                margin: 0 1rem;
            }

            &.loading {
                img {
                    opacity: .125;
                }
            }

            input.is-invalid {
                background: rgba(red($color-danger), green($color-danger), blue($color-danger), .1);
                border-bottom-color: $color-danger!important;
            }

            input.is-loading {
                opacity: .5;
                pointer-events: none;
            }

            .help-block.form-error {
                display: none;
            }
        }
    }

    .username-reminder-form,
    .password-reminder-form {
        margin: 0 0 -8rem 0!important;
        padding-bottom: 13rem;

        .grecaptcha-badge {
            bottom: 55px!important;
            left: 50%;
            transform: translateX(-50%);
        }
    }

    &.is-register {
        padding: 4rem 0 0 0;

        .mod-calmradio-login-header {
            padding: 0 4rem;
        }

        #register_form {
            overflow: hidden;
            padding: 0 4rem 12.2rem 4rem;
            position: relative;

            .grecaptcha-badge {
                bottom: 118px!important;
                position: absolute!important;
            }

            .nog-captcha-container {
                align-items: center;
                display: flex;
                margin-top: 4rem;

                img {
                    height: 46px;
                    margin: 0 1rem;
                }

                &.loading {
                    img {
                        opacity: .125;
                    }
                }

                input.is-invalid {
                    background: rgba(red($color-danger), green($color-danger), blue($color-danger), .1);
                    border-bottom-color: $color-danger!important;
                }

                input.is-loading {
                    opacity: .5;
                    pointer-events: none;
                }

                .help-block.form-error {
                    display: none;
                }
            }
        }
    }

    &.is-hidden {
        opacity: 0;
        pointer-events: none;
        transform: translateX(-50%) translateY(-40%);
        visibility: hidden;
        z-index: 0;
    }

    &-message {
        background: $color-danger;
        border-radius: $border-radius-small;
        color: $white;
        font-weight: bold;
        margin-top: 1rem;
        padding: 1rem 2rem;

        &-success {
            background: $green;
        }

        &.is-hidden {
            display: none;
            visibility: hidden;
        }

        span[data-action] {
            cursor: pointer;
            text-decoration: underline;

            &:active,
            &:focus,
            &:hover {
                opacity: .66;
            }
        }
    }

    &-button {
        padding-left: 6.4rem;
        padding-right: 6.4rem;

        .mod-calmradio-login-button-state-normal {
            display: inline;
        }

        .mod-calmradio-login-button-state-loading {
            display: none;
        }

        &.is-loading {
            opacity: .5;
            pointer-events: none;

            .mod-calmradio-login-button-state-normal {
                display: none;
            }

            .mod-calmradio-login-button-state-loading {
                display: inline;
            }
        }
    }

    &-header {
        font-size: 2.8rem;
        font-weight: 400;
        line-height: 1;
        margin: 0 0 2rem 0;
        text-align: left;
    }

    &-input {
        padding: 2.5rem 0 0 0;

        input.is-invalid {
            background: rgba(red($color-danger), green($color-danger), blue($color-danger), .1);
            border-bottom-color: $color-danger!important;
        }

        input.is-loading {
            opacity: .5;
            pointer-events: none;
        }
    }

    &-radio-buttons {
        display: flex;

        .radio-button + .radio-button {
            margin-left: 4rem;
        }
    }

    &-links {
        display: flex;
        justify-content: space-between;
        padding: 4rem 0;

        .checkbox {
            width: 40%;
        }

        a {
            width: 28%;
        }
    }

    &-terms {
        padding: 4rem 0 2rem;

        & > div {
            padding: .5rem 0;
        }
    }

    &-register,
    &-social-disclaimer {
        bottom: -7rem;
        font-weight: 400!important;
        left: 0;
        position: absolute;
        text-align: center;
        width: 100%;

        strong {
            font-weight: 400;
        }
    }

    &-social-disclaimer {
        bottom: -3rem;
        color: $gray-medium;
        font-size: 1.2rem;
    }

    &-social {
        bottom: 0;
        display: flex;
        left: 0;
        position: absolute;
        right: 0;

        &.is-loading {
            opacity: .5;
            pointer-events: none;
        }
    }

    &-with-fb,
    &-with-google {
        align-items: center;
        background: $color-fb;
        border-radius: 0 0 0 $border-radius-small;
        color: $white;
        display: flex;
        font-weight: 500;
        height: 6.2rem;
        justify-content: center;
        padding: 0 2rem;
        width: 50%;

        &:active,
        &:focus,
        &:hover {
            background: lighten($color-fb, 10%);
            color: $white;
            text-decoration: none;
        }

        svg {
            fill: $white;
            margin-right: 2rem;
        }
    }

    &-with-google {
        background: $color-g;
        border: 1px solid $border-light;
        border-left: 1px solid $border-light;
        border-radius: 0 0 $border-radius-small 0;
        color: $gray-medium;

        &:active,
        &:focus,
        &:hover {
            background: $white;
            color: $gray-medium;
            text-decoration: none;
        }
    }

    &-success {
        padding: 2rem 4rem 6rem 4rem;

        &.is-hidden {
            display: none;
            visibility: hidden;
        }

        h2 {
            font-size: 2.8rem;
            font-weight: 400;
            line-height: 1;
            margin: 0 0 2rem 0;
            text-align: center;
        }

        p {
            text-align: center;
        }
    }

    &.logout {
        padding-bottom: 4rem;
        width: 50rem;

        .btn {
            margin-top: 2rem;
        }
    }

    &.username-reminder {
        overflow: hidden;
        padding: 4rem 4rem 5rem 4rem;

        .mod-calmradio-login-header {
            padding: 0;
        }

        .mod-calmradio-login-username-reminder-email {
            margin-bottom: 4rem;
        }

        &.is-hidden {
            opacity: 0;
            pointer-events: none;
            transform: translateX(-50%) translateY(-40%);
            visibility: hidden;
            z-index: 0;
        }

        p,
        form {
            margin: 3rem 0;
        }

        input.is-invalid {
            background: rgba(red($color-danger), green($color-danger), blue($color-danger), .1);
            border-bottom-color: $color-danger!important;
        }

        input.is-loading {
            opacity: .5;
            pointer-events: none;
        }

        .mod-calmradio-login-username-reminder-message-success {
            background: $green;
        }

        .mod-calmradio-username-reminder-button {
            &-state-normal {
                display: inline;
            }

            &-state-loading {
                display: none;
            }

            &.is-loading {
                opacity: .5;
                pointer-events: none;

                .mod-calmradio-username-reminder-button-state-normal {
                    display: none;
                }

                .mod-calmradio-username-reminder-button-state-loading {
                    display: inline;
                }
            }
        }

        .mod-calmradio-username-reminder-cancel-button {
            float: right;
        }
    }

    &.password-reminder {
        padding: 4rem;

        .mod-calmradio-login-header {
            padding: 0;
        }

        .mod-calmradio-login-password-reminder-email {
            margin-bottom: 4rem;
        }

        input.is-invalid {
            background: rgba(red($color-danger), green($color-danger), blue($color-danger), .1);
            border-bottom-color: $color-danger!important;
        }

        input.is-loading {
            opacity: .5;
            pointer-events: none;
        }

        &-is-hidden {
            opacity: 0;
            pointer-events: none;
            transform: translateX(-50%) translateY(-40%);
            visibility: hidden;
            z-index: 0;
        }

        p,
        form {
            margin: 3rem 0;
        }

        .mod-calmradio-login-password-reminder-message-success {
            background: $green;
        }

        .mod-calmradio-password-reminder-button {
            &-state-normal {
                display: inline;
            }

            &-state-loading {
                display: none;
            }

            &.is-loading {
                opacity: .5;
                pointer-events: none;

                .mod-calmradio-password-reminder-button-state-normal {
                    display: none;
                }

                .mod-calmradio-password-reminder-button-state-loading {
                    display: inline;
                }
            }
        }

        .mod-calmradio-password-reminder-cancel-button {
            float: right;
        }
    }
}

.mod-login.password-retrieval {
    background: $white;
    box-shadow: 0 -.5rem 4rem rgba(0, 0, 0, .065);
    border-radius: $border-radius-small;
    left: 50%;
    min-height: auto;
    padding: 4rem;
    position: absolute;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    transition: all .25s ease-out;
    width: 60rem;
    z-index: 1;

    .pane.reset-pane.active {
        overflow: visible;

        .loader {
            background: rgba(255,255,255,.75);

            .ripple {
                animation: giftcards-loader 0.8s ease-in-out infinite;
                background: none;
                border-radius: 50%;
                border: 3px solid rgba(0, 0, 0, .125);
                border-top-color: $gray-medium;
                content: '';
                height: 64px;
                margin: 0;
                transform: translateX(-50%) translateY(-50%) rotate(0deg);
                width: 64px;
            }
        }
    }

    .pane.login-pane.active,
    .pane.reset-pane.active {
        display: block;
        position: static;

        .form-header {
            font-size: 2.8rem;
            font-weight: 400;
            line-height: 1;
            margin: 0 0 2rem;
            text-align: left;
        }

        .input-wrapper {
            padding: 2.5rem 0 0;
        }

        .help-block.form-error {
            color: $color-danger;
            font-size: 1.2rem;
            padding: .5rem 0;
        }

        .btn {
            display: block;
            margin: 4rem auto 0 auto;
        }

        // Additonal en/password view
        .form-container {
            margin: 0;
            padding: 0;

            .form-header {
                font-size: 2.8rem;
                font-weight: 400;
                line-height: 1;
                margin: 0 0 2rem;
                text-align: left;
            }

            p {
                font-size: 1.7rem;
                padding: 0;
                margin: 0 0 2rem 0;
            }

            input {
                background: transparent;
                border: none;
                border-bottom: 1px solid $border-medium;
                font-size: 1.6rem!important;
                height: 5rem!important;
                padding: 1rem 0!important;
                width: 100%;

                &:active,
                &:focus {
                    border-bottom-color: $blue;
                }
            }

            button {
                background: $blue;
                border-radius: 32px;
                border: 2px solid $blue;
                box-shadow: 2px 3px 3px rgba(0,0,0,.2);
                color: $white;
                display: inline-block;
                font-size: 2rem;
                font-weight: normal;
                line-height: 1;
                padding: .9rem 4rem;
                transition: all .24s ease;
                width: auto;
            }

            .message-placeholder.action-success,
            .message-placeholder.action-error {
                background: $green;
                border-radius: $border-radius-small;
                padding: 1rem 2rem;
            }

            .message-placeholder.action-error {
                background: $red;
            }
        }
    }
}

@media (max-height: 1100px) {
    .mod-calmradio-login {
        position: relative;
        top: 160px;
        transform: translateX(-50%);

        &.username-reminder,
        &.password-reminder {
            top: -300px;
            transform: translateX(-50%);
        }

        &.password-reminder {
            top: -660px;
        }

        &.is-register.is-register-success {
            top: 320px;
        }
    }
}

@media (max-height: 600px) and (max-width: 860px) {
    .mod-calmradio-login,
    .mod-login.password-retrieval {
        margin-bottom: 2rem;
        top: 120px;
        transform: translateX(-50%);
    }

    .mod-calmradio-login.password-reminder {
        top: -840px;
    }

    .mod-calmradio-login.username-reminder {
        top: -460px;
    }
}

@media (max-width: 640px) {
    .mod-login.password-retrieval,
    .mod-calmradio-login.logout,
    .mod-calmradio-login {
        margin-bottom: 2rem;
        padding: 2rem 0 0 0;
        width: calc(100% - 4rem);

        .mod-calmradio-login-header,
        .pane.login-pane.active .form-header {
            font-size: 2rem;
        }
    }

    .mod-login.password-retrieval {
        padding: 2rem;
    }

    .mod-calmradio-login.password-reminder {
        padding: 2rem;
    }

    .mod-calmradio-login.username-reminder {
        padding: 2rem 2rem 5rem 2rem;
    }

    .mod-calmradio-login-social {
        bottom: 0;
        flex-wrap: wrap;
    }

    .mod-calmradio-login-with-fb {
        border-radius: 0;
        width: 100%;
    }

    .mod-calmradio-login-with-google {
        border-radius: 0 0 $border-radius-small $border-radius-small;
        width: 100%;
    }

    .mod-calmradio-login-register,
    .mod-calmradio-login-social-disclaimer {
        bottom: -16rem;

        a {
            white-space: nowrap;
        }
    }

    .mod-calmradio-login-links {
        flex-wrap: wrap;
        padding: 2rem 0;

        .checkbox {
            margin-bottom: 1rem;
            width: 100%;
        }

        a {
            padding: 1rem 0;
            width: 100%;
        }
    }

    .mod-calmradio-login.is-register {
        padding-top: 2rem;
    }

    .mod-calmradio-login.is-register .mod-calmradio-login-header {
        padding: 0 2rem;
    }

    .mod-calmradio-login-input {
        padding: 1.5rem 0 0;
    }

    .mod-calmradio-login.is-register #register_form {
        padding: 0 2rem 14rem;
    }

    .mod-calmradio-login.is-register {

        .mod-calmradio-login-social {
            bottom: 0;
        }

        .mod-calmradio-login-register {
            bottom: -12rem;
        }

        .mod-calmradio-login-social-disclaimer {
            bottom: -6rem;
        }

        .checkbox input[type=checkbox] {
            left: 0;
            top: 8px;
        }

        .checkbox label:before {
            left: -3rem;
        }

        .checkbox label {
            padding: 0;
        }
    }

    .mod-calmradio-login.is-register #register_form .grecaptcha-badge {
        bottom: 136px!important;
    }

    .mod-calmradio-login-terms > div {
        padding: .5rem 1rem 0 3rem;
    }

    .mod-calmradio-login #login_form {
        padding: 0 2rem 16.2rem;
    }

    .mod-calmradio-login #login_form .grecaptcha-badge {
        bottom: 148px!important;
    }

    .mod-calmradio-login .mod-calmradio-login-header {
        padding: 0 2rem;
    }

    .mod-calmradio-login-register {
        bottom: -6rem;
    }
}

@media (max-width: 480px) {
    .mod-calmradio-login,
    .mod-login.password-retrieval {
        top: 100px;
    }

    .mod-calmradio-login-social-disclaimer {
        bottom: -20rem;
    }

    .mod-login.password-retrieval,
    .mod-calmradio-login.password-reminder,
    .mod-calmradio-login.username-reminder {
        padding-bottom: 10rem;

        button {
            margin-bottom: 2rem;
            width: 100%;
        }
    }

    .mod-login.password-retrieval {
        padding-bottom: 3rem;
    }

    .mod-calmradio-login-button {
        padding-left: 4.4rem;
        padding-right: 4.4rem;
    }

    .mod-calmradio-login-terms > div.checkbox {
        font-size: 1.2rem;
    }

    .mod-login.password-retrieval,
    .mod-calmradio-login.logout,
    .mod-calmradio-login {
        width: calc(100% - 2rem);
    }
}

@media (max-width: 480px) and (max-height: 1100px) {
    .mod-calmradio-login.password-reminder {
        top: -950px;
    }
}

@media (max-width: 480px) and (min-height: 600px) and (max-height: 1100px) {
    .mod-login.password-retrieval {
        top: 250px;
    }
}

@media (max-width: 480px) and (min-height: 1100px) {
    .mod-calmradio-login, 
    .mod-login.password-retrieval {
        top: 380px;
    }
}
