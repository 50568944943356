body:fullscreen {
    .main-nav,
    .top-promotions-wrapper {
        display: none;
    }
}

body:-webkit-full-screen {
    .main-nav,
    .top-promotions-wrapper {
        display: none;
    }
}
  
body:-moz-full-screen {
    .main-nav,
    .top-promotions-wrapper {
        display: none;
    }
}
  
body:-ms-fullscreen {
    .main-nav,
    .top-promotions-wrapper {
        display: none;
    }
}
  
body .heading.heading-frontpage {
    .cr-player-fullscreen {
        .open-fullscreen {
            display: block;
        }

        .close-fullscreen {
            display: none;
        }
    }

    &.is-fullscreen {
        .cr-player-fullscreen {
            .open-fullscreen {
                display: none;
            }
    
            .close-fullscreen {
                display: block;
            }
        }
    }
}