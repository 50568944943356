

#why-premium.pricing-page-v2 {
    background: $white;
    padding: 6rem 0 12rem;
}

#pricing-page-view-our-memberships {
    background: $white;
    padding: 7rem 0 8rem 0;
    text-align: center;

    & + #why-premium {
        padding-top: 0!important;

        .premium-header {
            margin-top: 0;
        }
    }

    & > span {
        color: $color-text-light;
        display: inline-block;
        font-weight: 300;
        font-size: 1.8rem;
        position: relative;

        &:before {
            border-bottom: 1px solid $color-text-light;
            bottom: -0.9rem;
            content: "";
            left: 10%;
            position: absolute;
            width: 80%;
        }

        &:after {
            background: $white;
            bottom: -1.4rem;
            border: 1px solid $color-text-light;
            border-left-color: transparent;
            border-top-color: transparent;
            content: "";
            height: 12px;
            left: 50%;
            position: absolute;
            transform: translateX(-50%) rotate(45deg);
            width: 12px;
        }
    }
}

.why-premium-content {
    border-radius: 20px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, .15);
    display: flex;
    margin: 0 auto;
    max-width: 1200px;
    overflow: hidden;

    &-col {
        box-shadow: 0 0 60px rgba(56, 170, 222, .25);
        padding: 4.5rem 12rem 6.5rem 12rem;
        width: 75%;

        &:first-child {
            background: rgb(229, 229, 229);
            box-shadow: none;
            padding: 4.5rem 6rem;
            width: 25%;
        }
    }

    &-header {
        display: block;
        font-size: 4.8rem;
        font-weight: 500;
        margin: 0 0 3rem;
        opacity: .8;
        text-align: left;
        text-transform: uppercase;
    }

    &-badge {
        border-radius: 10px;
        border: 2px solid rgb(56, 170, 222);
        color:rgb(56, 170, 222);
        display: inline-block;
        font-size: 1.6rem;
        font-weight: bold;
        height: 44px;
        line-height: 41px;
        margin-left: 1.5rem;
        padding: 0 1.5rem;
        position: relative;
        text-transform: none;
        top: -11px;

        & + .why-premium-content-badge {
            margin-left: .75rem;
        }
    }

    &-list {
        margin: 0;
        padding: 0;

        &-item {
            align-items: center;
            border-bottom: 1px solid rgb(209, 209, 209);
            display: flex;
            opacity: .8;
            padding: 1.2rem 0;

            &:first-child {
                border-top: 1px solid rgb(209, 209, 209);
            }
        }

        &-premium {
            display: flex;
            flex-wrap: wrap;
        
            .why-premium-content-list-item {
                border-bottom: 1px solid rgb(233, 233, 233);
                padding-left: 4rem;
                position: relative;
                width: 270px;

                &:nth-child(even) {
                    margin-left: auto;
                }

                &:first-child,
                &:nth-child(2) {
                    border-top: 1px solid rgb(233, 233, 233);
                }

                &:before,
                &:after {
                    content: "";
                    position: absolute;
                }

                &:before {
                    background: rgb(95, 208, 81);
                    border-radius: 50%;
                    height: 16px;
                    left: 0;
                    top: calc(50% - 1px);
                    transform: translateY(-50%);
                    width: 16px;
                }

                &:after {
                    border-left: 2px solid $white;
                    border-bottom: 2px solid $white;
                    height: 5px;
                    left: 5px;
                    top: 50%;
                    transform: translateY(-4px) translateX(-1px) rotate(-45deg);
                    width: 8px;
                }
            }
        }
    }
}

.pricing-page-header.pricing-page-v2.has-default-bg {
    background: url('../images/pricing-page-v2-top-cover.jpg') no-repeat bottom center;
    background-size: cover;
    margin: 0;
    min-height: 840px;
    max-width: 100%;
    width: 100%;
}

#plans_container.pricing-page-v2 {
    position: relative;
}

.pricing-page-v2 {
    .testimonials-header,
    .premium-header,
    .plans-header,
    .plans-subheader {
        color: rgb(56, 170, 222);
        display: block;
        font-size: 4.8rem;
        font-weight: 600;
        margin: 0 0 8rem;
        padding-left: 1rem;
        padding-right: 1rem;
        text-align: center;
        text-transform: uppercase;
        width: 100%;
    }

    .premium-header {
        margin: 4rem 0;
    }

    .plans-header {
        margin: 7.5rem 0 0 0;
    }

    .plans-subheader {
        color: $color-text;
        font-size: 1.6rem;
        margin: 1.5rem 0 0 0;
        opacity: .8;
        text-transform: none;
    }

    .testimonials-header {
        margin: 0;
    }

    .pricing-scroll-arrow {
        border: 1px solid rgb(56, 170, 222);
        border-right-color: transparent;
        border-top-color: transparent;
        bottom: 4.5rem;
        height: 3rem;
        left: 50%;
        position: absolute;
        transform: translateX(-50%) rotate(-45deg);
        transition: all .5s ease-out;
        width: 3rem;

        &:hover {
            transform: translateX(-50%) translateY(1rem) rotate(-45deg);
        }
    }
}

.pricing-page-v2 {
    .premium-wrapper {
        margin: 0 auto;
        max-width: $max-width;
        width: 100%;
    }
}

.testimonials-slider.pricing-page-v2 {
    background: url('../images/pricing/our-listeners-love-it.jpg') no-repeat center center;
    background-size: cover;
    padding: 7.5rem 0 12rem 0;
    position: relative;

    .testimonials-slider-content {
        overflow: hidden;
        width: 100%;

        &.pan-gesture-in-progress .testimonials-slider-content-inner {
            pointer-events: none;
        }

        &-inner {
            cursor: ew-resize;
            display: flex;
            justify-content: flex-end;
            padding: 4.5rem 0;

            @for $i from 3 through 12 {
                &[data-count="#{$i}"] {
                    width: calc((#{$i} * 480px) + (50vw - 240px));
                }
            }

            .testimonials-slider-item {
                background: $white;
                border-radius: 20px;
                box-shadow: 0 5px 10px rgba(0, 0, 0, .125);
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                margin: 0 1rem;
                padding: 90px 45px 45px 45px;
                position: relative;
                width: 46rem;

                &:before {
                    content: "“";
                    font-size: 170px;
                    font-family: georgia;
                    left: 30px;
                    opacity: .15;
                    position: absolute;
                    top: -40px;
                }

                p {
                    font-size: 1.6rem;
                    opacity: .8;
                }

                &-footer {
                    display: block;
                    font-weight: 300;
                    font-weight: bold;
                    margin-top: auto;
                    width: 100%;

                    & > cite {
                        display: block;
                        font-size: 1.5rem;
                        text-align: right;
                    }
                }
            }
        }
    }

    .testimonials-slider-content-outer-wrapper {
        overflow: hidden;
        width: 100%; 

        .testimonials-slider-content {
            margin-bottom: -2rem;
            overflow: auto;
            padding-bottom: 4rem;
            width: 100%;
        }

        & + .testimonials-slider-progress-bar {
            display: none!important;
        }
    }

    .testimonials-slider-progress-bar {
        bottom: 10rem;
        height: 30px;
        left: 50%;
        position: absolute;
        transform: translateX(-50%);
        width: 250px;

        &:before {
            background: $white;
            border-radius: 2px;
            box-shadow: 0 1px 2px rgba(0, 0, 0, .1);
            content: "";
            height: 2px;
            left: 0;
            position: absolute;
            pointer-events: none;
            top: 14px;
            width: 250px;
            z-index: 1;
        }

        &-handler {
            background: $white;
            border-radius: 50%;
            box-shadow: 0 1px 2px rgba(0, 0, 0, .2);
            cursor: ew-resize;
            height: 16px;
            left: 0;
            margin-left: -8px;
            position: absolute;
            top: 7px;
            width: 16px;
            z-index: 2;
        }
    }
}

#plans_container.pricing-page-v2 {
    .pricing-scroll-arrow {
        bottom: -13rem;
    }

    .pricing-table-wrapper {
        margin: 5rem auto 19rem auto;
        padding: 0 0 1rem 0;
        position: relative;

        .btn-show-more-plans {
            border-radius: 10px;
            border-color: transparent!important;
            left: calc(100% + 2.5rem);
            top: 50%;
            transform: translateY(-50%);
            padding: .5rem 2rem;
            position: absolute;
            white-space: nowrap;
        }

        .pricing-table {
            justify-content: space-between;

            .pricing-table-col {
                background: $white;
                border-radius: 20px;
                box-shadow: 0 5px 10px rgba(0, 0, 0, .125);
                flex-grow: 0;
                overflow: hidden;
                padding: 5rem 2.5rem;
                position: relative;
                text-align: center;

                &:first-child {
                    margin-bottom: 55px;
                }

                &[data-badge="ON_SALE"]:before,
                &[data-badge="BEST_VALUE"]:before {
                    color: $white;
                    font-size: 1.6rem;
                    font-weight: bold;
                    height: 3.2rem;
                    line-height: 3.2rem;
                    pointer-events: none;
                    position: absolute;
                    text-shadow: 0 1px 2px rgba(0, 0, 0, .15);
                    text-transform: uppercase;
                    transform: translateX(50px) translateY(-20px) rotate(45deg);
                    width: 20rem;
                    z-index: 1;
                }

                &[data-badge="ON_SALE"]:before {
                    background-color: rgb(228, 189, 75);
                    content: attr(data-badge-on-sale-text);
                }

                &[data-badge="BEST_VALUE"]:before {
                    background-color: rgb(228, 72, 69);
                    content: attr(data-badge-best-value-text);
                }

                h3 {
                    font-size: 2.6rem;
                    font-weight: bold;
                    opacity: .85;
                    text-transform: uppercase;
                }

                .price-amount-currency {
                    display: block;

                    .price-amount-previous {
                        color: rgb(141, 141, 141);
                        display: block;
                        font-size: 3rem;

                        &.is-empty {
                            opacity: 0;
                        }
                    }

                    & + sub {
                        color: $gray;
                        display: block;
                        font-size: 1.6rem;
                        font-weight: bold;
                        line-height: 1.6;
                    }

                    .price-current-amount {
                        color: rgb(56, 170, 222);
                        font-size: 4.8rem;
                        font-weight: bold;
                        line-height: 1;

                        &.is-free {
                            color: rgb(141, 141, 141);
                        }
                    }
                }

                .price-breakdown-desc {
                    color: $gray;
                    font-size: 1.6rem;
                    font-weight: bold;
                    line-height: 1.6;
                    margin-bottom: 1rem;
                    min-height: 5.8rem;
                }

                .btn.btn-full-width {
                    box-shadow: none;
                    border-radius: 10px;
                    width: auto;
                }

                .pricing-table-col-additional-users {
                    background-color: rgb(229, 229, 229)!important;
                    border: none;
                    bottom: -55px;
                    height: 60px;
                    left: 0;
                    margin: -10px -25px 0 -25px!important;
                    position: relative;
                    transition: all .25s ease-out;
                    width: calc(100% + 50px);

                    .btn-buy-additional {
                        color: $color-text;
                        font-size: 2.2rem;
                        font-weight: 400;
                        height: 6rem;
                        line-height: 6.1rem;
                        opacity: .8;
                        padding: 0;
                        transform: translateX(5px) translateY(0);
                        transition: all .25s ease-out;
                        width: 100%;

                        &:before {
                            display: none;
                        }
                        
                        &-icon {
                            color: $color-text;
                            content: "+";
                            font-size: 4.5rem;
                            font-weight: 200;
                            left: -10px;
                            line-height: .8;
                            position: relative;
                            top: 4px;
                            transition: all .25s ease-out;
                        }
                    }

                    &:active,
                    &:focus,
                    &:hover,
                    &.active {
                        background: rgb(56, 170, 222)!important;

                        .btn-buy-additional {
                            color: $white;

                            &-icon {
                                color: $white;
                            }
                        }
                    }

                    &.active {
                        background: $green!important;
                    }
                }
            }
        }
    }

    &.has-hidden-cols {
        .pricing-table-wrapper {
            max-width: 1200px;

            .pricing-table {
                width: 100%;
            }

            .pricing-table-col {
                width: calc(33.333333% - 15px)!important;
            }

            .pricing-table-col {
                display: none;

                &:nth-child(1),
                &:nth-child(2),
                &:nth-child(3) {
                    display: block;
                }
            }
        }
    }

    &:not(.has-hidden-cols) {
        .pricing-table-wrapper {
            max-width: 100%;
            width: 100%;

            .pricing-table {
                display: flex;
                justify-content: center;
                width: 100%;
            }

            .btn-show-more-plans {
                display: none;
            }

            .pricing-table-col {
                margin-right: 23px;
                width: 384px!important;

                &:last-child {
                    margin-right: 0;
                }
            }

            .pricing-table[data-personal-plans-count="6"] {
                flex-wrap: wrap;
                margin-left: auto;
                margin-right: auto;
                max-width: 1200px;

                .pricing-table-col:nth-child(1) {
                    margin-bottom: 78px;
                }

                .pricing-table-col:nth-child(2),
                .pricing-table-col:nth-child(3) {
                    margin-bottom: 23px;
                }

                .pricing-table-col:nth-child(3),
                .pricing-table-col:nth-child(6) {
                    margin-right: 0;
                }

                .pricing-table-col:nth-child(4),
                .pricing-table-col:nth-child(5),
                .pricing-table-col:nth-child(6) {
                    .pricing-table-col-additional-users {
                        bottom: -50px;
                    }
                }
            }
        }
    }
}

@media (max-width: 1720px) {
    .pricing-page-header.pricing-page-v2.has-default-bg {
        min-height: 600px;
    }

    #plans_container.pricing-page-v2 .pricing-table-wrapper .pricing-table .pricing-table-col h3 {
        font-size: 2rem;
    }

    #plans_container.pricing-page-v2 .pricing-table-wrapper .pricing-table .pricing-table-col .price-amount-currency .price-amount-previous {
        font-size: 2rem;
    }

    #plans_container.pricing-page-v2 .pricing-table-wrapper .pricing-table .pricing-table-col .price-amount-currency .price-current-amount {
        font-size: 3.6rem;
    }

    #plans_container.pricing-page-v2 .pricing-table-wrapper .pricing-table .pricing-table-col .price-amount-currency + sub,
    #plans_container.pricing-page-v2 .pricing-table-wrapper .pricing-table .pricing-table-col .price-breakdown-desc {
        font-size: 1.3rem;
    }

    #plans_container.pricing-page-v2 .pricing-table-wrapper .pricing-table .pricing-table-col .btn-medium {
        font-size: 1.8rem;
        padding: .5rem 2rem;
    }

    #plans_container.pricing-page-v2 .pricing-table-wrapper .pricing-table .pricing-table-col .pricing-table-col-additional-users .btn-buy-additional {
        font-size: 1.8rem;
    }

    #plans_container.pricing-page-v2 .pricing-table-wrapper .pricing-table .pricing-table-col .pricing-table-col-additional-users .btn-buy-additional-icon {
        font-size: 3.6rem;
    }

    #plans_container.pricing-page-v2.has-hidden-cols .pricing-table-wrapper {
        max-width: 1030px;
    }

    #plans_container.pricing-page-v2 .pricing-table-wrapper .btn-show-more-plans {
        font-size: 1.8rem;
        left: calc(100% + .5rem);
    }

    .pricing-page-v2 .plans-subheader {
        margin: 0 0 4rem 0;
    }

    #plans_container.pricing-page-v2 .pricing-table-wrapper .pricing-table .pricing-table-col[data-badge="ON_SALE"]:before, 
    #plans_container.pricing-page-v2 .pricing-table-wrapper .pricing-table .pricing-table-col[data-badge="BEST_VALUE"]:before {
        font-size: 1.2rem;
        height: 2rem;
        line-height: 2rem;
        transform: translateX(30px) translateY(-20px) rotate(45deg);
        width: 17rem;
    }

    #plans_container.pricing-page-v2 .pricing-table-wrapper,
    #plans_container.pricing-page-v2.has-hidden-cols .pricing-table-wrapper {
        .pricing-table-col {
            margin-right: 23px;
            max-width: 305px!important;
            width: 100%!important;

            &:last-child {
                margin-right: 0;
            }
        }
    }
}

@media (max-width: 1400px) {
    #plans_container.pricing-page-v2 .pricing-table-wrapper .btn-show-more-plans {
        left: calc(100% - 2.5rem);
    }
}

@media (max-width: 1300px) {
    .why-premium-content {
        max-width: calc(100% - 40px);
    }

    .why-premium-content-header {
        font-size: 3.6rem;
    }

    .why-premium-content-badge {
        height: 34px;
        line-height: 31px;
        padding: 0 1rem;
        top: -6px;
    }

    .why-premium-content-col {
        padding: 2.5rem 10rem 4.5rem;
        width: 70%;
    }

    .why-premium-content-col:first-child {
        padding: 2.5rem 4rem;
        width: 30%;
    }

    #plans_container.pricing-page-v2 .pricing-table-wrapper {
        margin: 2rem auto 10rem;
    }

    #plans_container.pricing-page-v2 .pricing-scroll-arrow {
        bottom: -5rem;
    }

    #why-premium.pricing-page-v2 {
        padding: 2rem 0 6rem;
    }

    #plans_container.pricing-page-v2 .pricing-table-wrapper .btn-show-more-plans {
        left: calc(100% - 3.5rem);
    }
}

@media (max-width: 1240px) {
    #plans_container.pricing-page-v2 .pricing-table-wrapper .pricing-table .pricing-table-col {
        padding: 3.5rem 2.5rem 5.5rem!important;
    }

    #plans_container.pricing-page-v2 .pricing-table-wrapper .pricing-table .pricing-table-col:first-child {
        margin-bottom: 74px;
    }

    #plans_container.pricing-page-v2 .pricing-table-wrapper,
    #plans_container.pricing-page-v2.has-hidden-cols .pricing-table-wrapper {
        max-width: 100%!important;
        width: 100%!important;

        .pricing-table {
            display: flex;
            justify-content: center;
            width: 100%;
        }

        .btn-show-more-plans {
            display: none!important;
        }
    }

    #plans_container.pricing-page-v2.has-hidden-cols .pricing-table-wrapper .pricing-table-col {
        display: block;
    }

    #plans_container.pricing-page-v2 .pricing-table-wrapper .pricing-table {
        margin: 0 auto;
        max-width: 740px;

        .pricing-table-col {
            &:nth-child(2),
            &:nth-child(4),
            &:last-child {
                margin-right: 0;
            }
        }
    }

    #plans_container.pricing-page-v2 .pricing-table-wrapper .pricing-table .pricing-table-col[data-badge="ON_SALE"]:before, 
    #plans_container.pricing-page-v2 .pricing-table-wrapper .pricing-table .pricing-table-col[data-badge="BEST_VALUE"]:before {
        transform: translateX(30px) translateY(-10px) rotate(45deg);        
    }
}

@media (max-width: 1100px) {
    .why-premium-content-col {
        padding: 2.5rem 4rem 4.5rem;
    }
}

@media (max-width: 1024px) {
    .pricing-page-header.pricing-page-v2.has-default-bg {
        min-height: 400px;
    }

    .pricing-page-v2 .plans-header,
    .pricing-page-v2 .premium-header, 
    .pricing-page-v2 .testimonials-header {
        font-size: 3.6rem;
        margin: 0 0 4rem 0;
    }

    .pricing-page-v2 .plans-header {
        margin: 4rem 0 0 0;
    }

    .why-premium-content-col,
    .why-premium-content-col:first-child {
        width: 50%;
    }

    .why-premium-content-list-premium .why-premium-content-list-item {
        width: 100%;
    }

    .why-premium-content-list-premium .why-premium-content-list-item:first-child, 
    .why-premium-content-list-premium .why-premium-content-list-item:nth-child(2) {
        border-top: none;
    }

    .why-premium-content {
        flex-wrap: wrap;
    }

    .why-premium-content-col, 
    .why-premium-content-col:first-child {
        width: 100%;
    }

    .why-premium-content-header {
        margin: 0 0 1rem 0;
    }

    .why-premium-content-col,
    .why-premium-content-col:first-child {
        padding: 1.5rem 2rem;
    }

    .why-premium-content-list .why-premium-content-list-item:last-child {
        border-bottom: none;
    }
}

@media (max-width: 830px) {
    #plans_container.pricing-page-v2 .pricing-table-wrapper .pricing-table .pricing-table-col {
        margin: 2rem!important;
    }
}

@media (max-width: 780px) {
    #plans_container.pricing-page-v2 .pricing-table-wrapper .pricing-table .pricing-table-col .pricing-table-col-additional-users {
        left: 0!important;
        width: calc(100% + 50px)!important;
    }
}

@media (max-width: 640px) {
    #plans_container.pricing-page-v2 .pricing-table-wrapper .pricing-table .pricing-table-col {
        margin: 2rem auto!important;
    }

    .pricing-page-header.pricing-page-v2.has-default-bg {
        min-height: 240px;
    }

    .why-premium-content {
        max-width: calc(100% - 20px);
    }

    .pricing-page-v2 .plans-header,
    .pricing-page-v2 .premium-header, 
    .pricing-page-v2 .testimonials-header {
        font-size: 2.4rem;
        margin: 0 0 2rem 0;
    }

    .pricing-page-v2 .testimonials-header {
        margin: 2rem 0;
    }

    .pricing-page-v2 .plans-header {
        margin: 5rem 0 0 0;
    }

    .why-premium-content-header {
        font-size: 2.4rem;
    }

    .why-premium-content-badge {
        font-size: 1.2rem;
        height: 24px;
        line-height: 21px;
        padding: 0 .5rem;
        top: -3px;
    }

    .why-premium-content-list-item {
        padding: .75rem 0;
    }

    .testimonials-slider.pricing-page-v2 .testimonials-slider-content-inner .testimonials-slider-item {
        padding: 50px 20px 25px;
        width: 28rem;
    }

    .testimonials-slider.pricing-page-v2 .testimonials-slider-content-inner .testimonials-slider-item:before {
        font-size: 120px;
        left: 16px;
        top: -35px;
    }

    .testimonials-slider.pricing-page-v2 .testimonials-slider-content-inner .testimonials-slider-item p,
    .testimonials-slider.pricing-page-v2 .testimonials-slider-content-inner .testimonials-slider-item-footer > cite {
        font-size: 1.3rem;
    }

    .testimonials-slider.pricing-page-v2 {
        padding: 2.5rem 0 12rem 0;
    
        .testimonials-slider-content {    
            &-inner {    
                padding: 1rem 0 3.5rem 0;

                @for $i from 3 through 12 {
                    &[data-count="#{$i}"] {
                        width: calc((#{$i} * 280px) + (50vw + 90px));
                    }
                }
            }
        }
    }
}

/**
 * Language-specific modifications
 */

html[lang="fr-FR"],
html[lang="it-IT"],
html[lang="es-ES"],
html[lang="ru-RU"],
html[lang="pt-BR"],
html[lang="nl-NL"] {
    .pricing-table-col[data-badge="ON_SALE"]:before,
    .pricing-table-col[data-badge="BEST_VALUE"]:before {
        font-size: 1.1rem!important;
        font-weight: 500!important;
        letter-spacing: -.75px!important;
    }
}

html[lang="pt-BR"] {
    #plans_container.pricing-page-v2 .pricing-table-wrapper .pricing-table .pricing-table-col:first-child .btn.btn-full-width {
        font-size: 1.4rem;
        padding: .8rem 2rem;
    }
}

@media (max-width: 1720px) {
    html[lang="fr-FR"],
    html[lang="it-IT"],
    html[lang="es-ES"],
    html[lang="ru-RU"],
    html[lang="pt-BR"],
    html[lang="nl-NL"] {
        .pricing-table-col[data-badge="ON_SALE"]:before,
        .pricing-table-col[data-badge="BEST_VALUE"]:before {
            font-size: 1rem!important;
        }
    }

    html[lang="fr-FR"],
    html[lang="nl-NL"],
    html[lang="ru-RU"] {
        #plans_container.pricing-page-v2 .pricing-table-wrapper .btn-show-more-plans {
            font-size: 1.4rem;
            padding: .5rem 1rem;
            white-space: normal;
            width: 120px;
        }
    }
}

@media (max-width: 1300px) {
    html[lang="fr-FR"],
    html[lang="it-IT"],
    html[lang="es-ES"],
    html[lang="ru-RU"],
    html[lang="pt-BR"],
    html[lang="nl-NL"] {
        .pricing-table-col[data-badge="ON_SALE"]:before,
        .pricing-table-col[data-badge="BEST_VALUE"]:before {
            font-size: .9rem!important;
        }
    }
}

@media (min-width: 1025px) {
    html[lang="ru-RU"],
    html[lang="de-DE"] {
        .why-premium-content-header {
            font-size: 3.6rem;
        }

        .why-premium-content-badge {
            font-size: 1.4rem;
            height: 36px;
            line-height: 35px;
            top: -6px;
        }
    }
}