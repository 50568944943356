#system-message-container {
    position: relative;
    z-index: 10000;

    #system-message {
        .alert {
            align-items: center;
            display: flex;
            justify-content: center;
            padding: 1.8rem 4rem;

            .close {
                color: $white;
                cursor: pointer;
                font-size: 2.4rem;
                line-height: .8;
                opacity: .75;
                padding: 0 1rem;
                position: absolute;
                right: 1rem;
                text-decoration: none;

                &:active,
                &:focus,
                &:hover {
                    opacity: 1;
                }
            }

            &-heading {
                color: $white;
                display: inline-block;
                font-size: 1.4rem;
                font-weight: bold;
                line-height: 1.4;
                margin: 0 .5rem 0 0;

                &:after {
                    content: ": ";
                }

                & + div {
                    color: $white;
                    font-size: 1.4rem;
                    font-weight: normal;
                    line-height: 1.4;
                }
            }

            &.alert-message {
                background: $green;
            }

            &.alert-notice {
                background: $blue;
            }

            &.alert-warning {
                background: $yellow;
            }

            &.alert-error {
                background: $color-danger;
            }
        }
    }
}

body > .subscription-success {
    background: $white;
    padding: 20rem 2rem;
    text-align: center;

    i {
        display: none;
    }

    h3 {
        font-weight: 400;
        margin: 0;
    }

    p {
        margin: 3rem auto;
        max-width: 640px;
        text-align: center;
    }
}

@media (max-width: 560px) {
    body > .subscription-success a button {
        font-size: 2rem;
        padding: 1.2rem 4rem;
    }
}
