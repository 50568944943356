.android-smart-app-banner {
    align-items: center;
    background: $white;
    box-shadow: 0 0 10px rgba(0, 0, 0, .25);
    display: flex;
    height: 8rem;
    position: relative;
    z-index: 1;

    & ~ .main-nav {
        margin-top: 8rem;
    }

    &-left {
        align-items: center;
        display: flex;
        width: 96px;
    }

    &-close {
        align-items: center;
        color: $color-text-light;
        display: flex;
        font-size: 2rem;
        font-weight: bold;
        height: 32px;
        justify-content: center;
        width: 32px;
    }

    &-icon {
        height: 64px;
        width: 64px;
    }

    &-center {
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        padding: 0 1rem;
        width: calc(100% - 165px);
    }

    &-name {
        line-height: 1;
        margin-bottom: 6px;
        width: 100%;
    }

    &-desc {
        color: $color-text-light;
        font-size: 1rem;
        line-height: 1.1;
        width: 100%;
    }

    &-stars {
        color: #ffc107;
        line-height: 1;

        &-wrapper {
            width: 100%;
        }

        &-count {
            color: $color-text-light;
            font-size: 1.2rem;
        }
    }

    &-btn {
        align-items: center;
        display: flex;
        border: 1px solid #444;
        border-radius: 10px;
        color: #444;
        font-weight: 500;
        height: 40px;
        justify-content: center;
        margin-right: 10px;
        width: 60px;
    }
}
