.page-header {
    background: $white;
    min-height: 42.5rem;
    overflow: hidden;
    position: relative;

    &-bg {
        left: 50%;
        max-width: 2560px;
        position: absolute;
        top: 15%;
        transform: translateX(-50%);
        width: 2560px;
    }

    .wrapper {
        margin-top: 20rem;
        position: relative;
        z-index: 1;

        h1 {
            font-size: 6.4rem;
            font-weight: 400;
        }

        p {
            font-size: 1.8rem;
            font-weight: 300;
            margin-top: 1rem;
        }
    }
}

.pricing-testimonials {
    background: $white;
    padding: 20rem 0 10rem 0;

    &-wrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin: 0 auto;
        max-width: 1400px;
        width: 100%;
    }

    &-item {
        background: $white;
        box-shadow: 0 0 32px rgba(0, 0, 0, .125);
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: space-between;
        opacity: 1;
        padding: 8rem 6.4rem;
        position: relative;
        transition: all .25s ease-out;
        transform: scale(1);
        width: calc((100% / 3) - 32px);

        &:before {
            color: $gray-light-alt;
            content: "“";
            font-family: Georgia, Times, serif;
            font-size: 9.6rem;
            left: 4rem;
            line-height: 1;
            position: absolute;
            top: 3rem;
        }

        p {
            font-size: 1.6rem;
            font-weight: 300;
            margin: 0 0 2rem 0;
            width: 100%;
        }

        &.is-hidden {
            display: none;
        }

        &.is-to-show {
            display: flex;
            opacity: 0;
            transform: scale(.75);
        }

        &-footer {
            align-items: center;
            display: flex;
            width: 100%;

            cite {
                font-size: 2rem;
                font-weight: 600;
                padding: 0;
            }

            img {
                border-radius: 50%;
                display: block;
                height: 50px;
                margin-right: 3rem;
                overflow: hidden;
                width: 50px;
            }
        }
    }

    &-show-more {
        display: block;
        height: 20px;
        margin: 1rem auto;
        position: relative;
        width: 200px;

        &:after,
        &:before {
            background: $blue;
            content: "";
            height: 2px;
            left: 50%;
            position: absolute;
            transition: background .25s ease-out, top .25s ease-out;
            top: 70%;
            width: 20px;
        }

        &:after {
            transform-origin: 100% 100%;
            transform: translateX(-20px) rotate(30deg);
        }

        &:before {
            transform-origin: 0 0;
            transform: rotate(-30deg);
        }

        &.is-hidden {
            display: none;
        }
    }
}

.pricing-page-faq {
    background: $white;
    padding: 10rem 0 25rem 0;

    h2.pricing-page-faq-header {
        display: block;
        font-size: 4.8rem;
        font-weight: 600;
        margin: 0 0 10rem 0;
        text-align: center;
        width: 100%;
    }

    &-wrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin: 0 auto;
        max-width: 880px;
        width: 100%;
    }
}

.pricing-faq {
    &-item {
        &-title {
            border-bottom: 1px solid $border-light;
            cursor: pointer;
            font-size: 2rem;
            font-weight: 500;
            line-height: 1.3;
            padding: 2rem 0 2rem 4.5rem;
            position: relative;

            &:before {
                content: url('data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2024%2024%22%3E%3Cpath%20fill%3D%22%23aaa%22%20d%3D%22M12%2C2C6.486%2C2%2C2%2C6.486%2C2%2C12c0%2C5.514%2C4.486%2C10%2C10%2C10c5.514%2C0%2C10-4.486%2C10-10C22%2C6.486%2C17.514%2C2%2C12%2C2z%20M12%2C20c-4.411%2C0-8-3.589-8-8s3.589-8%2C8-8s8%2C3.589%2C8%2C8S16.411%2C20%2C12%2C20z%20M13%2C11h3v2h-3v3h-2v-3H8v-2h3V8h2V11z%22%2F%3E%3C%2Fsvg%3E');
                height: 2.4rem;
                left: 0;
                position: absolute;
                top: 2.1rem;
                width: 2.4rem;
            }

            & > span {
                transition: all .25s ease-out;
            }

            &:hover {
                & > span {
                    color: $blue;
                }

                &:before {
                    content: url('data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2024%2024%22%3E%3Cpath%20fill%3D%22%2317abe5%22%20d%3D%22M12%2C2C6.486%2C2%2C2%2C6.486%2C2%2C12c0%2C5.514%2C4.486%2C10%2C10%2C10c5.514%2C0%2C10-4.486%2C10-10C22%2C6.486%2C17.514%2C2%2C12%2C2z%20M12%2C20c-4.411%2C0-8-3.589-8-8s3.589-8%2C8-8s8%2C3.589%2C8%2C8S16.411%2C20%2C12%2C20z%20M13%2C11h3v2h-3v3h-2v-3H8v-2h3V8h2V11z%22%2F%3E%3C%2Fsvg%3E');
                }
            }

            &.is-active {
                &:before {
                    content: url('data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2024%2024%22%3E%3Cpath%20fill%3D%22%2317abe5%22%20d%3D%22M12%2C2C6.486%2C2%2C2%2C6.486%2C2%2C12c0%2C5.514%2C4.486%2C10%2C10%2C10c5.514%2C0%2C10-4.486%2C10-10C22%2C6.486%2C17.514%2C2%2C12%2C2z%20M12%2C20c-4.411%2C0-8-3.589-8-8s3.589-8%2C8-8s8%2C3.589%2C8%2C8S16.411%2C20%2C12%2C20z%20M13%2C11h3v2h-3v3h-2v-3H8v-2h3V8h2V11z%22%2F%3E%3C%2Fsvg%3E');
                }

                & + .pricing-faq-item-content {
                    height: auto;

                    .pricing-faq-item-content-wrapper {
                        opacity: 1;
                    }
                }
            }
        }

        &-content {
            height: 0;
            overflow: hidden;
            transition: all .3s ease-out;

            &-wrapper {
                opacity: 0;
                padding: 2rem 0 3.5rem 4.5rem;
                transition: all .3s ease-out;
            }
        }
    }
}

.pricing-table {
    display: flex;
    margin-top: 14rem;
    text-align: left;

    &-wrapper {
        margin: 0 auto;
        padding: 20rem 0;
        max-width: 1400px;

        & > .pricing-table {
            margin-top: 0;
        }
    }

    &-col {
        flex-grow: 1;
        padding-bottom: 5rem;
        padding-top: 5rem;
        position: relative;

        &:nth-of-type(1) {
            padding-right: 10rem;
        }

        &:nth-of-type(2) {
            padding: 5rem;
        }

        &:nth-of-type(3) {
            padding-left: 10rem;
        }

        &.pricing-table-col-has-additional-users {
            padding-bottom: 12rem;
        }

        h3 {
            font-size: 2rem;
            margin: 0 0 1rem 0;
        }

        strong {
            display: block;
            font-size: 3.2rem;
            font-weight: 600;

            sub {
                bottom: 0.2rem;
                color: $gray-medium;
                font-size: 1.2rem;
                font-weight: 300;
            }
        }

        &-additional-users {
            align-items: center;
            border-top: 1px solid lighten($border-medium, 10%);
            bottom: 0;
            display: flex;
            height: 7rem;
            justify-content: center;
            left: 10rem;
            line-height: 1.2;
            position: absolute;
            text-align: center;
            width: calc(100% - 10rem);

            .btn-buy-additional {
                color: $color-text;
                position: relative;
                text-decoration: none;
                width: calc(100% - 9.6rem);

                &:before {
                    content: url('data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2024%2024%22%3E%3Cpath%20fill%3D%22%2317abe5%22%20d%3D%22M12%2C2C6.486%2C2%2C2%2C6.486%2C2%2C12c0%2C5.514%2C4.486%2C10%2C10%2C10c5.514%2C0%2C10-4.486%2C10-10C22%2C6.486%2C17.514%2C2%2C12%2C2z%20M12%2C20c-4.411%2C0-8-3.589-8-8s3.589-8%2C8-8s8%2C3.589%2C8%2C8S16.411%2C20%2C12%2C20z%20M13%2C11h3v2h-3v3h-2v-3H8v-2h3V8h2V11z%22%2F%3E%3C%2Fsvg%3E');
                    height: 2.4rem;
                    left: -1.6rem;
                    line-height: 2.4rem;
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    width: 2.4rem;
                }

                &:active,
                &:focus,
                &:hover {
                    color: $blue;

                    &:before {
                        content: url('data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2024%2024%22%3E%3Cpath%20fill%3D%22%2317abe5%22%20d%3D%22M12%2C2C6.486%2C2%2C2%2C6.486%2C2%2C12c0%2C5.514%2C4.486%2C10%2C10%2C10c5.514%2C0%2C10-4.486%2C10-10C22%2C6.486%2C17.514%2C2%2C12%2C2z%20M12%2C20c-4.411%2C0-8-3.589-8-8s3.589-8%2C8-8s8%2C3.589%2C8%2C8S16.411%2C20%2C12%2C20z%20M13%2C11h3v2h-3v3h-2v-3H8v-2h3V8h2V11z%22%2F%3E%3C%2Fsvg%3E');
                    }
                }
            }
        }

        &:first-child {
            .pricing-table-col-additional-users {
                left: 0;
            }
        }

        &.is-featured {
            background: $white;
            box-shadow: 0 0 32px rgba(0, 0, 0, .125);

            .pricing-table-col-additional-users {
                background: $gray-light;
                border-top: 1px solid $gray-light;
                left: 0;
                width: 100%;
            }
        }
    }

    &-features {
        margin: 4rem 0 5rem 0;
    }

    &-feature {
        background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='15px' height='11px' viewBox='0 0 15 11' %3e%3cpolygon points='13.636,0 5.455,8.25 1.364,4.125 0,5.5 4.091,9.625 5.455,11 15,1.375' fill='%2317abe5'/%3e%3c/svg%3e ");
        background-position: left center;
        background-repeat: no-repeat;
        font-size: 1.8rem;
        font-weight: 300;
        padding: .35rem 0 .35rem 3.2rem;

        &-inactive {
            background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='11' height='11' viewBox='0 0 11 11'%3e%3cpolygon points='11 9.63 6.88 5.5 11 1.38 9.63 0 5.5 4.13 1.38 0 0 1.38 4.13 5.5 0 9.63 1.38 11 5.5 6.88 9.63 11 11 9.63' fill='%23a5a5a5'/%3e%3c/svg%3e");
            background-repeat: no-repeat;
        }
    }

    &-frontpage,
    &-pricing-personal {
        .pricing-table-col {
            &:nth-of-type(1) {
                padding-right: 5rem;
            }

            &:nth-of-type(2) {
                padding: 5rem;
            }

            &:nth-of-type(3),
            &:nth-of-type(4) {
                padding-left: 5rem;

                .pricing-table-col-additional-users {
                    left: 5rem;
                    width: calc(100% - 5rem);

                    .btn-buy-additional {
                        width: calc(100% - 3rem);
                    }
                }
            }
        }
    }

    &-frontpage {
        .pricing-table-col {
            strong {
                font-size: 2.4rem;
            }
        }
    }
}

#couponAddedModal {
    display: none;
}

@media (max-width: 1440px) {
    .pricing-testimonials,
    .pricing-page-faq,
    .pricing-table {
        max-width: 100%;
        padding-left: 2rem;
        padding-right: 2rem;
        width: 100%;
    }

    .pricing-table-col:nth-of-type(1) {
        padding-right: 6rem;
    }

    .pricing-table-col:nth-of-type(3) {
        padding-left: 6rem;
    }

    .pricing-table-wrapper {
        padding: 15rem 0;
    }

    .pricing-table-col-additional-users {
        left: 6rem;
        width: calc(100% - 6rem);
    }
}

@media (max-width: 1280px) {
    .pricing-table-col:nth-of-type(2) {
        padding: 3rem;
    }

    .pricing-table-col {
        padding-top: 3rem;
    }

    .pricing-table-col strong {
        font-size: 3rem;
    }

    .pricing-table-features {
        margin: 2rem 0 3rem 0;
    }

    .pricing-table-col.pricing-table-col-has-additional-users {
        padding-bottom: 10rem;
    }

    .pricing-table-feature {
        font-size: 1.5rem;
    }

    .pricing-table-wrapper {
        padding: 10rem 0;
    }

    .pricing-testimonials-item {
        padding: 6rem 3.4rem;
    }

    .pricing-testimonials-item:before {
        left: 1rem;
        top: 1rem;
    }

    .pricing-testimonials {
        padding: 10rem 2rem 2rem;
    }
}

@media (max-width: 1240px) {
    .pricing-table {
        flex-wrap: wrap;

        &-pricing-personal {
            .pricing-table-col {
                margin: 2.5rem 0;
                padding: 2.5rem 2.5rem 10rem 2.5rem!important;
                width: 50%;
            }
        }

        &-frontpage {
            .pricing-table-col {
                margin: 2.5rem 0;
                padding: 2.5rem!important;
                width: 50%;
            }
        }
    }
}

@media (max-width: 1180px) {
    .pricing-testimonials-item {
        box-shadow: 0 0 32px rgba(0, 0, 0, .065);
        margin-bottom: .5rem;
        margin-top: .5rem;
        width: calc((100% / 3) - 10px);

        p {
            font-size: 1.4rem;
        }

        .pricing-testimonials-item-footer cite {
            font-size: 1.4rem;
        }
    }
}

@media (max-width: 1040px) {
    .pricing-table-col strong {
        font-size: 2rem;
    }

    .pricing-table-col strong sub {
        bottom: .1rem;
        font-size: 1.2rem;
    }

    .pricing-table-col h3 {
        font-size: 1.4rem;
        margin: 0;
    }

    .pricing-table-col:nth-of-type(1) {
        padding-right: 3rem;
    }

    .pricing-table-col:nth-of-type(3) {
        padding-left: 3rem;
    }

    .pricing-table-wrapper {
        padding: 8rem 0;
    }

    .pricing-table-col-additional-users {
        left: 3rem;
        width: calc(100% - 3rem);
    }

    .pricing-table-col-additional-users .btn-buy-additional {
        font-size: 1.4rem;

        &:before {
            left: -2.2rem;
        }
    }
}

@media (max-width: 960px) {
    .pricing-testimonials-item {
        margin-top: 1rem;
        margin-bottom: 1rem;
        width: 100%;
    }

    .pricing-page-faq h2.pricing-page-faq-header {
        font-size: 3.6rem;
        margin-bottom: 5rem;
    }

    .pricing-page-faq {
        padding: 5rem 2rem 15rem;
    }
}

@media (max-width: 780px) {
    .pricing-table-wrapper {
        padding: 4rem 0;
    }

    .pricing-table {
        flex-wrap: wrap;

        .pricing-table-col {
            margin-bottom: 2.5rem;
            margin-top: 0;
            width: 100%;

            &:last-child {
                margin-bottom: 0;
            }

            .pricing-table-col-additional-users {
                left: 2.5rem!important;
                width: calc(100% - 5rem)!important;
            }

            &.is-featured {
                .pricing-table-col-additional-users {
                    left: 0!important;
                    width: 100%!important;
                }
            }
        }

        .pricing-table-col:nth-of-type(1) {
            padding-right: 0;
        }

        .pricing-table-col:nth-of-type(3) {
            padding-left: 0;
        }
    }

    .page-header {
        min-height: 25rem;

        .wrapper {
            margin-top: 10rem;

            h1 {
                font-size: 4rem;
            }

            p {
                font-size: 1.4rem;
            }
        }
    }

    .pricing-page-faq h2.pricing-page-faq-header {
        font-size: 3rem;
    }

    .pricing-faq-item-title {
        font-size: 1.6rem;
    }

    .pricing-faq-item-content-wrapper {
        font-size: 1.2rem;
    }

    .pricing-faq-item-title:before {
        top: 1.8rem;
    }
}

@media (max-width: 480px) {
    .pricing-table-col-additional-users .btn-buy-additional {
        font-size: 1.4rem;

        &:before {
            left: -2.8rem;
        }
    }
}

/**
 * Upgrade page - related code
 */
.upgrade-page-template {
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;

    &:after {
        background: linear-gradient(to top, #116bb4 0%, #41d3fc 100%);
        content: "";
        display: block;
        height: 100%;
        left: 0;
        opacity: .9;
        position: absolute;
        top: 0;
        width: 100%;
    }

    .wrapper {
        h1,
        p {
            color: $white;
            text-align: center;
        }
    }
}

@media (max-width: 769px) {
    .upgrade-page {
        .modal {
            width: 90%;
        }
    }
}

@media (max-width: 480px) {
    .upgrade-page {
        .modal {
            .modal-content .btn-middle {
                width: 100%;
                margin: 10px 0;
            }
        }
    }
}

/* 
 * Pricing header changes 
 */
.pricing-page-template {
    .mod-calmradio-popup-header-text-wrapper.is-centered {
        text-align: center;
    }

    .mod-calmradio-popup-header-text-wrapper.is-centered > * {
        display: inline-block;
        width: auto;
    }

    .mod-calmradio-popup-header-text-main-text {
        border-bottom: 2px solid $white;
        font-weight: 700;
        padding-bottom: 20px;
    }

    .mod-calmradio-popup-header-text-wrapper.is-centered .mod-calmradio-popup-header-text-title-text {
        font-size: 50px!important;
        margin-bottom: 0!important;
        width: 100%;
    }

    .mod-calmradio-popup-header-text-wrapper.is-centered .mod-calmradio-popup-header-text-helper-text {
        margin-bottom: 0!important;
        width: 100%;
    }

    .mod-calmradio-popup-header-text-wrapper.is-centered .mod-calmradio-popup-header-promo-code-content {
        display: flex;
    }
}

@media (max-width: 800px) {
    .pricing-page-template .mod-calmradio-popup-header-text-wrapper.is-centered .mod-calmradio-popup-header-text-title-text {
        font-size: 33px!important;
    }
}

@media (max-width: 800px) {
    .pricing-page-template .mod-calmradio-popup-header-text-wrapper.is-centered .mod-calmradio-popup-header-text-title-text {
        font-size: 33px!important;
    }
}

@media (max-width: 480px) {
    .pricing-page-template .mod-calmradio-popup-header-text-wrapper.is-centered .mod-calmradio-popup-header-text-title-text {
        font-size: 20px!important;
    }
}