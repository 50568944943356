.js-calmradio-miniplayer {
    transition: all .3s ease-out;

    .btn-icon {
        &.is-hidden {
            display: none;
        }
    }

    .miniplayer-loader {
        animation: miniplayerSpinner 1s linear infinite;
        border: 3px solid #fff;
        border-left-color: transparent;
        border-radius: 50%;
        display: none;
        height: 24px;
        width: 24px;
    }

    &.is-loading {
        padding: .6rem 1rem;

        .btn-icon,
        .miniplayer-label {
            display: none;
        }

        .miniplayer-loader {
            display: block;
        }
    }

    .miniplayer-icon-pause {
        transform: translateY(-1px) scale(0.6666666);
    }

    audio {
        display: none;
    }
}

@keyframes miniplayerSpinner {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}
