.calmradio-channels-slider-item,
.calmradio-channels-search-results-item {
    background: $color-bg-light;
    box-shadow: 0 5px 12px rgba(0, 0, 0, .2);
    border-radius: $border-radius-small;
    cursor: pointer;
    margin: .8rem;
    opacity: 0;
    position: relative;
    transition: all .5s ease-out;
    transform-origin: 49.95% 49.95%;
    transform: scale(1);
    width: calc((100% - 11.2rem) / 8); // 11.2rem => 1.6rem * 7 (between)
    will-change: transform;

    &.is-loading,
    &.is-loading.is-selected {
        animation: loadingItem;
        animation-iteration-count: infinite;
        animation-duration: 2s;
        animation-timing-function: linear;
        box-shadow: 0 5px 12px rgba(0, 0, 0, 0);

        &:nth-child(6n) {
            animation-delay: .2s;
        }

        &:nth-child(6n + 1) {
            animation-delay: .4s;
        }

        &:nth-child(6n + 2) {
            animation-delay: .6s;
        }

        &:nth-child(6n + 3) {
            animation-delay: .8s;
        }

        &:nth-child(6n + 4) {
            animation-delay: 1s;
        }

        &:nth-child(6n + 5) {
            animation-delay: 1.2s;
        }
    }

    &.is-favorited {
        .calmradio-channels-slider-item-fav,
        .calmradio-channels-search-results-item-fav {
            background: $white;

            svg {
                &.is-border {
                    display: none;
                }

                &.is-solid {
                    display: block;
                    margin: 7px 5px 6px 6px;
                }
            }
        }
    }

    &.is-liked {
        .calmradio-channels-slider-item-like,
        .calmradio-channels-search-results-item-like {
            background: $white;
            cursor: auto;

            svg {
                &.is-border {
                    display: none;
                }

                &.is-solid {
                    display: block;
                }
            }
        }
    }

    &.is-disliked {
        filter: grayscale(1);

        .calmradio-channels-slider-item-bg {
            opacity: .33;
        }

        .calmradio-channels-slider-item-dislike,
        .calmradio-channels-search-results-item-dislike {
            background: $white;

            svg {
                &.is-border {
                    display: none;
                }

                &.is-solid {
                    display: block;
                }
            }
        }
    }

    &:before,
    &:after {
        content: "";
        opacity: 0;
        transition: opacity .5s ease-out;
    }

    &-bg {
        background-color: transparent;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: $border-radius-small;
        height: auto;
        left: 0;
        object-fit: cover;
        opacity: 0;
        top: 0;
        transition: opacity .5s ease-out;
        width: 100%;

        &.is-loaded {
            opacity: 1;
        }
    }

    &-helper-image {
        display: block;
        height: auto;
        width: 100%;
    }

    &-play,
    &-pause {
        align-items: center;
        background: rgba(0, 0, 0, .33);
        border: 1px solid $white;
        border-radius: $border-radius-full;
        bottom: 1.2rem;
        box-shadow: 0 2px 2px rgba(0, 0, 0, .25);
        display: flex;
        fill: $white;
        height: 3.6rem;
        justify-content: center;
        padding-left: 5px;
        pointer-events: none;
        position: absolute;
        transition: all .5s ease-out;
        right: 1.2rem;
        width: 3.6rem;
        z-index: 1;
    }

    &-pause {
        display: none;
        padding-left: 0;
    }

    &-fav,
    &-like,
    &-dislike {
        align-items: center;
        background: rgba(0, 0, 0, .33);
        border-radius: $border-radius-full;
        bottom: 2rem;
        cursor: pointer;
        display: flex;
        fill: $white;
        height: 2rem;
        justify-content: center;
        opacity: 0;
        position: absolute;
        transition: all .5s ease-out;
        right: 1.2rem;
        width: 2rem;
        will-change: opacity;
        z-index: 2;

        svg {
            height: 1.2rem;
            width: 1.2rem;

            &.is-border {
                display: block;
            }

            &.is-solid {
                display: none;
            }
        }
    }

    &-fav {
        right: 4rem;
    }

    &-like {
        bottom: 6.8rem;
    }

    &-dislike {
        right: 1.4rem;
    }

    &-like {
        display: none;
    }

    &-expand {
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, .25) 50%, rgba(0, 0, 0, .5) 100%);
        bottom: 0;
        border-radius: 0 0 $border-radius-small $border-radius-small;
        height: 2rem;
        left: 0;
        opacity: 0;
        position: absolute;
        transition: all .5s ease-out;
        width: 100%;
        will-change: opacity;

        &:after,
        &:before {
            background: $white;
            content: "";
            height: 1px;
            left: 50%;
            position: absolute;
            transition: background .25s ease-out, top .25s ease-out;
            top: 70%;
            width: 15px;
        }

        &:after {
            transform-origin: 100% 100%;
            transform: translateX(-15px) rotate(15deg);
        }

        &:before {
            transform-origin: 0 0;
            transform: rotate(-15deg);
        }

    }

    &-play-big,
    &-pause-big {
        align-items: center;
        background: rgba(0, 0, 0, .33);
        border: 1px solid $white;
        border-radius: $border-radius-full;
        box-shadow: 0 2px 2px rgba(0, 0, 0, .25);
        cursor: pointer;
        display: flex;
        fill: $white;
        height: 5rem;
        justify-content: center;
        opacity: 0;
        padding-left: 5px;
        position: absolute;
        right: 50%;
        top: 50%;
        transform: translateX(50%) translateY(-50%);
        transition: all .5s ease-out;
        width: 5rem;
        z-index: 2;
    }

    &-pause-big {
        display: none;
        padding-left: 0;
    }

    &.is-visible {
        opacity: 1;
    }

    &.is-deactivated {
        z-index: 1;
    }

    &.is-active {
        box-shadow: 0 10px 24px rgba(0, 0, 0, .2);
        transform: scale(1.5);
        transform-origin: 49.95% 49.95%;
        z-index: 2;

        &.is-active-left {
            transform-origin: left center;
        }

        &.is-active-right {
            transform-origin: right center;
        }

        .calmradio-channels-slider-item-play,
        .calmradio-channels-search-results-item-play,
        .calmradio-channels-slider-item-pause,
        .calmradio-channels-search-results-item-pause {
            opacity: 0;
        }

        .calmradio-channels-slider-item-play-big,
        .calmradio-channels-slider-item-pause-big,
        .calmradio-channels-slider-item-fav,
        .calmradio-channels-slider-item-like,
        .calmradio-channels-slider-item-dislike,
        .calmradio-channels-slider-item-expand,
        .calmradio-channels-search-results-item-play-big,
        .calmradio-channels-search-results-item-pause-big,
        .calmradio-channels-search-results-item-fav,
        .calmradio-channels-search-results-item-like,
        .calmradio-channels-search-results-item-dislike,
        .calmradio-channels-search-results-item-expand {
            opacity: 1;
        }

        &-next,
        &-prev {
            background: $blue;

            .calmradio-channels-slider-item-bg,
            .calmradio-channels-search-results-item-bg {
                opacity: .2;
                pointer-events: none;
            }

            .calmradio-channels-slider-item-play,
            .calmradio-channels-search-results-item-play,
            .calmradio-channels-slider-item-pause,
            .calmradio-channels-search-results-item-pause {
                opacity: 0;
                pointer-events: none;
            }
        }

        &-next {
            &:before,
            &:after {
                background: $white;
                border-radius: 3px;
                content: "";
                height: 40px;
                left: 40px;
                opacity: 1;
                position: absolute;
                top: 50%;
                transform: rotate(45deg) translateY(-3px);
                width: 4px;
                z-index: 2;
            }

            &:after {
                transform: rotate(-45deg) translateY(3px);
                top: calc(50% - 30px);
            }
        }

        &-prev {
            &:before,
            &:after {
                background: $white;
                border-radius: 3px;
                content: "";
                height: 40px;
                opacity: 1;
                position: absolute;
                right: 40px;
                top: 50%;
                transform: rotate(135deg) translateY(3px);
                width: 4px;
                z-index: 2;
            }

            &:after {
                transform: rotate(-135deg) translateY(-3px);
                top: calc(50% - 30px);
            }
        }
    }

    &.is-expanded-item {
        .calmradio-channels-slider-item-play,
        .calmradio-channels-slider-item-pause,
        .calmradio-channels-slider-item-fav,
        .calmradio-channels-slider-item-like,
        .calmradio-channels-slider-item-dislike,
        .calmradio-channels-search-results-item-fav,
        .calmradio-channels-search-results-item-like,
        .calmradio-channels-search-results-item-dislike,
        .calmradio-channels-search-results-item-expand {
            opacity: 0!important;
        }

        .calmradio-channels-slider-item-play-big,
        .calmradio-channels-slider-item-pause-big {
            opacity: 1!important;
        }

        .calmradio-channels-slider-item-expand,
        .calmradio-channels-search-results-item-expand {
            opacity: 1!important;

            &:after,
            &:before {
                background: $blue;
                top: 150%;
            }
        }
    }

    &.is-expanded-item.is-active .calmradio-channels-slider-item-play-big,
    &.is-expanded-item.is-visible .calmradio-channels-slider-item-play-big,
    &.is-expanded-item.is-active .calmradio-channels-slider-item-pause-big,
    &.is-expanded-item.is-visible .calmradio-channels-slider-item-pause-big {
        opacity: 1!important;
    }

    &.is-played {
        .calmradio-channels-slider-item-play,
        .calmradio-channels-search-results-item-play,
        .calmradio-channels-slider-item-play-big,
        .calmradio-channels-search-results-item-play-big {
            display: none;
        }

        .calmradio-channels-slider-item-pause,
        .calmradio-channels-search-results-item-pause,
        .calmradio-channels-slider-item-pause-big,
        .calmradio-channels-search-results-item-pause-big {
            display: flex;
        }
    }
}

.calmradio-channels-slider-item.is-atmosphere,
.calmradio-channels-search-results-item.is-atmosphere {
    .calmradio-channels-slider-item-fav,
    .calmradio-channels-slider-item-like,
    .calmradio-channels-slider-item-dislike,
    .calmradio-channels-search-results-item-fav,
    .calmradio-channels-search-results-item-like,
    .calmradio-channels-search-results-item-dislike {
        display: none!important;
    }

    .calmradio-channels-slider-item-play,
    .calmradio-channels-search-results-item-play,
    .calmradio-channels-slider-item-pause,
    .calmradio-channels-search-results-item-pause {
        & > svg {
            display: none;
        }

        &:before,
        &:after {
            background: $white;
            content: "";
            height: .2rem;
            left: 1rem;
            position: absolute;
            top: 1.6rem;
            width: 1.4rem;
        }

        &:after {
            height: 1.4rem;
            left: 1.6rem;
            top: 1rem;
            width: .2rem;
        }

        &-big {
            & > svg {
                display: none;
            }

            &:before,
            &:after {
                background: $white;
                content: "";
                height: .2rem;
                left: 1.3rem;
                position: absolute;
                top: 2.3rem;
                width: 2.2rem;
            }

            &:after {
                height: 2.2rem;
                left: 2.3rem;
                top: 1.3rem;
                width: .2rem;
            }
        }
    }
}

.calmradio-channels-search-results-item {
    display: none;
    margin: 0.6rem;

    &.is-selected {
        display: block;
        opacity: 1;
    }

    &.is-loading {
        animation: none;
        box-shadow: 0 5px 12px rgba(0, 0, 0, 0);

        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(4),
        &:nth-child(5),
        &:nth-child(6) {
            animation-delay: 0s;
        }

        .calmradio-channels-search-results-item-play,
        .calmradio-channels-search-results-item-pause {
            opacity: 0;
            pointer-events: none;
        }
    }

    svg {
        pointer-events: none;
    }
}

body.player-not-loaded {
    .calmradio-channels-search-results-item {
        .calmradio-channels-search-results-item-play,
        .calmradio-channels-search-results-item-play-big,
        .calmradio-channels-search-results-item-pause,
        .calmradio-channels-search-results-item-pause-big {
            display: none!important;
        }
    }
}

.calmradio-channels-slider-inner[data-mode="list"] {
    .calmradio-channels-slider-item {
        width: calc((100% - 12.8rem) / 8);
    }
}

.calmradio-channels-slider[data-kind="most_popular_all"] {
    .calmradio-channels-slider-item {
        margin: .8rem;
        width: calc((100% - 16rem) / 10);

        .calmradio-channels-slider-item-fav {
            right: calc(50% + .5rem);
        }

        .calmradio-channels-slider-item-dislike {
            right: calc(50% - 2.5rem);
        }
    }
}

.calmradio-channels[data-show-hidden-items="false"] .calmradio-channels-slider-item.is-disliked {
    display: none;
}

.calmradio-channels[data-show-hidden-items="false"] .calmradio-channels-search-results-item.is-disliked {
    display: block;
}

.calmradio-channels[data-show-hidden-items="false"] .calmradio-channels-slider[data-kind="most_popular_all"] .calmradio-channels-slider-item.is-disliked,
.calmradio-channels[data-show-hidden-items="false"] .calmradio-channels-slider[data-kind="favorites"] .calmradio-channels-slider-item.is-disliked {
    display: block;
}

.calmradio-channels-slider:not([data-kind="most_popular_all"]),
.calmradio-channels-slider:not([data-kind="favorites"]) {
    .calmradio-channels-slider-item {
        &.will-be-disliked {
            opacity: 0;
            transform: scale(0.5);
            width: 0;
        }

        &.will-be-not-disliked {
            display: block;
            opacity: 0;
            transform: scale(0.5);
            width: 0;
        }
    }
}

@media (max-width: 1600px) {
    .calmradio-channels-slider-item,
    .calmradio-channels-search-results-item {
        width: calc((100% - 8rem) / 6); // 8rem => 1.6rem * 5 (between)
    }

    .calmradio-channels-slider-inner[data-mode="list"] {
        .calmradio-channels-slider-item {
            width: calc((100% - 9.6rem) / 6);
        }
    }

    .calmradio-channels-slider[data-kind="most_popular_all"] {
        .calmradio-channels-slider-item {
            margin: .8rem;
            width: calc((100% - 16rem) / 10);
        }
    }

    .calmradio-channels-slider-item {
        &.is-loading:nth-child(7),
        &.is-loading:nth-child(8) {
            display: none;
        }
    }
}

@media (max-width: 1370px) {
    .calmradio-channels-slider[data-kind="most_popular_all"] {
        .calmradio-channels-slider-item {
            .calmradio-channels-slider-item-play,
            .calmradio-channels-slider-item-pause {
                opacity: 0;
                pointer-events: none;
            }
        }
    }
}

@media (max-width: 1240px) {
    .calmradio-channels-slider-item,
    .calmradio-channels-search-results-item {
        margin: .5rem;
        width: calc((100% - 4rem) / 4);
    }

    .calmradio-channels-slider-inner[data-mode="list"] {
        .calmradio-channels-slider-item {
            width: calc((100% - 5rem) / 4);
        }
    }

    .calmradio-channels-slider-item {
        &.is-loading:nth-child(5),
        &.is-loading:nth-child(6) {
            display: none;
        }
    }

    .calmradio-channels-slider[data-kind="most_popular_all"] {
        .calmradio-channels-slider-item {
            margin: .5rem;
            width: calc((100% - 5rem) / 5);
        }
    }
}

@media (max-width: 1040px) {
    .calmradio-channels-slider-item,
    .calmradio-channels-search-results-item {
        margin: .6rem;
        width: calc((100% - 4rem) / 3);

        &.is-active {
            box-shadow: 0 10px 24px rgba(0, 0, 0, .2);
            transform: scale(1);
            z-index: 1;
        }
    }

    .calmradio-channels-slider-item {
        &.is-loading:nth-child(4) {
            display: none;
        }
    }

    .calmradio-channels-slider-inner[data-mode="list"] {
        .calmradio-channels-slider-item {
            width: calc((100% - 3.6rem) / 3);
        }
    }

    .calmradio-channels-slider[data-kind="most_popular_all"] {
        .calmradio-channels-slider-item {
            margin: .5rem;
            width: calc((100% - 5rem) / 5);
        }
    }
}

@media (max-width: 720px) {
    .calmradio-channels-slider-item,
    .calmradio-channels-search-results-item {
        margin: .5rem;
        width: calc((100% - 2rem) / 2); 
    }

    .calmradio-channels-slider-item {
        &.is-loading:nth-child(3) {
            display: none;
        }
    }

    .calmradio-channels-slider-inner[data-mode="list"] {
        .calmradio-channels-slider-item {
            width: calc((100% - 2rem) / 2);
        }
    }

    .calmradio-channels-slider[data-kind="most_popular_all"] {
        .calmradio-channels-slider-item {
            margin: .5rem;
            width: calc((100% - 3rem) / 3);

            &:nth-child(10) {
                display: none;
            }
        }
    }
}

@media (max-width: 480px) {
    .calmradio-channels-slider-item,
    .calmradio-channels-search-results-item {
        margin: .4rem;
        width: calc(100% - 1rem); // 1rem => .5rem * 2 (between)
    }

    .calmradio-channels-slider-item {
        &.is-loading:nth-child(2) {
            display: none;
        }
    }

    .calmradio-channels-slider-inner[data-mode="list"] {
        .calmradio-channels-slider-item {
            width: calc(100% - 0.8rem);
        }
    }

    .calmradio-channels-slider[data-kind="most_popular_all"] {
        .calmradio-channels-slider-item {
            margin: .5rem;
            width: calc((100% - 2rem) / 2);

            &:nth-child(10) {
                display: flex;
            }
        }
    }
}