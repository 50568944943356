// Page Width (wartosci maksymalne)
$width-page: 1400px;
$width-page-narrow: 878px;

// Colors
$white: #fff;
$blue: #17abe5;
$blue-overlay: #14a6e0;
$green: #5db157;
$dark: #323435;
$gray: #525455;
$yellow: #cea043;
$red: #dd1f1f;
$gray-medium: #888;
$gray-light: #f7f8fa;
$gray-light-alt: #e1e1e1;
$border: #acacac;
$border-light: #eee;
$border-medium: #d5d5d5;
$color-text: #212121;
$color-text-light: #989c9d;
$color-bg-light: #f7f8fa;
$color-bg-light2: #f6f6f6;
$color-danger: rgb(200, 50, 50);
$pink: #fc5588;

// Other
$border-radius-small: 8px;
$border-radius-full: 50%;

// Social media color
$color-fb: #345b9b;
$color-g: #f7f8fa; 

// Font families
$font-base: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
$monospace: monospace;


// Modular scale
$ms-base: 17px;
$ms-ratio: $minor-third; // the list of all available ratios you can find here: /vendors/modular-scale/_ratios.scss

// Fluid Typography
$min_width: 320px;
$max_width: 1400px;
$min_font: 16px;
$max_font: 18px; 

// Baseline
$baseline: calc(1.6rem / 6); 


// Font size map


$h1: ( 
	min: (ms(3)),
	max: (ms(8))
); 

$h2: ( 
	min: (ms(2)),
	max: (ms(5))
);
$h3: ( 
	min: (ms(1)),
	max: (ms(4))
);
$h4: ( 
	min: (ms(0)),
	max: (ms(3))
);

$h5: ( 
	min: (ms(0)),
	max: (ms(2))
);

$h6: ( 
	min: (ms(0)),
	max: (ms(1))
);
