.calmradio-remarketing-popup {
    align-items: center;
    background: linear-gradient(to right, #ff847d, #ffbd55);
    border-radius: 3px;
    display: flex;
    height: 700px;
    left: 50%;
    max-height: 90vh;
    max-width: 90vw;
    padding-right: 240px;
    position: fixed;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: 740px;
    z-index: 100001;

    &:after {
        background: url('../images/waves.png');
        background-position: center center;
        background-repeat: no-repeat;
        background-size: 200% auto;
        content: "";
        height: 100%;
        opacity: .35;
        position: absolute;
        width: 100%;
        z-index: 1;
    }

    &:before {
        background: url('../images/remarketing-bg.png');
        background-size: cover;
        border-radius: 0 3px 3px 0;
        content: "";
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
        width: 240px;
        z-index: 2;
    }

    &-overlay {
        background: rgba(0, 0, 0, .5);
        height: 100%;
        left: 0;
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 100000;

        &.is-hidden {
            display: none;
            pointer-events: none;
        }
    }

    &-close {
        align-items: center;
        background: $color-bg-light;
        border-radius: 50%;
        color: $color-text;
        display: flex;
        font-size: 26px;
        height: 32px;
        justify-content: center;
        line-height: 1;
        position: absolute;
        right: 0;
        text-decoration: none;
        top: 0;
        transform: translateX(50%) translateY(-50%);
        width: 32px;
        z-index: 100002;

        &:active,
        &:focus,
        &:hover {
            background: $color-text;
            color: $color-bg-light;
            text-decoration: none;
        }

        & > span {
            position: relative;
            top: -2px;
        }
    }

    &-content {
        padding: 50px;
        position: relative;
        z-index: 3;
    }

    &-title {
        color: $color-text;
        font-size: 42px;
        font-weight: 500;
    }

    &-price {
        color: $color-bg-light;
        font-size: 82px;
        font-weight: 600;

        sub {
            bottom: 0;
            font-size: 18px;
            font-weight: 400;
            top: 0;
        }
    }

    &-description {
        color: $color-bg-light;
        font-size: 20px;
        font-weight: 400;
        letter-spacing: -0.5px;
        line-height: 28px;
    }

    &-buy {
        border: none;
        margin: 40px 0 15px 0;
        padding-left: 0;
        padding-right: 0;
        text-align: center;
        width: 240px;
    }

    &-remind-me-later {
        color: $color-bg-light;
        display: inline-block;
        font-size: 16px;
        font-weight: 400;
        text-align: center;
        width: 240px;

        &:active,
        &:focus,
        &:hover {
            color: $color-bg-light;
            text-decoration: underline;
        }
    }
}

@media (max-width: 800px), (max-height: 800px) {
    .calmradio-remarketing-popup {
        &-content {
            padding: 30px;
        }

        &-title {
            font-size: 32px;
        }

        &-price {
            font-size: 56px;

            sub {
                font-size: 14px;
            }
        }

        &-description {
            font-size: 16px;
            line-height: 22px;
        }

        &-remind-me-later {
            font-size: 13px;
        }
    }
}

@media (max-width: 680px), (max-height: 600px) {
    .calmradio-remarketing-popup {
        padding-right: 0;

        &:before {
            display: none;
        }

        &-content {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            text-align: center;
        }

        &-title {
            font-size: 28px;
            text-align: center;
            width: 100%;
        }

        &-price {
            font-size: 48px;
            text-align: center;
            width: 100%;

            sub {
                font-size: 13px;
            }
        }

        &-description {
            font-size: 14px;
            line-height: 20px;
        }

        &-remind-me-later {
            font-size: 12px;
            width: 100%;
        }
    }
}

@media (max-height: 500px) {
    .calmradio-remarketing-popup {
        &-content {
            padding: 20px;
        }

        &-title {
            font-size: 24px;
        }

        &-price {
            font-size: 32px;

            sub {
                font-size: 12px;
            }
        }

        &-description {
            font-size: 13px;
            line-height: 18px;
        }

        &-buy {
            margin: 20px 0;
        }
    }
}
