.top-promotions {
    text-align: center;
    font-size: 16px;
    color: #525455;
    font-weight: normal;
    position: relative;
    z-index: 103;
    height: 3.5rem;
    overflow: hidden;
    transition: background-color .6s ease-in-out;

    .mod_calmradio_promo {
        position: relative;
        top: 0;
        transition: all .4s ease-in-out;

        & > div {
            height: 3.5rem;
            line-height: 3.5rem;
            overflow: hidden;
        }

        .ticker {
            display: inline-block;

            p {
                font-size: 16px;
            }

            * {
                display: inline-block;
                white-space: nowrap
            }
        }

        strong {
            font-weight: 500;
        }

        p {
            margin: 0;
        }
    }

    &.loading {
        .mod_calmradio_promo {
            top: -6rem;
        }
    }

    &.done {
        .mod_calmradio_promo {
            top: -12rem;
        }
    }

    &.action-success {
        background-color: $green!important
    }

    &.action-error {
        background-color: $color-danger!important
    }

    .ticker-wrapper {
        position: relative
    }

    &.effect-scroll {
        .mod_calmradio_promo {
            max-width: 100%;
            overflow: hidden;

            .ticker {
                left: 100%;
                position: absolute;
                width: auto;
                animation-timing-function: linear;
                animation-name: move_to_left;
                animation-iteration-count: 1;
                animation-duration: 40s;
                animation-play-state: running;


                & > * {
                    left: 0;
                }
            }

            &:hover {
                .ticker {
                    animation-play-state: paused;
                }
            }
        }
    }

    &.effect-fade {
        .mod_calmradio_promo {
            .ticker {
                & > * {
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    width: 100%;
                    transform: translate(-50%,-50%);
                    opacity: 0;
                    transition: all .3s ease-out
                }

                & > .active {
                    opacity: 1
                }
            }
        }
    }

    &.no-transition {
        .ticker {
            animation-play-state: paused
        }
    }

    .cckw {
        display: inline-block;
        font-weight: 700;
        text-align: center;
        font-size: 18px;

        & > div {
            padding: 0 2px;
            display: inline-block;
            vertical-align: top;

            & > span {
                padding: 5px 5px 0 4px;
                display: inline-block;
                line-height: 1;
            }
        }
    }

    .smalltext {
        font-size: 10px;
        font-weight: 400;
        text-transform: uppercase;
    }
}

@keyframes blink {
    0% {
        opacity: .2
    }

    20% {
        opacity: 1
    }

    100% {
        opacity: .2
    }
}

@keyframes move_to_left {
    from {
        left: 100%
    }

    to {
        left: -150%
    }
}

@media (max-width: 720px) {
    .top-promotions {
        display: none;
    }
}
