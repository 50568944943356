// Buttons -----------------------------------------------------------------
.pagination-prev a,
.pagination-next a,
.btn {
    background: $blue;
    border-radius: 32px;
    border: 2px solid $blue;
    color: $white;
    cursor: pointer;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    text-decoration: none;
    transition: all 0.24s ease;
    will-change: transform;

    &:hover {
        color: $white;
        text-decoration: none;
        transform: translateY(-2px);
    }

    &:focus {
        color: $white;
        outline: none;
        text-decoration: none;
    }

    &:active {
        box-shadow: 1px 2px 2px rgba(black, 0.2);
        color: $white;
        text-decoration: none;
    }

    &.is-disabled,
    &:disabled {
        opacity: .5;
        pointer-events: none;
    }

    &-small {
        font-size: 1.6rem;
        font-weight: 300;
        padding: .6rem 3rem;
    }

    &-medium {
        box-shadow: 2px 3px 3px rgba(black, .2);
        font-size: 2rem;
        padding: .9rem 4rem;
    }

    &-large {
        box-shadow: 2px 3px 3px rgba(black, .2);
        font-size: 2.4rem;
        padding: 1.2rem 7.5rem;

        &:active {
            box-shadow: 3px 5px 5px rgba(black, 0.2);
        }

        &.btn-outline {
            border-width: 2px;
        }
    }

    &-light {
        background: $white;
        color: $color-text;

        &:hover {
            color: $color-text;
        }
    }

    &-green {
        background: $green;
        border: 2px solid $green;
    }

    &-gray {
        background: $gray;
        border: 2px solid $gray;
    }

    &-outline {
        background: none;
        box-shadow: none;
        color: $blue;

        &:hover {
            border-color: $dark;
            color: $dark;
            opacity: 1;
        }

        &.btn-green {
            border-color: $green !important;
            box-shadow: none;
            color: $green !important;

            &:hover {
                opacity: .75;
            }
        }

        &.btn-gray {
            border-color: #acacac;
            color: $gray;

            &:hover {
                border-color: $blue;
                color: $blue;
            }
        }
    }

    &-full-width {
        text-align: center;
        width: 100%;
    }
}

[type=button],
[type=submit],
button {
    @extend .btn;
    appearance: none;
}

.pagination-prev a,
.pagination-next a {
    font-size: 1.6rem;
    font-weight: 300;
    padding: .9rem 4rem;
}

.mod-calmradio-login-button {
    border: none!important;
}

.subscription-success a button {
    appearance: none;
    background: $blue;
    border-radius: 32px;
    border: 2px solid $blue;
    color: $white;
    cursor: pointer;
    display: inline-block;
    font-weight: 400;
    vertical-align: middle;
    line-height: 30px;
    text-align: center;
    text-decoration: none;
    transition: all 0.24s ease;
    will-change: transform;
    box-shadow: 2px 3px 3px rgba(black, .2);
    font-size: 2.4rem;
    padding: 1.2rem 7.5rem;
    width: auto;

    &:active {
        box-shadow: 3px 5px 5px rgba(black, 0.2);
    }

    &.btn-outline {
        border-width: 2px;
    }
}

@media (max-width: 1040px) {
    .pagination-prev a,
    .pagination-next a,
    .btn {
        &-small {
            font-size: 1.4rem;
            padding: .9rem 1.5rem;
        }

        &-medium {
            font-size: 1.6rem;
            padding: .9rem 3rem;
        }

        &-large {
            font-size: 2rem;
            padding: .9rem 4rem;
        }
    }
}
