.heading {
    height: 100vh;
    min-height: 540px;
    position: relative;

    &.has-top-promo {
        height: 100vh;
    }

    &-frontpage {
        &:after {
            background: linear-gradient(rgba(0, 0, 0, .25), rgba(0,0,0,0));
            content: "";
            height: 200px;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;
        }

        .heading-overlay {
            content: "";
            height: 100%;
            position: absolute;
            top: 0;
            transition: all 1s ease-out;
            width: 100%;
            will-change: opacity;
            z-index: 1;
        }

        .video-cover {
            bottom: 0;
            height: 100%;
            overflow: hidden;
            position: absolute;
            top: 0;
            width: 100%;

            video {
                height: auto;
                left: 50%;
                min-height: 100%;
                min-width: 100%;
                position: absolute;
                top: 50%;
                transform: translate(-50%,-50%);
                width: auto;
            }
        }

        &.is-guest {
            overflow: hidden;

            .chevron-icon {
                bottom: 2rem;
                fill: $white;
                left: 50%;
                position: absolute;
                transform: translateX(-50%);
                z-index: 10;

                .arrow {
                    animation: chevron-move 3s ease-out infinite;
                    margin-left: -1px;
                    transform: translate(-.5px, 0);
                    transform-origin: 0 0;
                }
            }
        }
    }

    &-content {
        color: $white;
        left: 50%;
        max-width: 95%;
        opacity: 1;
        padding: 0;
        pointer-events: auto;
        position: absolute;
        text-align: center;
        top: calc(50% + 50px);
        transform: translateX(-50%) translateY(-50%);
        transition: opacity 2s ease-out;
        width: 100%;
        will-change: opacity;
        z-index: 2;

        &:after {
            background: url('../images/waves.png');
            background-position: center center;
            background-repeat: no-repeat;
            content: "";
            height: 300px;
            left: 50%;
            position: absolute;
            top: 0;
            transform: translateX(-50%);
            width: 1280px;
            z-index: -1;
        }

        &.is-hidden {
            opacity: 0;
            z-index: 1;
        }

        &-volume-ui {
            align-items: center;
            border: 1px solid rgba(255, 255, 255, .6);
            border-radius: $border-radius-full;
            bottom: 2.8rem;
            display: flex;
            height: 3.6rem;
            justify-content: center;
            opacity: 1;
            pointer-events: auto;
            position: absolute;
            right: 10rem;
            transition: all 1s ease-out;
            transform: translateY(0);
            width: 3.6rem;
            will-change: opacity, transform;
            z-index: 10;

            &:active,
            &:focus,
            &:hover {
                border: 1px solid rgba(255, 255, 255, 1);
            }

            &.is-hidden {
                opacity: 0;
                pointer-events: none;
                transform: translateY(10px);
            }

            & > .is-mute,
            & > .is-unmute {
                display: none;
                fill: $white;
                pointer-events: none;
            }

            &.is-mute {
                & > .is-mute {
                    display: block;
                }

                & > .is-unmute {
                    display: none;
                }
            }

            &.is-unmute {
                & > .is-mute {
                    display: none;
                }

                & > .is-unmute {
                    display: block;
                }
            }
        }

        &.heading-content-play-demo,
        &.heading-autoplay {
            padding-bottom: 8rem;
        }
    }

    &-title {
        color: $white;
        font-weight: 400;
        line-height: 1.1;
        margin: 0 0 20px 0;
        pointer-events: auto;
        transition: color 2s ease-out;

        &.is-hidden {
            color: rgba(255, 255, 255, 0);
            pointer-events: none;
        }
    }

    &-subtitle {
        font-size: 2.4rem;
        margin: 4rem 0 6rem 0;
    }

    &-signup {
        bottom: 0;
        opacity: 1;
        position: absolute;
        transform: translateY(0);
        transition: all 2s ease-out;
        width: 100%;

        &.is-hidden {
            opacity: 0;
            transform: translateY(14px);
        }

        .btn {
            border-color: $white;
            color: $color-text;
            display: inline-block;
            padding: 1.2rem 5rem;
            position: relative;
            transition: all .5s ease-out;
            transform: translateY(0);
            width: auto;
            will-change: opacity, transform;

            &:active,
            &:focus,
            &:hover {
                color: $color-text;
                transform: translateY(-2px);
            }

            &-icon {
                border: 1px solid $border-light;
                border-radius: 50%;
                fill: $blue;
                left: .9rem;
                height: 4.6rem;
                padding: 1.1rem 1.2rem;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                width: 4.6rem;
            }
        }

        small {
            display: block;
            font-size: 1.4rem;
            margin-top: 2.5rem;
            opacity: .75;
            transition: opacity .5s ease-out;
            will-change: opacity;

            &.is-hidden {
                opacity: 0;
            }
        }

        &.heading-common-button {
            bottom: 15rem;
            text-align: center;
            z-index: 2;
        }
    }

    &-play-demo {
        .btn {
            padding: 1.2rem 5rem 1.2rem 7rem!important;
        }
    }

    &-autoplay {
        z-index: 2;

        &.is-hidden {
            pointer-events: none;
            z-index: 1;
        }
    }

    &-content-play-demo {
        z-index: 2;

        &.is-hidden {
            pointer-events: none;
            z-index: 1;
        }
    }

    &-subscribe {
        padding: 1.2rem 5rem;

        .btn-icon {
            display: none;
        }
    }
}

h2.heading-title,
h3.heading-title {
    margin: 0 0 30px 0;
}

body.player-loaded .heading.heading-frontpage {
    overflow: hidden;
}

.top-promotions-wrapper:empty + .heading.has-top-promo {
    height: 100vh;
}

.top-promotions-wrapper + .main-nav {
    top: 6rem;
}

.top-promotions-wrapper:empty + .main-nav {
    top: 4rem;
}

.main-nav {
    left: 0;
    position: absolute;
    top: 4rem;
    width: 100%;
    z-index: 100;

    .wrapper-wide {
        align-items: center;
        display: flex;
        margin: 0 auto;
        max-width: calc(100vw - 20rem);
    }

    .logo {
        background: url('../images/logo-light.png') no-repeat;
        background-size: contain;
        background-position: center left;
        display: block;
        height: 34px;
        overflow: hidden;
        position: relative;
        text-indent: -200px;
        top: 0;
        width: 205px;
    }

    &.not-frontpage {
        .logo {
            background: url('../images/logo-dark.png') no-repeat;
            background-size: contain;
            background-position: center left;
        }
    }

    .nav.menu {
        display: flex;
        list-style-type: none;
        margin: 0 0 0 6rem;
        padding: 0;

        li {
            font-size: 1.4rem;
            padding: 0;

            a {
                background: transparent;
                color: $white;
                display: inline-block;
                padding: 0 1rem;
                position: relative;
                white-space: nowrap;

                &:active,
                &:focus,
                &:hover {
                    background: transparent;
                    text-decoration: none;

                    &:after {
                        background: $white;
                        bottom: 1px;
                        content: "";
                        height: 1px;
                        left: 1rem;
                        position: absolute;
                        right: 1rem;
                    }
                }
            }
        }

        &.is-channel-lp,
        & ~ .nav.menu {
            margin-left: auto;

            li {
                margin-left: 2rem;

                a {
                    border: 1px solid rgba(255, 255, 255, .6);
                    border-radius: 2rem;
                    display: inline-block;
                    height: 4rem;
                    line-height: 3.8rem;
                    padding: 0 3rem;
                    will-change: opacity;

                    &:active,
                    &:focus,
                    &:hover {
                        border: 1px solid $white;
                        opacity: .75;
                        text-decoration: none;

                        &:after {
                            display: none;
                        }
                    }
                }
            }
        }

        &:first-of-type + .language-switcher,
        &:first-of-type + .header-user-avatar {
            margin-left: auto;

            & + .language-switcher {
                margin-left: 2rem;
            }
        }
    }

    .header-user-avatar {
        align-items: center;
        border: 1px solid rgba(255, 255, 255, .6);
        border-radius: 50%;
        cursor: pointer;
        display: flex;
        height: 4rem;
        justify-content: center;
        margin-left: 2rem;
        padding: .1rem;
        position: relative;
        width: 4rem;
        will-change: opacity;

        span {
            background-position: center center;
            background-repeat: no-repeat;
            background-size: cover;
            border-radius: 50%;
            display: block;
            height: 3.2rem;
            overflow: hidden;
            width: 3.2rem;

            svg {
                fill: $white;
            }

            &.is-empty {
                height: 2.6rem;
                width: 2.6rem;
            }
        }

        &:active,
        &:focus,
        &:hover {
            border: 1px solid rgba(255, 255, 255, 1);
        }

        &-dropdown {
            background: $white;
            border-radius: $border-radius-small;
            box-shadow: 0 3px 5px rgba(0, 0, 0, .25);
            display: none;
            list-style-type: none;
            margin: 0;
            padding: 2.4rem;
            position: absolute;
            right: 0;
            top: 5.6rem;
            width: 20rem;
            z-index: 100;

            &.is-active {
                display: block;
            }

            &:before {
                border: 5px solid $white;
                border-left-color: transparent;
                border-right-color: transparent;
                border-top-color: transparent;
                content: "";
                height: 10px;
                position: absolute;
                right: 15px;
                top: -10px;
                width: 10px;
            }

            a {
                color: $gray-medium;
                display: block;
                font-size: 1.4rem;
                padding: .8rem 0;

                &:active,
                &:focus,
                &:hover {
                    color: $color-text;
                }
            }
        }
    }

    &.not-frontpage {
        .nav.menu {
            li {
                a {
                    color: $color-text;

                    &:active,
                    &:focus,
                    &:hover {
                        color: $blue;

                        &:after {
                            background: $blue;
                        }
                    }
                }
            }

            & ~ .nav.menu {
                li {
                    a {
                        border-color: $border;

                        &:active,
                        &:focus,
                        &:hover {
                            border-color: $blue;
                        }
                    }
                }
            }
        }

        .header-user-avatar {
            border-color: $border;

            span {
                svg {
                    fill: $dark;
                }
            }
        }
    }

    &.main-nav-half-colors {
        .nav.menu {
            & ~ .nav.menu {
                li {
                    a {
                        border-color: $border;
                        color: $color-text;

                        &:active,
                        &:focus,
                        &:hover {
                            border-color: $blue;
                            color: $blue;

                            &:after {
                                background: $blue;
                            }
                        }
                    }
                }
            }
        }

        .header-user-avatar {
            border-color: $border;

            span {
                svg {
                    fill: $dark;
                }
            }
        }
    }

    .js-toggle-mobile-menu {
        display: none;
    }
}

body.is-member .main-nav .is-for-non-members {
    display: none!important;
}

.top-promotions-wrapper + .main-nav.not-frontpage {
    top: 6rem;
}

@keyframes chevron-move {
    0% {
        opacity: .5;
        transform: translate(-.5px, -2.5px);
    }

    50% {
        opacity: 1;
        transform: translate(-.5px, 7.5px);
    }

    100% {
        opacity: 1;
        transform: translate(-.5px, -2.5px);
    }
}

@media (max-width: 1600px) {
    .main-nav {
        .wrapper-wide {
            max-width: 1400px;
            width: 100%;
        }
    }

    .heading-content-volume-ui {
        right: calc((100% - 1400px) / 2);
    }

    .main-nav .nav.menu~.nav.menu li a {
        padding: 0 1.5rem;
    }

    .main-nav .nav.menu {
        margin-left: 2rem;
    }
}

@media (max-width: 1440px) {
    .main-nav {
        top: 2rem;

        .wrapper-wide {
            max-width: 100%;
            padding: 0 2rem;
            width: 100%;
        }
    }

    .heading-content-volume-ui {
        right: 2rem;
    }
}

@media (max-width: 1280px) {
    .heading {
        height: 90vh;
    }

    .main-nav {
        .nav.menu {
            margin-left: 2rem;

            & ~ .nav.menu li a {
                padding: 0 1.5rem;
            }
        }
    }
}

@media (max-width: 1280px) and (orientation: landscape) {
    .heading {
        height: 100vh;
    }
}

@media (max-width: 1180px) {
    body.is-logged-in,
    body.is-guest {
        .main-nav {
            .nav.menu:not(.is-channel-lp) {
                display: none;
            }

            .header-user-avatar {
                display: none;
            }

            .js-toggle-mobile-menu {
                display: block;
                margin-left: 2.4rem;

                & > span {
                    background: $white;
                    border-radius: 2px;
                    display: block;
                    height: 2px;
                    transition: all .3s ease-out;
                    width: 30px;

                    &:nth-child(1) {
                        transform: translateY(-5px);
                        transform-origin: left center;
                    }

                    &:nth-child(2) {
                        transform-origin: center center;
                    }

                    &:nth-child(3) {
                        transform: translateY(5px);
                        transform-origin: right center;
                    }
                }

                &.is-open {
                    & > span {
                        &:nth-child(1) {
                            transform: translateY(-9px) rotate(45deg);
                        }
    
                        &:nth-child(2) {
                            transform: scaleX(0);
                        }
    
                        &:nth-child(3) {
                            transform: translateX(-9px) translateY(-13px) rotate(-45deg);
                        }
                    }
                }
            }

            &.not-frontpage,
            &.main-nav-half-colors {
                .js-toggle-mobile-menu {
                    display: block;
                    margin-left: 2.4rem;
    
                    & > span {
                        background: $dark;
                    }
                }
            }
        }
    }
}

@media (max-width: 960px) {
    .heading-subtitle {
        margin: 2rem 0 3rem;
    }

    .heading {
        &-signup {
            .btn {
                padding: .6rem 2.5rem;

                &-icon {
                    border: 1px solid $border-light;
                    border-radius: 50%;
                    fill: $blue;
                    left: .9rem;
                    height: 3.4rem;
                    padding: .5rem .6rem;
                    width: 3.4rem;
                }
            }
        }

        &-play-demo {
            .btn {
                padding: .6rem 2.5rem .6rem 6rem!important;
            }
        }
    }
}

@media (max-width: 720px) {
    .top-promotions-wrapper + .main-nav {
        top: 2rem;
    }

    h1.heading-title,
    h2.heading-title,
    h3.heading-title {
        line-height: 1.2;
        margin: 0 0 10px 0;
        padding: 0;
    }

    h2.heading-title,
    h3.heading-title {
        margin: 0 0 15px 0;
    }

    .heading-subtitle {
        font-size: 1.4rem;
        line-height: 1.4;
        padding: 0 2rem;
    }

    .heading {
        &.has-top-promo {
            height: 100vh;
        }
    }

    .top-promotions-wrapper + .main-nav.not-frontpage {
        top: 1rem;
    }

    body .heading-content {
        padding: 0 0 80px 0;
        top: 50%;
    }
}

@media (max-width: 400px) {
    .main-nav,
    .top-promotions-wrapper + .main-nav {
        top: 1rem;

        .wrapper-wide {
            padding: 0 1rem 0 0;
        }

        .nav.menu ~ .nav.menu li {
            margin-left: 1rem;
        }

        .logo {
            left: 10px;
            width: 170px;
        }
    }
}

@media (max-height: 900px) {
    body .heading-subtitle {
        font-size: 2rem;
    }
}

@media (max-height: 720px) {
    body .heading-subtitle {
        font-size: 1.8rem;
        margin: 20px 0;
    }

    .heading-content {
        padding: 0 0 80px 0;
    }

    h1.heading-title,
    h2.heading-title,
    h3.heading-title {
        line-height: 1.2;
        margin: 0 0 10px 0;
        padding: 0;
    }

    h2.heading-title,
    h3.heading-title {
        margin: 0 0 15px 0;
    }

    .heading-signup.heading-common-button {
        bottom: 10rem;
    }
}

@media (max-height: 600px) {
    body .heading-subtitle {
        font-size: 1.5rem;
    }

    body .heading-signup small {
        font-size: 1.2rem;
        margin-top: 1.5rem;
    }

    body .heading-signup .btn,
    body .heading-signup [type=button],
    body .heading-signup [type=submit],
    body .heading-signup button {
        font-size: 1.4rem;
    }
}

@media (max-height: 500px) {
    body .heading-subtitle {
        font-size: 1.3rem;
    }
}

@media (max-height: 450px) {
    body .heading-subtitle {
        font-size: 1.2rem;
    }
}

@media (max-width: 340px) {
    .main-nav .logo {
        height: 60px;
        top: 0;
        width: 160px;
    }
}