:root{
    --swiper-navigation-top-offset: 55%;
    --swiper-navigation-sides-offset: 20px !important;
    @media screen and (max-width: 1279px) {
        --swiper-navigation-sides-offset: 15px !important;
    }
}
#k2Container{
    .k2-header{
        //min-height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        background-color: transparent;
        .channels-guide{
            padding: 12rem 0 0 5rem;
            h1, h2, h3, h4, h5{
                color: #fff;
            }
            @media screen and (max-width: 1279px) {
                position: fixed;
                bottom: 2rem;
                z-index: 20;
                padding: 0;
                transform: translateX(-50%);
                left: 50%;
                z-index: 100;
            }
            .channels-guide-wrapper{
                margin: 0 auto;
                position: relative;
                transition: all .3s ease-out;
                display: flex;
                justify-content: space-between;
                align-items: end;
                .menu-arrow-wrapper{
                    position: absolute;
                    bottom: 0;
                    right: 2rem;
                    transform: rotate(180deg) translateY(66%);
                    pointer-events: none;
                    @media screen and (min-width: 1280px) {
                        display: none;
                    }
                }
                .calmradio-channels-groups-item{
                    &.is-active{
                        .calmradio-channels-groups-item-label{
                            opacity: 1;
                            font-weight: 600 !important;
                        }
                    }
                    transition: all .3s;
                    display: inline-flex;
                    width: fit-content !important;
                    &.is-active{
                        pointer-events: none;
                    }
                    &:hover{
                        &:not(.is-active){
                            transform: scale(1.2);
                            transform-origin: left center;
                            @media screen and (max-width: 1279px) {
                                transform: none;
                            }
                        }
                    }
                    @media screen and (max-width: 1279px) {
                        display: none;
                        &.is-active{
                            pointer-events: all;
                            display: flex;
                            //margin-right: 5rem;
                            min-width: 300px;
                        }
                    }
                }
                .calmradio-channels-groups-item-label{
                    font-size: 2.2rem;
                    opacity: .5;
                    margin: .8rem 0;
                    font-weight: 700 !important;
                    text-transform: uppercase;
                    position: relative;
                    top: auto;
                    left: auto;
                    width: 100%;
                    transform: none;
                    line-height: 1.1;
                    @media screen and (max-width: 1279px) {
                        margin: 0;
                        padding: 2rem;
                        white-space: nowrap;
                    }
                }
            }
        }
        .channels-guide-wrapper{
            margin: 10vh auto 0;
            margin-bottom: 0;
            h1, h2, h3, h4, h5{
                color: #fff;
            }
            @media (max-width: 1536px) {
                margin: 8rem auto 0;
            }
            @media (max-width: 1279px) {
                &:not(.channels-guide-desc){
                    background-color: rgba(0,0,0,.25);
                    -webkit-backdrop-filter: blur(20px);
                    backdrop-filter: blur(20px);
                    border-radius: 11px;
                }
            }
            @media screen and (max-width: 768px) {
                max-width: calc(100% - 2rem);
            }
            &.wrapper{
                margin-top: 20vh;
                @media (max-width: 768px) {
                    @media (orientation: portrait) {
                        margin-top: 16vh;
                    }
                    @media (orientation: landscape) {
                        margin-bottom: 10vh;
                    }
                }
            }
            + .channels-guide-wrapper{
                margin-bottom: 0;
            }
            h1 {
                font-size: 4rem;
                font-weight: 700;
                text-transform: uppercase;
                text-align: center;
                padding: 0 31%;
                @media (max-width: 1536px) {
                    padding: 0 12vw;
                    margin-bottom: 6rem;
                    @media (orientation: landscape) {
                        padding: 0 20vw;
                        font-size: 2.8rem;
                        margin-bottom: 0;
                    }
                }
                @media (max-width: 768px) {
                    font-size: 2.8rem;
                    padding: 0 1rem;
                }
            }
            .swiper-wrapper{
                min-height: 32vh;
                @media (max-width: 1024px) {
                    @media (orientation: portrait) {
                        min-height: 38vh;
                    }
                    @media (orientation: landscape) {
                        min-height: 62vh;
                    }
                }
            }
            .calmradio-channels-groups{
                @media (max-width: 1279px) {
                    align-items: center;
                }
                &-item{
                    min-height: 30vh;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-end;
                    transition: all .3s ease-out;
                    @media (max-width: 1024px) {
    
                        @media (orientation: portrait) {
                            min-height: 36vh;
                        }
                        @media (orientation: landscape) {
                            min-height: 60vh;
                        }
                    }
                    &:hover{
                        min-height: 32vh;
                        @media (max-width: 1024px) {
                            @media (orientation: portrait) {
                                min-height: 38vh;
                            }
                            @media (orientation: landscape) {
                                min-height: 62vh;
                            }
                        }
                    }
                    &-bg{
                        border-radius: 10px;
                    }
                }
            }
            &.channels-guide-channels{
                margin: 2rem auto 0;
                .channels-guide-channels-rows{
                    display: flex;
                    flex-direction: column;
                    gap: 1rem;
                    .channels-guide-channels-row{
                        padding: 3.5rem 5rem;
                        background-color: rgba(100,100,100,.3);
                        -webkit-backdrop-filter: blur(20px);
                        backdrop-filter: blur(20px);
                        border-radius: 11px;
                        &-title{
                            font-size: 3rem;
                            font-weight: 700;
                            text-transform: uppercase;
                            margin: 0 0 1rem;
                            a{
                                display: inline-flex;
                                align-items: center;
                                color: #fff;
                                line-height: 1;
                                gap: 4rem;
                                &:hover,
                                &:active,
                                &:focus{
                                    text-decoration: none;
                                }
                                span{
                                    line-height: 1;
                                }
                                .title-content{
                                    transform: translateY(-2px);
                                }
                            }
                        }
                        @media (max-width: 1279px) {
                            padding: 2rem;
                            &-title{
                                font-size: 2.6rem;
                            }
                        }
                        .see-all{
                            padding: 6px 10px;
                            border-radius: 15px;
                            background-color: rgba(0,0,0,.75);
                            color: #fff;
                            text-transform: none;
                            font-size: 1.3rem;
                            display: inline-flex;
                            align-items: center;
                            gap: 1rem;
                            border-width: 0;
                            font-weight: 400;
                            white-space: nowrap;
                            line-height: 1.5;
                            &:hover,
                            &:active,
                            &:focus{
                                text-decoration: none;
                            }
                            svg{
                                width: 20px;
                                height: 20px;
                            }
                        }
                    }
                }
                .channels-guide-channels-slider{
                    margin: 0;
                    &-inner{
                        grid-template-columns: repeat(12, 1fr);
                        gap: 2rem;
                        padding: 1.5rem;
                        @media screen and (max-width: 1536px) {
                            grid-template-columns: repeat(9, 1fr);
                            padding: 0;
                        }
                        @media screen and (max-width: 1279px) {
                            grid-template-columns: repeat(auto-fit, minmax(70px, 1fr));
                            gap: 10px;
                        }
                    }
                }
            }
            &.channels-guide-desc{
                margin: 0 auto;
                padding: 3.5rem 60% 3.5rem 5rem;
                h1 {
                   font-size: 2.6rem;
                   font-weight: 400;
                   text-align: left;
                   padding: 0;
                   margin-bottom: 2rem;
                }
                p{
                    max-width: 100%;
                    text-align: left;
                    font-size: inherit;
                    line-height: 1.3;
                    color: #fff;
                }
                @media (max-width: 1279px) {
                    padding: 25rem 50% 3.5rem 0;
                }
                @media (max-width: 767px) {
                    padding: 35rem 0 3.5rem;
                    @media screen and (orientation: landscape) {
                        padding: 10rem 0 3.5rem;
                    }
                    h1 {
                        text-align: center;
                    }
                    p{
                        text-align: center;
                    }
                }
                @media (max-width: 768px) {
                    max-width: calc(100% - 2rem);
                }
            }
        }
        .menu-wrapper{
            .channels-guide-wrapper{
                @media (max-width: 1279px) {
                    max-width: 100%;
                    background-color: rgba(255,255,255,.1);
                }
            }
        }
        .open{
            @media screen and (max-width: 768px) {
                bottom: 0;
                left: 0;
                width: 100%;
                transform: none;
                margin-bottom: 0 !important;
            }
            .channels-guide-wrapper{
                padding: 3rem 3rem 5rem;
                justify-content: center;
                .calmradio-channels-groups-item{
                    pointer-events: all;
                    display: flex;
                    &:hover{
                        transform: none;
                    }
                    &.is-active{
                        margin-right: 0;
                        min-width: 0;
                        .calmradio-channels-groups-item-label{
                            padding: 2rem 5rem;
                        }
                    }
                }
                .menu-arrow-wrapper{
                    right: 50%;
                    transform: rotate(180deg) translateX(-50%) translateY(66%);
                }
            }
        }
        .arrow-wrapper{
            background-color: rgba(0, 0, 0, 0.8);
        }
        .channels-guide-video-bg{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        .channels-guide-channels-item-wrapper{
            width: auto;
        }
        .channels-guide-channels-item{
            margin: 0;
            width: auto;
            height: auto;
        }
    }
    .next-section-wrapper{
        position: relative;
        padding: 5rem 0;
    }
    &.channels-guide-main{ // MAIN
        background-position: top center;
        background-repeat: no-repeat;
        background-size: 100% auto;
        .calmradio-channels-groups{
            margin: 0 !important;
            flex-direction: column;
            .calmradio-channels-groups-item{
                min-height: 0;
                flex-direction: row;
                justify-content: flex-start;
                width: auto;
                border-color: transparent !important;
                background-color: transparent !important;
                padding: 0;
                margin: 0;
                &:after{
                    display: none;
                }
                &:hover{
                    min-height: 0;
                    box-shadow: none;
                }
            }
            .calmradio-channels-groups-item-label{
                //text-shadow: none;
                text-transform: none;
                right: auto;
            }
        }
        .channels-guide{
            margin-bottom: 2rem;
        }
        .channels-guide-wrapper{
            &.channels-guide-channels{
                .channels-guide-channels-rows{
                    .channels-guide-channels-row{
                        @media screen and (max-width: 1279px) {
                            padding: 2rem 4rem;
                            &-title{
                                margin-bottom: 0;
                            }
                        }
                        @media screen and (max-width: 1279px) {
                            &-title{
                                a{
                                    display: flex;
                                    justify-content: space-between;
                                }
                            }
                        }
                        @media screen and (max-width: 1279px) {
                            &-title{
                                a{
                                    font-size: 2.5rem;
                                }
                            }
                        }
                    }
                }
                @media (max-width: 1279px) {
                    margin: 22rem auto 0;
                }
                .channels-guide-channels-slider{
                    padding: 1.8rem 0 1.2rem;
                }
            }
            .swiper-wrapper{
                min-height: 0;
                height: 312px;
                @media (max-width: 1279px) {
                    height: 190px;
                }
                @media (max-width: 768px) {
                    height: 170px;
                }
                .swiper-slide{
                    width: auto;
                }
                .channels-guide-channels-item-bg{
                    width: auto;
                    max-height: 100%;
                }
            }
            .swiper-controls{
                .swiper-button-prev,
                .swiper-button-next{
                    color: #fff;
                    &:before,
                    &:after{
                        font-weight: 700;
                    }
                }
            }
        }
    }
    &.channels-guide-super-category{ // SUPER CATEGORY
        background-repeat: no-repeat;
        background-size: 100% auto;
        background-position: top center;
        .calmradio-channels-groups{
            margin: 0 !important;
            flex-direction: column;
            .calmradio-channels-groups-item{
                min-height: 0;
                flex-direction: row;
                justify-content: flex-start;
                width: auto;
                border-color: transparent !important;
                background-color: transparent !important;
                padding: 0;
                margin: 0;
                &:after{
                    display: none;
                }
                &:hover{
                    min-height: 0;
                    box-shadow: none;
                }
            }
            .calmradio-channels-groups-item-label{
                //text-shadow: none;
                text-transform: none;
                right: auto;
            }
        }
        .channels-guide{
            @media screen and (max-width: 1279px) {
                margin-bottom: 1rem;
            }
        }
        .channels-guide-wrapper{
            &.channels-guide-channels{
                .channels-guide-channels-rows{
                    .channels-guide-channels-row{
                        &-title{
                            @media screen and (max-width: 1279px) {
                                text-align: center;
                                margin-bottom: 3rem;
                            }
                        }
                    }
                }
                .channels-guide-channels-slider{
                    padding: 1.8rem 0 1.2rem;
                }
            }
            .swiper-wrapper{
                min-height: 0;
                //height: 300px;
                .swiper-slide{
                    width: auto;
                }
                .channels-guide-channels-item-bg{
                    width: auto;
                }
            }
            .swiper-controls{
                .swiper-button-prev,
                .swiper-button-next{
                    color: #fff;
                    &:before,
                    &:after{
                        font-weight: 700;
                    }
                }
            }
        }
    }
}
.pricing-section-v3{
    h2{
        &.section-headline {
            font-size: 4rem;
            font-weight: 600;
            text-transform: uppercase;
            text-align: center;
            margin: 8rem 0;
        }
    }
    &.testimonials{
        padding-bottom: 6.5rem;
        blockquote{
            margin-top: 0;
            border-left: 0;
            line-height: 1.3;
            margin-top: 0;
            font-style: normal;
            font-family: inherit;
            padding: 4rem;
            .testimonial-rating{
                margin-top: 0;
            }
            p{
                font-size: inherit;
                margin-top: 0;
            }
        }
    }
    &.faq{
        .pricing-faq-v3{
            &__item{
               &-title{
                    font-size: 2.7rem;
                    margin-top: 0;
                    font-weight: 600;
                }
                p{
                    font-size: 1.8rem;
                    margin-top: 0;
                }
            }
        }
    }
}
.backdrop-banner{
    &.channel-guide{
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        height: 300px;
        &:after{
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: rgba(0, 0, 0, 0.35);
            z-index: 1;
        }
        video{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        .banner-headline{
            color: #fff !important;
            position: relative;
            margin: 0;
            z-index: 1;
            font-size: 5rem;
            text-align: center;
            z-index: 2;
        }
        @media (max-width: 1600px) {
            padding: 10rem 0;
            font-size: 3.5rem;
            .banner-headline{
                font-size: 4rem;
            }
        }
        .section-headline{
            color: #fff !important;
            position: relative;
            margin: 0;
            font-size: 5rem;
            text-align: center;
            z-index: 2;
        }
    }
}