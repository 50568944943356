// Player
body.is-frontpage {
    .calmradio-channels-groups {
        display: flex;
        flex-wrap: nowrap;
        justify-content: flex-start;
        margin: 0;

        &-wrapper {
            clear: both;
            height: 6.8rem;
            overflow: hidden;
            padding: 3.6rem 0 0 0;
            position: relative;

            &.is-hidden {
                display: none;
            }

            &-inner {
                background: $white;
                margin-top: -2rem;
                position: relative;
                z-index: 1;

                &:first-child {
                    margin-top: 0;
                    z-index: 0;
                }

                &:last-child {
                    display: none;
                }

                .calmradio-channels-groups-prev,
                .calmradio-channels-groups-next {
                    content: "";
                    cursor: pointer;
                    height: 100%;
                    opacity: 0;
                    pointer-events: none;
                    position: absolute;
                    top: 0;
                    transition: opacity .15s ease-out;
                    user-select: none;
                    width: 72px;

                    &:before {
                        background-image: url("data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeD0iMHB4IiB5PSIwcHgiDQoJIHZpZXdCb3g9IjAgMCAzMzAgMzMwIj4NCjxwYXRoIGQ9Ik0zMjUuNjA2LDIyOS4zOTNsLTE1MC4wMDQtMTUwQzE3Mi43OSw3Ni41OCwxNjguOTc0LDc1LDE2NC45OTYsNzVjLTMuOTc5LDAtNy43OTQsMS41ODEtMTAuNjA3LDQuMzk0DQoJbC0xNDkuOTk2LDE1MGMtNS44NTgsNS44NTgtNS44NTgsMTUuMzU1LDAsMjEuMjEzYzUuODU3LDUuODU3LDE1LjM1NSw1Ljg1OCwyMS4yMTMsMGwxMzkuMzktMTM5LjM5M2wxMzkuMzk3LDEzOS4zOTMNCglDMzA3LjMyMiwyNTMuNTM2LDMxMS4xNjEsMjU1LDMxNSwyNTVjMy44MzksMCw3LjY3OC0xLjQ2NCwxMC42MDctNC4zOTRDMzMxLjQ2NCwyNDQuNzQ4LDMzMS40NjQsMjM1LjI1MSwzMjUuNjA2LDIyOS4zOTN6Ii8+DQo8L3N2Zz4NCg==");
                        content: "";
                        height: 12px;
                        pointer-events: none;
                        position: absolute;
                        top: 10px;
                        width: 12px;
                    }
                }

                .calmradio-channels-groups-prev {
                    background: linear-gradient(270deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 60%);
                    left: -2px;

                    &:before {
                        left: 0;
                        transform: rotate(-90deg);
                    }
                }

                .calmradio-channels-groups-next {
                    background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 60%);
                    right: -2px;

                    &:before {
                        right: 0;
                        transform: rotate(90deg);
                    }
                }

                &.has-overflow-left .calmradio-channels-groups-prev,
                &.has-overflow-right .calmradio-channels-groups-next {
                    opacity: 1;
                    pointer-events: auto;
                }

                &-scrollable {
                    padding-bottom: 30px;
                    overflow: auto;
                    overscroll-behavior-x: none;
                    scroll-behavior: smooth;
                }
            }
        }

        .calmradio-channels-groups-item {
            align-items: center;
            background-color: $white;
            border: 1px solid $border-medium;
            border-radius: 1.5rem;
            display: flex;
            height: 3.2rem;
            margin: 0 .5rem 1rem .5rem;
            padding: 0 2rem;
            text-transform: lowercase;
            transition: background-color .2s ease-out;

            &-label-start {
                font-size: 1.6rem;
                font-weight: 600;
                height: 3.2rem;
                line-height: 3.1rem;
                padding-right: 1rem;
            }

            &.is-loading {
                animation: loadingItem;
                animation-iteration-count: infinite;
                animation-duration: 2s;
                animation-timing-function: linear;
                background-color: $color-bg-light;
                border-color: $white;
                box-shadow: 0 5px 12px rgba(0, 0, 0, 0);
                width: 150px;

                &:nth-child(6n) {
                    animation-delay: .2s;
                }

                &:nth-child(6n + 1) {
                    animation-delay: .4s;
                }

                &:nth-child(6n + 2) {
                    animation-delay: .6s;
                }

                &:nth-child(6n + 3) {
                    animation-delay: .8s;
                }

                &:nth-child(6n + 4) {
                    animation-delay: 1s;
                }

                &:nth-child(6n + 5) {
                    animation-delay: 1.2s;
                }
            }

            &:active,
            &:focus,
            &:hover {
                cursor: pointer;

                & > strong {
                    color: $blue;
                }
            }

            &.is-active {
                background-color: $blue!important;
                border-color: $blue!important;

                & > strong {
                    color: $white!important;
                }
            }

            & > strong {
                font-size: 1.4rem;
                font-weight: 400;
                padding: 0;
                pointer-events: none;
                text-align: center;
                white-space: nowrap;

                &::first-letter {
                    text-transform: uppercase;
                }
            }
        }
    }
}

// Channels guide
.channels-guide {
    .calmradio-channels-groups {
        .calmradio-channels-groups-item {
            background-color: $dark!important;
            background-position: center center!important;
            background-size: cover!important;
            background-repeat: no-repeat!important;
            margin: 0 .5rem 1rem .5rem;
            overflow: hidden;

            &.is-active {
                border-color: $blue!important;
            }

            &-label {
                color: $white;
                font-size: 22px;
                font-weight: 500;
                left: 20px;
                padding: 0;
                position: absolute;
                right: 20px;
                text-align: left;
                text-shadow: 0 1px 1px rgba(0, 0, 0, .5);
                text-transform: lowercase;
                top: 12px;
                width: calc(100% - 40px);
                z-index: 2;
                
                &::first-letter {
                    text-transform: uppercase;
                }
            }

            &:after {
                background: linear-gradient(112deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 55%);
                content: "";
                height: 100%;
                left: 0;
                position: absolute;
                top: 0;
                width: 100%;
                z-index: 1;
            }
        }
    }
}

@media (max-width: 2300px) {
    .channels-guide .calmradio-channels-groups .calmradio-channels-groups-item-label {
        font-size: 2rem;
    }
}

@media (max-width: 2100px) {
    .channels-guide .calmradio-channels-groups .calmradio-channels-groups-item-label {
        font-size: 1.6rem;
    }
}

@media (max-width: 1600px) {
    .channels-guide .calmradio-channels-groups .calmradio-channels-groups-item-label {
        left: 10px;
        right: 10px;
        width: calc(100% - 20px);
    }
}

@media (max-width: 1040px) {
    .channels-guide .calmradio-channels-groups .calmradio-channels-groups-item-label {
        font-size: 1.8rem;
    }
}

@media (min-width: 1441px) {
    .channels-guide .calmradio-channels-groups {
        &[data-count="6"],
        &[data-count="12"] {
            .calmradio-channels-groups-item {
                width: calc((100% - 6rem) / 6); 
            }
        }

        &[data-count="7"],
        &[data-count="14"],
        &[data-count="21"] {
            .calmradio-channels-groups-item { 
                width: calc((100% - 7rem) / 7); 
            }
        }

        &[data-count="8"],
        &[data-count="16"],
        &[data-count="24"] {
            .calmradio-channels-groups-item { 
                width: calc((100% - 8rem) / 8); 
            }
        }

        &[data-count="9"] {
            .calmradio-channels-groups-item { 
                width: calc((100% - 9rem) / 5); 

                &:nth-child(6),
                &:nth-child(7),
                &:nth-child(8),
                &:nth-child(9) {
                    width: calc((100% - 4rem) / 4); 
                }
            }
        }

        &[data-count="10"] {
            .calmradio-channels-groups-item { 
                width: calc((100% - 5rem) / 5); 
            }
        }

        &[data-count="11"] {
            .calmradio-channels-groups-item { 
                width: calc((100% - 6rem) / 6); 

                &:nth-child(7),
                &:nth-child(8),
                &:nth-child(9),
                &:nth-child(10),
                &:nth-child(11) {
                    width: calc((100% - 5rem) / 5); 
                }
            }
        }

        &[data-count="13"] {
            .calmradio-channels-groups-item { 
                width: calc((100% - 7rem) / 7);

                &:nth-child(8),
                &:nth-child(9),
                &:nth-child(10),
                &:nth-child(11),
                &:nth-child(12),
                &:nth-child(13) {
                    width: calc((100% - 6rem) / 6); 
                }
            }
        }

        &[data-count="15"] {
            .calmradio-channels-groups-item { 
                width: calc((100% - 8rem) / 8); 

                &:nth-child(9),
                &:nth-child(10),
                &:nth-child(11),
                &:nth-child(12),
                &:nth-child(13),
                &:nth-child(14),
                &:nth-child(15) {
                    width: calc((100% - 7rem) / 7); 
                }
            }
        }

        &[data-count="17"] {
            .calmradio-channels-groups-item { 
                width: calc((100% - 9rem) / 9); 

                &:nth-child(10),
                &:nth-child(11),
                &:nth-child(12),
                &:nth-child(13),
                &:nth-child(14),
                &:nth-child(15),
                &:nth-child(16),
                &:nth-child(17) {
                    width: calc((100% - 8rem) / 8); 
                }
            }
        }

        &[data-count="18"] {
            .calmradio-channels-groups-item { 
                width: calc((100% - 9rem) / 9); 
            }
        }

        &[data-count="19"] {
            .calmradio-channels-groups-item { 
                width: calc((100% - 9rem) / 9); 

                &:nth-child(10),
                &:nth-child(11),
                &:nth-child(12),
                &:nth-child(13),
                &:nth-child(14),
                &:nth-child(15),
                &:nth-child(16),
                &:nth-child(17),
                &:nth-child(18),
                &:nth-child(19) {
                    width: calc((100% - 10rem) / 10); 
                }
            }
        }

        &[data-count="20"] {
            .calmradio-channels-groups-item { 
                width: calc((100% - 7rem) / 7);

                &:nth-child(15),
                &:nth-child(16),
                &:nth-child(17),
                &:nth-child(18),
                &:nth-child(19),
                &:nth-child(20) {
                    width: calc((100% - 6rem) / 6); 
                }
            }
        }

        &[data-count="22"] {
            .calmradio-channels-groups-item { 
                width: calc((100% - 8rem) / 8); 

                &:nth-child(17),
                &:nth-child(18),
                &:nth-child(19),
                &:nth-child(20),
                &:nth-child(21),
                &:nth-child(22) {
                    width: calc((100% - 6rem) / 6); 
                }
            }
        }

        &[data-count="23"] {
            .calmradio-channels-groups-item { 
                width: calc((100% - 8rem) / 8); 

                &:nth-child(17),
                &:nth-child(18),
                &:nth-child(19),
                &:nth-child(20),
                &:nth-child(21),
                &:nth-child(22),
                &:nth-child(23) {
                    width: calc((100% - 7rem) / 7); 
                }
            }
        }
    }
}

@media (min-width: 1025px) and (max-width: 1440px) {
    .channels-guide .calmradio-channels-groups {
        &[data-count="6"],
        &[data-count="12"],
        &[data-count="18"] {
            .calmradio-channels-groups-item {
                width: calc((100% - 6rem) / 6); 
            }
        }

        &[data-count="7"],
        &[data-count="14"],
        &[data-count="21"] {
            .calmradio-channels-groups-item { 
                width: calc((100% - 7rem) / 7); 
            }
        }

        &[data-count="8"],
        &[data-count="16"],
        &[data-count="24"] {
            .calmradio-channels-groups-item { 
                width: calc((100% - 8rem) / 8); 
            }
        }

        &[data-count="9"] {
            .calmradio-channels-groups-item { 
                width: calc((100% - 9rem) / 5); 

                &:nth-child(6),
                &:nth-child(7),
                &:nth-child(8),
                &:nth-child(9) {
                    width: calc((100% - 4rem) / 4); 
                }
            }
        }

        &[data-count="10"] {
            .calmradio-channels-groups-item { 
                width: calc((100% - 5rem) / 5); 
            }
        }

        &[data-count="11"] {
            .calmradio-channels-groups-item { 
                width: calc((100% - 6rem) / 6); 

                &:nth-child(7),
                &:nth-child(8),
                &:nth-child(9),
                &:nth-child(10),
                &:nth-child(11) {
                    width: calc((100% - 5rem) / 5); 
                }
            }
        }

        &[data-count="13"] {
            .calmradio-channels-groups-item { 
                width: calc((100% - 7rem) / 7);

                &:nth-child(8),
                &:nth-child(9),
                &:nth-child(10),
                &:nth-child(11),
                &:nth-child(12),
                &:nth-child(13) {
                    width: calc((100% - 6rem) / 6); 
                }
            }
        }

        &[data-count="15"] {
            .calmradio-channels-groups-item { 
                width: calc((100% - 8rem) / 8); 

                &:nth-child(9),
                &:nth-child(10),
                &:nth-child(11),
                &:nth-child(12),
                &:nth-child(13),
                &:nth-child(14),
                &:nth-child(15) {
                    width: calc((100% - 7rem) / 7); 
                }
            }
        }

        &[data-count="17"] {
            .calmradio-channels-groups-item { 
                width: calc((100% - 9rem) / 9); 

                &:nth-child(10),
                &:nth-child(11),
                &:nth-child(12),
                &:nth-child(13),
                &:nth-child(14),
                &:nth-child(15),
                &:nth-child(16),
                &:nth-child(17) {
                    width: calc((100% - 8rem) / 8); 
                }
            }
        }

        &[data-count="19"] {
            .calmradio-channels-groups-item { 
                width: calc((100% - 7rem) / 7); 

                &:nth-child(15),
                &:nth-child(16),
                &:nth-child(17),
                &:nth-child(18),
                &:nth-child(19) {
                    width: calc((100% - 5rem) / 5); 
                }
            }
        }

        &[data-count="20"] {
            .calmradio-channels-groups-item { 
                width: calc((100% - 7rem) / 7);

                &:nth-child(15),
                &:nth-child(16),
                &:nth-child(17),
                &:nth-child(18),
                &:nth-child(19),
                &:nth-child(20) {
                    width: calc((100% - 6rem) / 6); 
                }
            }
        }

        &[data-count="22"] {
            .calmradio-channels-groups-item { 
                width: calc((100% - 8rem) / 8); 

                &:nth-child(17),
                &:nth-child(18),
                &:nth-child(19),
                &:nth-child(20),
                &:nth-child(21),
                &:nth-child(22) {
                    width: calc((100% - 6rem) / 6); 
                }
            }
        }

        &[data-count="23"] {
            .calmradio-channels-groups-item { 
                width: calc((100% - 8rem) / 8); 

                &:nth-child(17),
                &:nth-child(18),
                &:nth-child(19),
                &:nth-child(20),
                &:nth-child(21),
                &:nth-child(22),
                &:nth-child(23) {
                    width: calc((100% - 7rem) / 7); 
                }
            }
        }
    }
}

@media (min-width: 861px) and (max-width: 1024px) {
    .channels-guide .calmradio-channels-groups {
        &-item {
            min-height: 160px;
        }

        &[data-count="6"],
        &[data-count="9"],
        &[data-count="12"],
        &[data-count="15"],
        &[data-count="18"],
        &[data-count="21"] {
            .calmradio-channels-groups-item {
                width: calc((100% - 3rem) / 3);
            }
        }

        &[data-count="8"],
        &[data-count="12"],
        &[data-count="16"],
        &[data-count="24"] {
            .calmradio-channels-groups-item {
                width: calc((100% - 4rem) / 4);
            }
        }

        &[data-count="10"],
        &[data-count="20"] {
            .calmradio-channels-groups-item {
                width: calc((100% - 5rem) / 5);
            }
        }

        &[data-count="7"] {
            .calmradio-channels-groups-item {
                &:nth-child(1),
                &:nth-child(2),
                &:nth-child(3),
                &:nth-child(4) {
                    width: calc((100% - 4rem) / 4);
                }

                &:nth-child(5),
                &:nth-child(6),
                &:nth-child(7) {
                    width: calc((100% - 3rem) / 3);
                }
            }
        }

        &[data-count="11"] {
            .calmradio-channels-groups-item {
                &:nth-child(1),
                &:nth-child(2),
                &:nth-child(3),
                &:nth-child(4),
                &:nth-child(5),
                &:nth-child(6),
                &:nth-child(7),
                &:nth-child(8) {
                    width: calc((100% - 4rem) / 4);
                }

                &:nth-child(9),
                &:nth-child(10),
                &:nth-child(11) {
                    width: calc((100% - 3rem) / 3);
                }
            }
        }

        &[data-count="13"] {
            .calmradio-channels-groups-item {
                &:nth-child(1),
                &:nth-child(2),
                &:nth-child(3),
                &:nth-child(4),
                &:nth-child(5),
                &:nth-child(6),
                &:nth-child(7),
                &:nth-child(8),
                &:nth-child(9),
                &:nth-child(10) {
                    width: calc((100% - 5rem) / 5);
                }

                &:nth-child(11),
                &:nth-child(12),
                &:nth-child(13) {
                    width: calc((100% - 3rem) / 3);
                }
            }
        }

        &[data-count="14"] {
            .calmradio-channels-groups-item {
                &:nth-child(1),
                &:nth-child(2),
                &:nth-child(3),
                &:nth-child(4),
                &:nth-child(5),
                &:nth-child(6),
                &:nth-child(7),
                &:nth-child(8) {
                    width: calc((100% - 4rem) / 4);
                }

                &:nth-child(9),
                &:nth-child(10),
                &:nth-child(11),
                &:nth-child(12),
                &:nth-child(13),
                &:nth-child(14) {
                    width: calc((100% - 3rem) / 3);
                }
            }
        }

        &[data-count="17"] {
            .calmradio-channels-groups-item {
                width: calc((100% - 4rem) / 4);
                
                &:nth-child(1),
                &:nth-child(2),
                &:nth-child(3),
                &:nth-child(4),
                &:nth-child(5) {
                    width: calc((100% - 5rem) / 5);
                }
            }
        }

        &[data-count="19"] {
            .calmradio-channels-groups-item {
                width: calc((100% - 5rem) / 5);

                &:nth-child(16),
                &:nth-child(17),
                &:nth-child(18),
                &:nth-child(19) {
                    width: calc((100% - 4rem) / 4);
                }
            }
        }

        &[data-count="22"] {
            .calmradio-channels-groups-item {
                width: calc((100% - 4rem) / 4);

                &:nth-child(17),
                &:nth-child(18),
                &:nth-child(19),
                &:nth-child(20),
                &:nth-child(21),
                &:nth-child(22) {
                    width: calc((100% - 3rem) / 3);
                }
            }
        }

        &[data-count="23"] {
            .calmradio-channels-groups-item {
                width: calc((100% - 4rem) / 4);

                &:nth-child(21),
                &:nth-child(22),
                &:nth-child(23) {
                    width: calc((100% - 3rem) / 3);
                }
            }
        }
    }
}

@media (max-width: 860px) {
    .channels-guide .calmradio-channels-groups {
        &-item {
            min-height: 140px;
        }

        &[data-count="6"],
        &[data-count="8"],
        &[data-count="10"],
        &[data-count="12"],
        &[data-count="14"],
        &[data-count="16"],
        &[data-count="18"],
        &[data-count="20"],
        &[data-count="22"],
        &[data-count="24"] {
            .calmradio-channels-groups-item {
                width: calc(50% - 1rem);
            }
        }

        &[data-count="7"],
        &[data-count="9"],
        &[data-count="11"],
        &[data-count="13"],
        &[data-count="15"],
        &[data-count="17"],
        &[data-count="19"],
        &[data-count="21"],
        &[data-count="23"] {
            .calmradio-channels-groups-item {
                width: calc(50% - 1rem);

                &:last-child {
                    width: calc(100% - 1rem);
                }
            }
        }
    }

    body.is-frontpage {
        .calmradio-channels-groups {
            &-wrapper {
                height: 11.6rem;
                padding: 3.2rem 0 0 0;

                &-inner {
                    &:first-child {
                        .calmradio-channels-groups-item:nth-child(2n) {
                            display: none;
                        }
                    }

                    &:last-child {
                        display: block;

                        .calmradio-channels-groups-item:nth-child(2n+1) {
                            display: none;
                        }
                    }
                }

                .calmradio-channels-groups-item-label-start {
                    display: none;
                }
            }
        }
    }
}