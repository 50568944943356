.channel-chapters {
    margin-top: -1rem;
    margin-right: 9.2rem;

    .channel-chapters-title {
        color: $dark;
        font-size: 1.8rem;
        font-weight: 600;
        padding: 0.5rem 0;
        text-transform: uppercase;
        user-select: none;
    }

    .channel-chapters-ui {
        user-select: none;
    }

    &.no-animation {
        .channel-chapters-list {
            transition: none;
        }
    }

    .channel-chapters-list {
        display: grid;
        grid-auto-flow: column;
        grid-column-gap: 0;
        grid-row-gap: 3px;
        grid-template-rows: repeat(6, 1fr);
        margin: 10px 0 20px 0;
        max-height: 180px;
        transition: all .35s ease-out;
        width: 100%;

        &-wrapper {
            overflow: hidden;
            width: 100%;

            &[data-columns="1"],
            &[data-columns="2"],
            &[data-columns="3"],
            &[data-columns="4"] {
                .channel-chapters-list {
                    grid-template-columns: repeat(4, 25%);
                    width: 100%;
                }
            }

            @for $i from 5 through 30 {
                &[data-columns="#{$i}"] {
                    .channel-chapters-list {
                        grid-template-columns: repeat($i, calc(100% / #{$i}));
                        width: 25% * $i;
                    }
                }
            }

            @for $i from 0 through 29 {
                &[data-current-page="#{$i}"] {
                    .channel-chapters-list {
                        margin-left: -25% * $i;
                    }
                }
            }
        }

        .channel-chapters-item {
            font-weight: 400;
            padding-right: 1rem;
            position: relative;
            width: 100%;

            .channel-chapters-item-link {
                color: $dark;
                display: block;
                font-size: 14px;
                font-weight: 400;
                overflow: hidden;
                padding: 0 0 0 2.8rem;
                position: relative;
                text-overflow: ellipsis;
                white-space: nowrap;

                &:before {
                    background: $white;
                    border: 1px solid $border;
                    border-radius: 7px;
                    content: "";
                    height: 14px;
                    left: 0;
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    width: 14px;
                }

                &:hover {
                    color: $blue;
                    text-decoration: none;
                }

                .channel-chapters-item-link-chapter {
                    color: $dark!important;
                    font-weight: 400!important;
                    opacity: .66;
                    padding-right: .5rem;
                    pointer-events: none;
                }
            }

            &.is-current {
                .channel-chapters-item-link { 
                    font-weight: 600;

                    &:before {
                        border-color: $blue!important;
                    }
    
                    &:after {
                        background: $blue!important;
                        border-radius: 3px;
                        content: "";
                        height: 6px;
                        left: 4px;
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        width: 6px;
                    }
                }   
            }

            &:hover {
                .channel-chapters-item-link {
                    color: $blue;

                    &:after {
                        background: $border;
                        border-radius: 3px;
                        content: "";
                        height: 6px;
                        left: 4px;
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        width: 6px;
                    }
                }
            }
        }
    }

    &-prev,
    &-next {
        border: 1px solid $border-medium;
        border-radius: 50%;
        display: inline-block;
        height: 30px;
        margin: 0 0 0 10px;
        overflow: hidden;
        position: relative;
        text-indent: -500px;
        top: 8px;
        width: 30px;

        &:before {
            border-right: 1px solid $gray;
            border-top: 1px solid $gray;
            content: "";
            height: 6px;
            left: calc(50% - 1px);
            position: absolute;
            top: 50%;
            transform: translateX(-50%) translateY(-50%) rotate(45deg);
            width: 6px;
        }

        &:hover {
            border-color: $blue;

            &:before {
                border-color: $blue;
            }
        }

        &.is-inactive {
            opacity: .25;
            pointer-events: none;
        }

        &.is-hidden {
            display: none;
        }
    }

    &-prev {
        margin-left: 20px;

        &:before {
            left: calc(50% + 1px);
            transform: translateX(-50%) translateY(-50%) rotate(-135deg);
        }
    }
}

@media (max-width: 1600px) {
    .channel-chapters {
        margin-right: 5.2rem;
    }
}

@media (max-width: 1440px) {
    .channel-chapters { 
        .channel-chapters-list {
            &-wrapper {    
                &[data-columns="1"],
                &[data-columns="2"],
                &[data-columns="3"] {
                    .channel-chapters-list {
                        grid-template-columns: repeat(3, calc(100% / 3));
                        width: 100%;
                    }
                }
    
                @for $i from 4 through 30 {
                    &[data-columns="#{$i}"] {
                        .channel-chapters-list {
                            grid-template-columns: repeat($i, calc(100% / #{$i}));
                            width: 33.333333% * $i;
                        }
                    }
                }
    
                @for $i from 0 through 29 {
                    &[data-current-page="#{$i}"] {
                        .channel-chapters-list {
                            margin-left: -33.333333% * $i;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 1040px) {
    .channel-chapters { 
        .channel-chapters-list {
            grid-template-columns: repeat(2, 1fr);

            &-wrapper {    
                &[data-columns="1"],
                &[data-columns="2"] {
                    .channel-chapters-list {
                        grid-template-columns: repeat(2, 50%);
                        width: 100%;
                    }
                }
    
                @for $i from 3 through 30 {
                    &[data-columns="#{$i}"] {
                        .channel-chapters-list {
                            grid-template-columns: repeat($i, calc(100% / #{$i}));
                            width: 50% * $i;
                        }
                    }
                }
    
                @for $i from 0 through 29 {
                    &[data-current-page="#{$i}"] {
                        .channel-chapters-list {
                            margin-left: -50% * $i;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 720px) {
    .channel-chapters { 
        overflow: auto;

        .channel-chapters-list {
            grid-template-columns: repeat(1, 1fr);

            &-wrapper {    
                &[data-columns="1"]{
                    .channel-chapters-list {
                        grid-template-columns: repeat(1, 100%);
                        width: 100%;
                    }
                }
    
                @for $i from 2 through 30 {
                    &[data-columns="#{$i}"] {
                        .channel-chapters-list {
                            grid-template-columns: repeat($i, calc(100% / #{$i}));
                            width: 100% * $i;
                        }
                    }
                }
    
                @for $i from 0 through 29 {
                    &[data-current-page="#{$i}"] {
                        .channel-chapters-list {
                            margin-left: -100% * $i;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 640px) {
    .channel-chapters {
        margin-right: 3.2rem;
    }
}