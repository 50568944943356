.rangeslider,
.rangeslider__fill {
  display: inline-block;
  box-shadow: inset 0px 1px 3px rgba(0, 0, 0, 0.3);
  border-radius: 3px;
}

.rangeslider {
  background: #e6e6e6;
  position: relative;
  top: -2px;
}

.rangeslider--horizontal {
  height: 6px;
  width: 300px;
}

.rangeslider__fill {
  background: $blue;
  position: absolute;
}
.rangeslider--horizontal .rangeslider__fill {
  top: 0;
  height: 100%;
}

.rangeslider__handle {
  background: white;
  border: 1px solid #ccc;
  cursor: pointer;
  display: inline-block;
  width: 18px;
  height: 18px;
  position: absolute;
  background-color: $white;
  background-size: 100%;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
  border-radius: 50%;
}

.rangeslider__handle:active, .rangeslider--active .rangeslider__handle {
  background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIgc3RvcC1vcGFjaXR5PSIwLjEiLz48c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiMwMDAwMDAiIHN0b3Atb3BhY2l0eT0iMC4xMiIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
  background-size: 100%;
  background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.12));
}
.rangeslider--horizontal .rangeslider__handle {
  top: -6px;
  touch-action: pan-y;
}

input[type="range"]:focus + .rangeslider .rangeslider__handle {
  box-shadow: 0 0 3px rgba(255, 0, 255, 0.9);
}