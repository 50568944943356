.myaccount-page {
    .moscribe-myprofile {
        margin: 10rem auto;
        width: 1400px;

        #central-content {
            background: transparent;
            margin: 0 -20px;
            padding: 0;

            button {
                border: none;
            }

            .my-invoices-block {
                .detail-list-header {
                    background-color: transparent;
                    border-bottom: 1px solid $border-medium;
                    color: $color-text;

                    li {
                        font-weight: bold;
                        text-align: center;
                    }
                }

                .detail-list {
                     background-color: transparent;
                     border-bottom: 1px solid $border-medium;

                     li {
                         font-weight: normal;
                         text-align: center;
                     }
                }
            }
        }

        .my-profile-block {
            padding: 20px;

            & > div {
                background: $white;
                border: none;
                border-radius: $border-radius-small;
                box-shadow: 0 -0.5rem 4rem rgba(0, 0, 0, .065);
                padding: 4rem;
            }

            &.my-subscriptions-block {
                & > div {
                    padding: 4rem!important;
                }
            }

            h4,
            h5 {
                border-bottom: none;
                color: $color-text;
                font-size: 2.4rem;
                font-weight: 400;
                margin: 0 0 2rem 0;
                padding: 0;
                text-transform: none;
            }

            h5 {
                margin-left: 32px;
            }

            &.password-manager-block {
                & > div {
                    button {
                        margin-top: 3rem;
                    }
                }
            }

            &.internet-radio-urls-block {
                clear: both;
            }
        }

        .my-subscriptions-block {
            li {
                border-top: 1px solid $border-light;
                text-align: right;

                &:first-child {
                    border-top: none;
                }

                strong {
                    float: left;
                }
            }
        }

        .my-purchase-block {
            display: none;
        }

        .card-expiry-update,
        .edit-credit-cards-block {
            .btn-small {
                background: $blue;
                border: none;
                border-radius: 32px;
                color: $white;
                cursor: pointer;
                display: inline-block;
                margin: .75rem 0;
                padding: 0 2rem;
                vertical-align: middle;
                text-align: center;
                text-decoration: none;
                transition: all 0.24s ease;
                will-change: transform;

                &:hover {
                    color: $white;
                    text-decoration: none;
                    transform: translateY(-2px);
                }
            }

            input,
            select,
            .select-wrapper {
                background: $white;
                border-left: none!important;
                border-right: none!important;
                border-top: none!important;
            }

            select {
                border-bottom: 1px solid $border-medium;
                border-radius: 0;
            }

            .select-wrapper {
                border-bottom: none!important;

                .form-error {
                    color: $color-danger;
                    display: inline-block;
                    font-size: 1rem;
                    line-height: 1.4;
                    margin-top: 1rem;
                }
            }

            .empty-value-message {
                display: inline-block;
                margin-top: 5px;
                padding-left: 0!important;
            }
        }

        .internet-radio-urls-block {
            .download-btn {
                background: $blue!important;
                border: none!important;
                border-radius: 32px!important;
                color: $white;
                cursor: pointer;
                display: inline-block;
                margin: .75rem 0!important;
                padding: .35rem 2rem!important;
                vertical-align: middle;
                text-align: center;
                text-decoration: none;
                transition: all 0.24s ease;
                will-change: transform;

                &:hover {
                    color: $white;
                    text-decoration: none;
                    transform: translateY(-2px);
                }
            }
        }

        .password-manager-block {
            .action-error {
                background: transparent!important;
                color: $color-danger;
            }

            .action-success {
                background: transparent!important;
                color: $green;
            }

            .loader {
                background: rgba(255,255,255,.75);

                .ripple {
                    animation: giftcards-loader 0.8s ease-in-out infinite;
                    background: none;
                    border-radius: 50%;
                    border: 3px solid rgba(0, 0, 0, .125);
                    border-top-color: $gray-medium;
                    content: '';
                    height: 64px;
                    margin: 0;
                    transform: translateX(-50%) translateY(-50%) rotate(0deg);
                    width: 64px;
                }
            }
        }

        .account-overview-block {
            padding-bottom: 2rem;

            button {
                float: left;
                margin: 3rem 0 0 0;
                width: 50%;
            }

            & > div {
                min-height: auto;
                padding-bottom: 14rem;

                .profile-info {
                    .user-info {
                        border: none;
                        margin: 0;
                        padding: 0;

                        small {
                            font-style: normal;
                            font-weight: 300;
                        }
                    }

                    & + ul {
                        margin-left: 145px!important;
                        margin-top: -75px!important;

                        small {
                            font-style: normal;
                            font-weight: 300;
                        }

                        li {
                            clear: both;
                        }
                    }
                }
            }

            .my-profile-block {
                border: none;
                float: right;
                margin-top: 5rem;
                padding: 0;
            }
        }

        .account-details-block {
            .account-heading {
                small {
                    margin-left: 30px;
                    margin-right: 30px;
                }
            }

            .fa-twitter,
            .fa-facebook {
                margin-left: 26px!important;
                opacity: .5;
                pointer-events: none;

                &.connected {
                    opacity: 1;
                }
            }
        }

        .my-profile-block {
            .fa-twitter,
            .fa-facebook {
                margin-left: 6px!important;
                opacity: .5;
                pointer-events: none;

                &.connected {
                    opacity: 1;
                }
            }
        }

        .my-subscriptions-block {
            & > div {
                min-height: auto!important;
            }

            ul {
                min-height: auto!important;
            }
        }

        #central-content {
            .password-manager-block {
                & > div {
                    min-height: auto;
                }

                p {
                    padding-left: 0;
                    padding-right: 0;
                }
            }
        }

        .input-box:not(.input-inline) {
            input[type=text],
            input[type=password],
            input[type=email] {
                padding-left: 3rem!important;
            }

            &:after {
                left: 2px;
                top: 8px;
            }
        }

        .internet-radio-urls-block {
            .url-header {
                background: transparent;
                border: none;
                border-bottom: 1px solid $border-medium;
                padding-left: 0;
                padding-right: 0;

                .url-row-content {
                    margin-left: 0;
                    text-align: left;
                }
            }

            .url-row {
                border: none;
                border-bottom: 1px solid $border-light;
                padding-left: 0;
                padding-right: 0;
            }
        }

        .my-subscriptions-block {
            .detail-list-item {
                background: none;
                border: none;
                margin-bottom: 2rem;
                padding: 0;

                .list-item-header {
                    border-bottom: 1px solid $border-medium;
                }

                li {
                    text-align: left;
                }

                .item-label {
                    color: $color-text;
                    padding-right: 1rem;
                    width: auto;
                }

                .recuring-button {
                    background: $blue;
                }
            }
        }

        .profile-photo-block {
            .upload-form-wrapper {
                background: $color-bg-light!important;
            }
        }

        .my-profile-block.account-heading {
            margin-bottom: 4rem;
            padding: 4rem 7rem;

            i {
                top: 32px;
            }

            button {
                background: $blue;
                right: 40px;
                top: 36px;

                &:active,
                &:focus,
                &:hover {
                    transform: translateY(-2px);
                }
            }
        }

        .social-networks-block {
            .my-profile-block.account-heading {
                border: none;
                border-bottom: 1px solid $border-light;
                margin: 0;
                padding: 2rem 0 0 4rem;

                h4 {
                    margin-left: 0;
                }

                i {
                    opacity: 1;
                    left: -6px;
                    top: 14px;
                }

                h5 {
                    margin: 0 0 3rem 2rem!important;
                }

                button {
                    right: 0;
                    top: 17px;
                }

                small {
                    margin-left: 2rem;
                }

                &:last-child {
                    border-bottom: none;
                }
            }
        }

        .account-details-block {
            .account-details-header {
                border-radius: $border-radius-small;
            }

            form {
                input,
                select,
                .select-wrapper {
                    border-left: none!important;
                    border-right: none!important;
                    border-top: none!important;
                }

                .select-wrapper {
                    background: none;
                    border-bottom: none!important;
                }

                select {
                    background: none;
                    border-bottom: 1px solid $border-medium;
                    border-radius: 0;
                }
            }
        }
    }
}

@media (max-width: 1440px) {
    .myaccount-page .moscribe-myprofile {
        margin: 6rem auto;
        max-width: 100%;
        padding: 0 2rem;
        width: 100%;
    }
}

@media (max-width: 1200px) {
    .myaccount-page .moscribe-myprofile .my-profile-block h5 {
        margin-left: 0;
    }
}

@media (max-width: 768px) {
    .moscribe-myprofile #central-content .user-info-wrapper {
        display: none!important;
    }

    body .myaccount-page .moscribe-myprofile .account-overview-block>div .profile-info+ul {
        margin-left: 0!important;
        margin-top: 0!important;
    }
}

@media (max-width: 560px) {
    .myaccount-page .moscribe-myprofile .account-overview-block>div {
        padding-bottom: 20rem;
    }
}

@media (max-width: 480px) {
    .myaccount-page .moscribe-myprofile .my-profile-block h4,
    .myaccount-page .moscribe-myprofile .my-profile-block h5 {
        font-size: 2rem;
    }

    .myaccount-page .moscribe-myprofile .account-overview-block button {
        width: 100%;
    }

    .myaccount-page .moscribe-myprofile .account-overview-block .my-profile-block {
        margin-top: 1rem;
        width: 100%;
    }

    .moscribe-myprofile .account-details-block>div .inline-form {
        margin: 30px 0 20px;
    }
}
