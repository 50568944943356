.app-block {
    background: $blue;
    color: $white;

    .wrapper {
        align-items: center;
        display: flex;
        padding: 20rem 0;
    }

    &-content {
        padding-right: 12rem;
        width: 50%;
    }

    &-title {
        color: $white;
        font-size: 4rem;
        font-weight: 300;
        line-height: 1.2;
        margin-bottom: 4.8rem;
    }

    &-desc {
        font-size: 2rem;
        font-weight: 300;
        line-height: 1.5;
        margin-bottom: 8rem;
    }

    &-links {
        a {
            display: inline-block;
            margin-right: 2rem;

            img {
                display: block;
                height: 4.2rem;
                width: auto;
            }
        }
    }

    &-visual {
        width: 50%;

        &-os-list {
            margin: 4rem 0 0 0;
            text-align: center;

            li {
                display: inline-block;
                padding: 0 1.2rem;
            }
        }
    }

    &-img {
        fill: $white;
        height: auto;
        margin-top: 2rem;
        width: 100%;
    }
}

@media (max-width: 1440px) {
    .app-block .wrapper {
        padding: 15rem 2rem;
    }

    .app-block-visual-os-list li {
        font-size: 1.2rem;
    }
}

@media (max-width: 1280px) {
    .app-block .wrapper {
        flex-wrap: wrap;
        padding: 10rem 2rem;
    }

    .app-block-content {
        padding-right: 2rem;
    }
}

@media (max-width: 1040px) {
    .app-block .wrapper {
        padding: 6rem 2rem;
    }

    .app-block-content {
        padding-right: 0;
        width: 100%;
    }

    .app-block-links {
        text-align: center;
    }

    .app-block-visual {
        margin-top: 5rem;
        width: 100%;
    }
}

@media (max-width: 640px) {
    .app-block-title {
        font-size: 3rem;
    }

    .app-block-desc {
        font-size: 1.6rem;
        margin-bottom: 4rem;
    }

    .app-block-links a {
        margin: 1.5rem;
    }
}
