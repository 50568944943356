#k2Container {
    &.download-page {
        .k2-header {
            background: url('https://calmradio.com//images/Banners-instruments/piano-banner.png') no-repeat center center;
            background-size: cover;
            height: 50rem;
            position: relative;
            text-align: center;

            &:after {
                background: linear-gradient(to top, #0038a9 0%, #33d2ff 100%);
                content: "";
                display: block;
                height: 100%;
                left: 0;
                opacity: .9;
                position: absolute;
                top: 0;
                width: 100%;
            }

            .wrapper {
                margin-top: 25rem;
            }

            h1,
            p {
                color: $white;
                position: relative;
                z-index: 1;
            }
        }
    }

    .download {
        &-intro {
            align-items: center;
            border-bottom: 1px solid darken($border-light, 5%);
            display: flex;
            padding: 7rem 0;

            &-left {
                width: 42%;
            }

            &-right {
                height: auto;
                margin-left: auto;
                max-width: 734px;
                width: 55%;
            }

            p {
                font-weight: 300;
                margin-bottom: 4rem;
            }

            .btn + .btn {
                margin: 1rem 2rem 1rem 0;
            }
        }

        &-devices {
            border-bottom: 1px solid darken($border-light, 5%);
            display: flex;
            justify-content: space-between;
            list-style: none;
            margin: 0 auto;
            padding: 8rem 0;
        }

        &-device {
            figcaption {
                a {
                    color: $color-text;
                    display: block;
                    font-weight: 500;
                    padding: 2rem 0;
                    text-align: center;

                    &:active,
                    &:focus,
                    &:hover {
                        color: $blue;
                    }
                }
            }
        }

        &-other {
            margin-bottom: 15rem;
            margin-top: 15rem;

            h2 {
                font-size: 3.2rem;
                margin-bottom: 4rem;
            }

            &-list {
                display: flex;
                flex-wrap: wrap;               
                width: 80%;

                li {
                    position: relative;
                    width: 25%;

                    a {
                        color: $color-text;
                        display: block;                        

                        &:active,
                        &:focus,
                        &:hover {
                            color: $blue;
                        }
                    }
                }
            }
        }

        &-car {
            background-size: 100% auto;
            background-repeat: no-repeat;
            padding-bottom: calc(60% + 200px);
            background-position: center bottom;
            position: relative;

            &-wrapper {
                background: linear-gradient(to bottom, $color-bg-light 0% , $white 25%);
            }

            &-badge {
                display: block;
                margin: 180px auto 0 auto;
            }

            &-logo {
                display: block;
                margin: 30px auto;
            }

            .wrapper {
                left: 50%;
                position: absolute;
                text-align: center;
                top: 0;
                transform: translateX(-50%);
                width: 960px;
                z-index: 1;
            }
        }

        &-header {
            margin-bottom: -80px;
        }
    }
}

@media (max-width: 1920px) {
    #k2Container .download-car {
        padding-bottom: calc(60% + 360px);
    }
}

@media (max-width: 1440px) {
    #k2Container .download-intro,
    #k2Container .download-devices {
        padding-left: 2rem;
        padding-right: 2rem;
    }

    #k2Container .download-intro-left {
        width: 57.5%;
    }

    #k2Container .download-intro-right {
        width: 40%;
    }
}

@media (max-width: 1280px) {
    #k2Container .download-other {
        margin-bottom: 10rem;
        margin-top: 10rem;
    }

    #k2Container .download-other-list {
        width: 100%;
    }

    #k2Container .download-car-badge {
        margin: 80px auto 0;
    }
}

@media (max-width: 860px) {
    #k2Container {
        &.download-page {
            .k2-header {
                height: 35rem;

                .wrapper {
                    margin-top: 15rem;
                }
            }
        }
    }

    #k2Container .download-intro {
        flex-wrap: wrap;
        flex-direction: column-reverse;
    }

    #k2Container .download-intro-left,
    #k2Container .download-intro-right {
        text-align: center;
        width: 100%;
    }

    #k2Container .download-intro-right {
        margin-bottom: 3rem;
    }

    #k2Container .download-other-list li {
        width: 50%;
    }

    #k2Container .download-header {
        text-align: center;
    }
}

@media (max-width: 780px) {
    #k2Container .k2-header {
        height: 25rem;
    }

    #k2Container .k2-header .wrapper {
        margin-top: 10rem;
    }

    #k2Container .k2-header .wrapper h1 {
        font-size: 4rem;
    }

    #k2Container .k2-header .wrapper p {
        font-size: 1.4rem;
    }

    #k2Container .download-intro h2 {
        font-size: 3.2rem;
    }
}

@media (max-width: 720px) {
    #k2Container .download-car {
        padding-bottom: calc(60% + 480px);
    }
}

@media (max-width: 640px) {
    .download-devices {
        flex-wrap: wrap;

        li {
            width: 50%;

            figure {
                text-align: center;
            }
        }
    }
}

@media (max-width: 480px) {
    .download-devices {
        li {
            width: 100%;
        }
    }

    #k2Container .download-other-list li {
        width: 100%;
    }

    .download-intro-left {
        .btn {
            margin: 1rem 0!important;
            width: 100%;
        }
    }
}

@media (max-width: 420px) {
    #k2Container .download-car {
        padding-bottom: calc(60% + 580px);
    }
}
