:root{
    --swiper-navigation-sides-offset: 25px;
}

html {
    scroll-behavior: smooth;
}
body{
    background-color: #fff;
}
h1, h2, h3, h4, h5{
    color: #404040;
}

h2{
    font-size: 3.5rem;
    &.section-headline{
        font-size: 4rem;
        text-transform: uppercase;
        text-align: center;
        margin: 5rem 0;
        @media (max-width: 1600px) {
            font-size: 3rem;
            margin: 8rem 10%;
        }
        @media (orientation: landscape) {
            br{
                display: none;
            }
        }
    }
}
.no-select{
    user-select: none;
}
.view-checkout{
    .page-header-v3{
        background-position: center top !important;
        flex-direction: column;
    }
}
.page-header{
    .pricing-section-v3{
        #pricing-page-view-our-memberships{
            background: transparent !important;
            position: relative;
            left: 0;
            bottom: 0;
            width: 100%;
            padding: 0 0 25px;
            margin-top: auto;
            > span{
                color: #fff;
                font-weight: 500;
                &:before,
                &:after {
                    display: none;
                }
            }
            .line-wrapper {
                display: flex;
                width: 126px;
                position: relative;
                margin: 0 auto;
                height: 12px;
                align-items: center;
                justify-content: space-between;
                .line {
                    width: 55px;
                    height: 1px;
                    background-color: #fff;
                    transform: translateY(-1px);
                }
                .square {
                    width: 12px;
                    height: 12px;
                    border: 1px solid #fff;
                    border-left-width: 0;
                    border-top-width: 0;
                    transform: rotate(45deg);
                    margin: 0 1px;
                }
            }
        }
    }
} 
.next-section-wrapper{
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 2;
    padding: 3rem 0;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
}
.xl-pt-100{
    padding-top: 100px;
    @media (max-width: 1980px) AND (max-height: 1440px) {
        padding-top: 0;
    }
}
.pricing-section-v3{
    color: #404040;
    position: relative;
    max-width: 100%;
    overflow: hidden;
    box-sizing: border-box;
    &.h-full{
        //@media (max-width: 1980px) AND (max-height: 1440px) {
        @media (max-height: 1440px) {
            min-height: 100vh;
        }
    }
    .backdrop-banner{
        display: flex;
        justify-content: center;
        align-items: center;
        //background-image: url('../images/pricing/channel-list-bg.jpg');
        //background-size: cover;
        //background-position: center center;
        position: relative;
        height: 300px;
        &:after{
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: rgba(0, 0, 0, 0.35);
            z-index: 1;
        }
        video{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        .banner-headline{
            color: #fff !important;
            position: relative;
            margin: 0;
            z-index: 1;
            font-size: 5rem;
            text-align: center;
            z-index: 2;
        }
        @media (max-width: 1600px) {
            padding: 10rem 0;
            font-size: 3.5rem;
            .banner-headline{
                font-size: 4rem;
            }
        }
    }
    &.testimonials{
        overflow: hidden;
        display: flex;
        flex-direction: column;
        .section-headline{
            margin: 8rem 0;
        }
        .swiper-wrapper{
            align-items: stretch;
        }
        blockquote{
            padding: 4rem;
            text-align: center;
            position: relative;
            background-color: #f7f7f7;
            border-radius: 18px;
            height: auto;
            font-weight: 400;
            margin-top: 0;
            display: flex;
            flex-direction: column;
            gap: 1.5rem;
            @media (max-width: 1600px) {
                padding: 2rem;
            }
            @media (max-width: 1280px) {
                width: 70%;
            }
            @media (max-width: 640px) {
                width: 75%;
            }
            .testimonial-title{
                font-size: 2.5rem;
                font-weight: 700;
            }
            .testimonial-rating{
                display: flex;
                justify-content: center;
                gap: .5rem;
            }
            .rating-star{
                width: 20px;
                height: 20px;
            }
            p{
                line-height: 1.3;
                margin-top: 0;
            }
            cite{
                margin-top: 0;
            }
            .testimonials-slider-item-footer{
                cite{
                    font-size: 2rem;
                    font-weight: 700;
                }
            }
        }
        .swiper-controls{
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 435px;
            position: relative;
            margin: 2rem auto;
            gap: 4rem;
            max-width: 100%;
            @media (max-width: 768px) {
                max-width: 75%;
            }
            .swiper-button-prev,
            .swiper-button-next{
                position: relative;
                width: 22px;
                height: 22px;
                background-image: url(../images/pricing/arrow.svg);
                background-position: center center;
                background-repeat: no-repeat;
                &:after{
                    display: none;
                }
            }
            .swiper-button-prev{
                transform: rotate(180deg);
            }
            .swiper-button-next{
                margin-right: -2px;
            }
            .swiper-scrollbar{
                position: relative;
                bottom: 5px;
                .swiper-scrollbar-drag{
                    background-color: #767676;
                }
            }
        }
        .arrow-wrapper{
            background-color: #a3a3a3;
        }
        .next-section-wrapper{
            position: relative;
            padding: 4rem 0 6.5rem;
        }
    }
    &.faq{
        background-color: #f7f7f7;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .section-headline{
            margin: 8rem 0;
        }
        .pricing-faq-v3{
            gap: 2.5rem;
        }
        .container{
            max-width: 1600px;
            position: relative;
            margin: 0 auto;
            //padding-bottom: 100px;
        }
        .next-section-wrapper{
            position: relative;
            padding: 6rem 0;
        }
        .arrow-wrapper{
            background-color: #a3a3a3;
        }
        @media (max-width: 1280px) {
            //padding: 5.2rem 0 100px;
        }
    }
    &.showcase{
        background-image: url(../images/pricing/showcase-bg.jpg);
        background-position: center center;
        background-size: cover;
    }
    &.highlights{
        display: flex;
        align-items: center;
        //padding-bottom: 10rem;
        flex-direction: column;
        justify-content: flex-end;
        .container {
            max-width: 1600px;
            position: relative;
            margin: 0 auto;
            box-sizing: border-box;
            @media (max-width: 1280px) {
                padding: 0 2rem 0rem;
            }
        }
        .highlights{
            &__grid{
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                grid-auto-rows: min-content;
                @media (max-width: 1280px) {
                    grid-template-columns: repeat(2, 1fr);
                }
                @media (max-width: 640px) {
                    grid-template-columns: repeat(1, 1fr);
                }
            }
            &__box{
                padding: 1.5rem;
                transition: all .25s ease;
                &:hover{
                    padding: 0;
                    p{
                        margin-top: 3rem;
                        @media (max-width: 1280px) {
                            margin-top: 1.6rem;
                        }
                    }
                }
                &-content{
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    padding: 1.5rem;
                    border-radius: 11px;
                    backdrop-filter: blur(20px);
                    background-color: rgba(0,0,0,.2);
                }
                img,
                video{
                    border-radius: 7px;
                }
                p{
                    color: #fff;
                    font-weight: 700;
                    text-align: center;
                    line-height: 1.3;
                    transition: all .25s ease;
                }
                .video-box{
                    position: relative;
                    img{
                        opacity: 0;
                    }
                    video{
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
            }
        }
        .next-section-wrapper{
            position: relative;
            padding: 5rem 0 6.5rem;
        }
    }
    .photo-gallery{
        height: 100vh;
        @media (max-width: 768px) {
            height: auto;
            grid-template-columns: repeat(1, 1fr);
            .gallery-box{
                height: 85vh;
            }
        }
        overflow: hidden;
        display: grid;
        grid-template-columns: 1fr 0px 1fr 0px 1fr;
        position: relative;
        .gallery-box{
            background-position: center center;
            background-repeat: no-repeat;
            background-size: cover;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            position: relative;
            container: gallery-box / size;
            transition: all .25s ease;
            overflow: hidden;
            z-index: 1;
            &.gallery-box-1{
                background-image: url(../images/pricing/woman-with-phones.jpg);
                &:after {
                    transform-origin: bottom right;
                }
                @media (max-width: 768px) {
                    p{
                        transform: translateY(-7rem);
                    }
                }
            }
            &.gallery-box-2{
                background-image: url(../images/pricing/sharks.jpg);
                @media (max-width: 768px) {
                    p{
                        transform: scale(.9);
                    }
                }
            }
            &.gallery-box-3{
                background-image: url(../images/pricing/woman-in-field.jpg);
            }
            &:after {
                content: "";
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                background: inherit;
                background-size: cover;
                transform-origin: bottom center;
                transition: transform .25s ease-in-out;
            }
            p{
                color: #fff;
                font-size: 10cqw;
                font-weight: 700;
                transition: all .25s ease;
                line-height: 1.1;
                opacity: 0;
                z-index: 2;
                margin: 0;
                text-align: center;
                @media (max-width: 768px) {
                    font-size: 12cqw;
                }
            }
            &:hover{
                &:after {
                    transform: scale(1.05);
                }
                p{
                    opacity: 1;
                    transform: translateY(0);
                }
                &.gallery-box-1{
                    background-size: 120%;
                    @media (max-width: 768px) {
                        p{
                            transform: translateY(-7rem);
                        }
                    }
                }
                &.gallery-box-2{
                    @media (max-width: 768px) {
                        p{
                            transform: scale(1);
                            transform: translateY(-8rem);
                        }
                    }
                }
                .mini-player__thumb{
                    transform: translate(20.5%, -42%); 
                }
                .btn-back{
                    transform: rotate(180deg) translateX(25%);
                }
                .btn-next{
                    transform: translateX(25%);
                }
                @media (max-width: 768px) {
                    .mini-player{
                        padding-bottom: 65%;
                        &__thumb{
                            transform: translate(32%, -50%);
                        }
                        &__controls{
                            bottom: 11%;
                        }
                    }
                }
                .soundscapes-wrapper{
                    border-radius: 9999em;
                    box-shadow: 0px 3px 60px #21C0FFAF;
                    transform: scale(1.7);
                    @media (max-width: 768px) {
                        transform: scale(1.8);
                    }
                }
            }
            &.gallery-box-3{
                p{
                    transform: translateY(3rem);
                }
                &:hover{
                    p{
                        transform: translateY(0);
                    }
                }
            }
        }
        .photo-gallery-divider{
            z-index: 2;
            overflow: visible;
            img{
                width: 5cqw;
                max-width: 9999rem;
                transform: translate(-2.5cqw, -50%);
                position: relative;
                top: 50%;
            }
            @media (max-width: 768px) {
                height: 0px;
                img{
                    width: 80px;
                    left: 50%;
                    transform: translate(-50%,-50%);
                }
            }
        }
    }
    .mini-player-wrapper{
        position: absolute;
        width: 35%;
        bottom: 10%;
        z-index: 1;
        @media (max-width: 768px) {
            width: 60%;
        }
        .mini-player{
            position: relative;
            padding-bottom: 100%;
            transition: all .25s ease;
            @media (max-width: 768px) {
                padding-bottom: 75%;
            }
            &__content{
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                box-shadow: 0px 3px 6px #00000029;
                border-radius: 10%;
                backdrop-filter: blur(20px);
                -webkit-backdrop-filter: blur(20px);
                background-color: #76a1ae;
            }
            &__thumb{
                width: 70%;
                border-radius: 18px;
                position: relative;
                padding-bottom: 70%;
                transform: translate(20.5%, -34%);
                background-image: url(../images/pricing/dream-piano.jpg);
                background-size: cover;
                transition: all .25s ease;
                @media (max-width: 768px) {
                    width: 60%;
                    padding-bottom: 60%;
                    transform: translate(32%, -50%);
                }
            }
            &__controls{
                position: absolute;
                bottom: 10%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                box-sizing: border-box;
                padding: 0 17%;
                transition: all .25s ease;
                @media (max-width: 768px) {
                    bottom: 18%;
                    padding: 0 24%;
                }
            }
            &__btn{
                width: 15%;
                padding-bottom: 15%;
                position: relative;
                background-size: cover;
                transition: all .25s ease;
                @media (max-width: 768px) {
                    width: 20%;
                    padding-bottom: 15%;
                }
                &.btn-back{
                    background-image: url(../images/pricing/forward.svg);
                    transform: rotate(180deg);
                }
                &.btn-play-pause{
                    width: 28%;
                    padding-bottom: 28%;
                    background-image: url(../images/pricing/play.svg);
                    @media (max-width: 768px) {
                        width: 40%;
                        padding-bottom: 40%;
                    }
                }
                &.btn-next{
                    background-image: url(../images/pricing/forward.svg);
                }
            }
        }
    }
    .soundscapes-wrapper{
        position: absolute;
        width: 16%;
        padding-bottom: 16%;
        bottom: 21%;
        background-image: url(../images/pricing/soundscapes.png);
        background-position: center center;
        background-size: cover;
        transition: all .25s ease;
        z-index: 1;
    }
    .pricing-faq-v3{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 1.5rem;
        @media (max-width: 1280px) {
            padding: 0 4rem;
        }
        @media (max-width: 640px) {
            padding: 0 2rem;
        }
        &__item{
            max-width: 47%;
            background: #fff;
            border-radius: 18px;
            text-align: center;
            box-sizing: border-box;
            padding: 2rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 2rem;
            box-sizing: border-box;
            flex: auto;
            > *{
                margin: 0;
                line-height: 1.3;
            }
            &-title{
                font-size: 2.7rem;
            }
        }
        @media (max-width: 1280px) {
            &__item{
                max-width: 100%;
                padding: 1.5rem;
                &-title{
                    font-size: 2.6rem;
                }
            }
        }
    }
}
:root{
    --swiper-navigation-size: 22px;
}
.app-block-v3{
    background-color: transparent;
    background-image: url(../images/pricing/showcase-bg-1.jpg);
    background-position: center center;
    background-size: cover;
    padding: 11rem 0 12rem;
    position: relative;
    .wrapper{
        width: 1600px;
        @media (max-width: 768px) {
            padding: 0;
        }
    }
    .app-block-content{
        padding: 0;
        width: 768px;
        margin: 0 auto;
        max-width: 100%;
        position: relative;
        text-align: center;
        line-height: 1.3;
        color: #fff;
    }
    .app-block-title{
        color: currentColor;
        text-transform: uppercase;
        font-weight: 700;
        margin-bottom: 2rem;
    }
    .app-block-desc{
        font-size: 1.8rem;
        line-height: inherit;
        font-weight: inherit;
        margin-bottom: 5rem;
    }
    .app-block-links-wrapper{
        display: flex;
        flex-direction: column;
        gap: 3rem;
    }
    .app-block-links{
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 4rem;
        > a{
            margin: 0;
            &.apple-store-link,
            &.google-play-link{
                img{
                    height: 54px;
                }
            }
        }
    }
    .app-block-visual{
        width: 100%;
    }
    .app-block-visual-os-list{
        position: relative;
        margin-top: 0;
        max-width: 100%;
        overflow-x: hidden;
        //flex-wrap: wrap;
        .swiper-wrapper{
            align-items: end;
            @media (min-width: 768px) {
                flex-wrap: wrap;
            }
            @media (max-width: 1280px) {
                //flex-wrap: wrap;
            }
            @media (max-width: 768px) {
                //flex-wrap: nowrap;
            }
            @media (min-width: 1280px) {
                //flex-wrap: nowrap;
            }
            .swiper-slide{
                padding-top: 5rem;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                @media (max-width: 1600px) {
                    width: 25%;
                }
                @media (max-width: 768px) {
                    width: 50%;
                }
                img{
                    transition: all .25s ease;
                    display: block;
                    position: relative;
                    margin: 0 auto;
                }
                &:hover{
                    img{
                        transform: scale(1.3);
                        transform-origin: bottom center;
                    }
                    .os-name{
                        background-color: #409CDC;
                    }
                }
                &.apps-windows{
                    img{
                        transform: translateY(2rem);
                    }
                    &:hover{
                        img{
                            transform: scale(1.5) rotate(10deg) translateY(-1.5rem);
                            transform-origin: center center;
                        }
                    }
                }
                .os-name{
                    align-self: center;
                    display: inline-flex;
                    justify-content: center;
                    align-items: center;
                    width: 150px;
                    height: 52px;
                    background-color: #adb1b1;
                    box-shadow: 0px 3px 10px #00000029;
                    border-radius: 11px;
                    opacity: 1;
                    font-size: 2rem;
                    font-weight: 700;
                    color: #fff;
                    backdrop-filter: blur(20px);
                    margin-top: 2rem;
                    transition: all .25s ease;
                }
            }
        }
        .swiper-controls{
            .swiper-button-prev,
            .swiper-button-next{
                color: #fff;
                opacity: 0;
                pointer-events: none;
                font-weight: 700;
                @media (max-width: 768px) {
                    opacity: 1;
                    pointer-events: all;
                }
            }
            .swiper-button-prev{
                left: 85px;
            }
            .swiper-button-next{
                right: 85px;
            }
        }
    }
    .arrow-wrapper{
        background-color: #a3a3a3;
    }
}
.arrow-wrapper-container{
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    height: 60px;
    text-align: center;
    z-index: 1;
}
.arrow-wrapper{
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 36px;
    height: 36px;
    background-color: rgba(255, 255, 255, 0.15);
    border-radius: 50%;
    box-shadow: 0px 3px 6px #00000029;
    backdrop-filter: blur(30px);
    -webkit-backdrop-filter: blur(30px);
}
.footer{
    .footer-menu-right{
        ul{
            margin: 0;
            gap: 4rem;
            li{
                a{
                    background-size: 26px 26px;
                    display: block;
                    height: 26px;
                    overflow: hidden;
                    text-indent: -20rem;
                    width: 26px;
                    &[href*="facebook.com"]{
                        background-image: url(../images/pricing/social-icon-facebook.png);
                    }
                    &[href*="twitter.com"]{
                        background-image: url(../images/pricing/social-icon-twitter.png);
                    }
                    &[href*="instagram.com"]{
                        background-image: url(../images/pricing/social-icon-instagram.png);
                    }
                }
            }
        }
    }
}

.view-checkout{
    .mod-calmradio-popup-header{
        margin-top: 100px;
        flex-grow: 1;
        @media (max-width: 1440px){
            padding: 0;
            @media (orientation: portrait) {
                margin-top: 120px;
            }
            @media (orientation: landscape) {
                margin-top: 60px;
            }
        }
    }
}
