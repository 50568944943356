a {
    color: $blue;
    text-decoration: none;

    &:active,
    &:focus,
    &:hover {
        color: $gray;
        text-decoration: underline;
    }

    &.inverse {
        color: $gray;
        text-decoration: none;

        &:active,
        &:focus,
        &:hover {
            color: $blue;
            text-decoration: underline;
        }
    }
}

p,
ul,
ol,
dl,
table,
hr {
    margin-top: 1rem;
}

p {
    font-size: 1.8rem;
    line-height: 1.6;
    margin-top: 2.4rem;
}

blockquote{
    font-size: 1.8rem;
    font-weight: 500;
    margin-top: 2.4rem;
    margin-bottom: 2.4rem;
    padding: 0 10%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: $dark;
    font-family: $font-base;
    font-weight: 500;
    line-height: 1.3;
    margin-top: 1rem;
}

h1,
h2.heading-title {
    font-size: 5rem;
}

h2.heading-title {
    font-size: 4.2rem;
}

h3.heading-title {
    font-size: 3.2rem;
}

h2 {
    font-size: 5.6rem;
    font-weight: 600;
}

h3 {
    font-size: 4.6rem;
}

h4 {
    font-size: 3.6rem;
}

h5 {
    font-size: 2.8rem;
}

h6 {
    font-size: 2.25rem;
}

h2,
h3,
h4,
h5,
h6 {
    margin-top: 5.2rem;
}

b,
strong {
    font-weight: 700;
}

blockquote {
    font-family: $font-base;
    font-weight: 500;
    padding: 0 10%;

    cite {
        display: block;
        font-size: 90%;
        font-style: normal;
        padding-top: 1rem;
    }
    & > :nth-child(1) {
        margin-top: 0;
    }
}

ul,
ol {
    margin-left: 2rem;

    & > li {
        list-style: inherit;
        padding: 0 0 1rem 4rem;
    }
}

dl {
    dt {
        font-weight: 700;
    }
}

pre {
    margin: 2rem;
    padding: 6rem 12rem;
    white-space: pre-wrap;
    word-wrap: break-word;
}

code,
pre {
    background-color: $gray-light;
    font-family: $monospace;
    font-size: 90%;
}

sub,
sup {
    font-size: 65%;
}

hr {
    border: 0;
    height: 0;
    border-top: 1px solid $border;
    border-bottom: 1px solid rgba(white, 0.3);
}

@media (max-width: 1280px), (max-height: 900px) {
    h1,
    h1.heading-title { font-size: 4.4rem; }
    h2.heading-title { font-size: 3.6rem; }
    h3.heading-title { font-size: 2.4rem; }
    h2 { font-size: 4.5rem; }
    h3 { font-size: 4rem; }
    h4 { font-size: 3rem; }
    h5 { font-size: 2rem; }
    h6 { font-size: 1.7rem; }

    h2,
    h3,
    h4,
    h5,
    h6 {
        margin-top: 3.2rem;
    }
}

@media (max-width: 1040px), (max-height: 800px) {
    h1,
    h1.heading-title { font-size: 4.2rem; }
    h2.heading-title { font-size: 2.8rem; }
    h3.heading-title { font-size: 2.2rem; }
    h2 { font-size: 3.6rem; }
    h3 { font-size: 3rem; }
    h4 { font-size: 2.4rem; }
    h5 { font-size: 1.8rem; }
    h6 { font-size: 1.4rem; }

    h2,
    h3,
    h4,
    h5,
    h6 {
        margin-top: 2.4rem;
    }

    p {
        font-size: 1.6rem;
        margin-top: 1.6rem;
    }

    blockquote{
        font-size: 1.6rem;
        margin-top: 1.6rem;
        margin-bottom: 1.6rem;
    }
}

@media (max-width: 720px), (max-height: 700px) {
    h1,
    h1.heading-title { font-size: 3.6rem; }
    h2.heading-title { font-size: 2.4rem; }
    h3.heading-title { font-size: 2rem; }
    h2 { font-size: 2.4rem; }
    h3 { font-size: 2rem; }
    h4 { font-size: 1.8rem; }
    h5 { font-size: 1.6rem; }
    h6 { font-size: 1.6rem; }
}

@media (max-width: 640px), (max-height: 640px) {
    h1.heading-title { font-size: 3rem; }
    h2.heading-title { font-size: 2rem; }
    h3.heading-title { font-size: 1.8rem; }
}

@media (max-width: 480px), (max-height: 400px) {
    h1.heading-title { font-size: 2.4rem; }
    h2.heading-title { font-size: 1.8rem; }
    h3.heading-title { font-size: 1.6rem; }
}

@media (max-width: 400px), (max-height: 300px) {
    h1.heading-title { font-size: 2.2rem; }
    h2.heading-title { font-size: 1.6rem; }
    h3.heading-title { font-size: 1.4rem; }
}
