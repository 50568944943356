.calmradio-channels {
    background: $white;
    padding: 3.2rem 10rem 8rem 16rem;
    overflow: hidden;

    &[data-show-hidden-items="true"] {
        .channels-hidden-label-show,
        .channels-hidden-icon-show {
            display: none;
        }

        .channels-hidden-label-hide,
        .channels-hidden-icon-hide {
            display: block;
        }
    }

    &[data-show-hidden-items="false"] {
        .channels-hidden-label-show,
        .channels-hidden-icon-show {
            display: block;
        }

        .channels-hidden-label-hide,
        .channels-hidden-icon-hide {
            display: none;
        }
    }

    header {
        float: left;
        margin-right: 12rem;
    }

    h2 {
        margin: 0;
    }

    svg {
        &.is-solid {
            fill: $color-text;
        }
    }

    &-more {
        margin: 8rem 0 0 0;
        opacity: 1;
        text-align: center;
        transition: all .5s ease-out;

        .btn {
            &:active,
            &:focus,
            &:hover {
                color: $white;
            }
        }

        &.is-hidden {
            opacity: 0;
            pointer-events: none;
            visibility: hidden;
        }
    }

    &-filter {
        padding: 2.6rem 0 0 0;
        position: relative;
        z-index: 100;

        &.is-hidden {
            opacity: 0;
            pointer-events: none;
            visibility: hidden;
        }

        &-input {
            display: flex;
            margin-top: -1rem;

            &-content {
                align-items: center;
                border-bottom: 1px solid $border-medium;
                color: $color-text-light;
                cursor: pointer;
                display: inline-flex;
                padding: .8rem 0;
                position: relative;

                &:after {
                    color: $color-text;
                    content: "▼";
                    font-size: 1rem;
                    position: absolute;
                    right: 0!important;
                    top: 50%;
                    transform: translateY(-50%);
                    will-change: opacity;
                }
            }

            .channels-favorites,
            .channels-hidden {
                align-items: center;
                color: $color-text-light;
                display: flex;
                margin-right: 4.5rem;
                margin-top: .3rem;
                position: relative;
                top: -2px;

                &-icon {
                    fill: $pink!important;
                    position: relative;
                    top: 0;
                    transform: scale(.75);

                    &-wrapper {
                        align-items: center;
                        border: 1px solid lighten($gray-medium, 25%);
                        border-radius: 50%;
                        display: flex;
                        height: 3rem;
                        justify-content: center;
                        margin-right: 1rem;
                        width: 3rem;
                    }
                }

                &:active,
                &:focus,
                &:hover {
                    color: $blue;
                    text-decoration: none;

                    .channels-favorites-icon,
                    .channels-hidden-icon {
                        fill: currentColor!important;
                    }
                }
            }

            .channels-hidden {
                &-icon {
                    fill: $gray-medium!important;
                }

                &:active,
                &:focus,
                &:hover {
                    color: $gray;
                }
            }

            &.is-hidden {
                opacity: 0;
                pointer-events: none;
            }
        }

        &-selected {
            color: $color-text;
            padding: 0 3.5rem 0 4.5rem;
        }
    }

    &-groups {
        clear: both;
        display: flex;
        flex-wrap: wrap;
        margin: 6.2rem 0 0 calc(-2rem / 6);

        &.is-hidden {
            display: none;
        }
    }

    &-search {
        &-wrapper {
            margin-right: 4.5rem;
            position: relative;
            width: 300px;

            &.is-active {
                .calmradio-channels-search-icon-active {
                    display: block;
                }

                .calmradio-channels-search-icon-inactive {
                    display: none;
                }
            }

            &.is-loading {
                opacity: .25;
                pointer-events: none;
            }
        }

        &-icon {
            &-active,
            &-inactive {
                cursor: pointer;
                height: 15px;
                position: absolute;
                right: 0;
                top: 1.5rem;
                width: 15px;
                z-index: 1;
            }

            &-active {
                display: none;
                overflow: hidden;
                right: .2rem;

                &:before,
                &:after {
                    background: $dark;
                    content: "";
                    left: 50%;
                    height: 15px;
                    position: absolute;
                    top: 50%;
                    transform: translateX(-50%) translateY(-50%) rotate(45deg);
                    transform-origin: 50% 50%;
                    width: 2px;
                }

                &:after {
                    transform: translateX(-50%) translateY(-50%) rotate(-45deg);
                }

                &:hover {
                    &:before,
                    &:after {
                        background: $blue;
                    }
                }
            }

            &-inactive {
                display: block;
                pointer-events: none;

                & > svg {
                    display: block;
                    fill: $dark;
                    margin-left: -3px;
                }
            }
        }

        &-results {
            opacity: 0;

            &.is-hidden {
                display: none;
            }

            &-wrapper {
                display: flex;
                flex-wrap: wrap;
                margin: 0 -0.8rem;
                padding: 4.8rem 0;
                width: calc(100% + 1.6rem);
            }

            &-empty-state {
                color: $border-medium;
                font-size: 2.4rem;
                font-weight: 500;
                padding: 4rem 0 6rem 0;
                text-align: center;
                
                &.is-hidden {
                    display: none;
                }
            }
        }
    }

    .calmradio-channels-rows {
        padding: 2rem 0 0 0;
        transition: opacity .5s ease-out;
        will-change: opacity;

        &.is-hidden {
            display: none;
        }

        &.is-loading {
            min-height: 200px;
            opacity: .25;
            position: relative;

            &:before {
                animation: channelsSpinner 1s linear infinite;
                border: 5px solid #333;
                border-top-color: transparent;
                border-radius: 50%;
                content: "";
                height: 48px;
                left: 50%;
                margin: -24px 0 0 -24px;
                position: absolute;
                top: 50%;
                width: 48px;
                z-index: 3;
            }
        }

        .calmradio-channels-row {
            h3 {
                color: $gray-medium;
                font-size: 1.6rem;
                margin: 3.8rem 0 0 0;

                &.is-loading {
                    animation: loadingTitle;
                    animation-iteration-count: infinite;
                    animation-duration: 2s;
                    animation-timing-function: linear;
                    color: $gray-medium;
                    will-change: color;
                }
            }

            .calmradio-channels-slider {
                margin: 0 -10rem;
                padding: 0 0 0 8.4rem;

                &-empty {
                    background: $gray-light;
                    border-radius: $border-radius-small;
                    color: $color-text-light;
                    font-size: 2.4rem;
                    margin-left: 1rem;
                    padding: 4rem;
                    text-align: center;
                    width: calc(100% - 10.4rem);
                }

                &[data-mode="list"] {
                    margin: 0 -0.8rem;
                    padding: 0;

                    .calmradio-channels-slider-empty {
                        width: 100%;
                    }
                }
            }

            .calmradio-channels-slider-inner {
                display: flex;
                padding: 2rem 0;
                position: relative;
                transform: translateX(0);
                transition: transform .5s ease-out;
                width: 100%;

                &.is-expanded {
                    &[data-mode="slider"] {
                        .calmradio-channels-slider-item {
                            &.is-active,
                            &.is-active-left,
                            &.is-active-right {
                                transform: scale(1);

                                .calmradio-channels-slider-item-fav,
                                .calmradio-channels-slider-item-like,
                                .calmradio-channels-slider-item-dislike {
                                    display: none;
                                }
                            }
                        }
                    }

                    &[data-mode="list"] {
                        .calmradio-channels-slider-item {
                            &.is-expanded-item {
                                transform: scale(1);

                                .calmradio-channels-slider-item-fav,
                                .calmradio-channels-slider-item-like,
                                .calmradio-channels-slider-item-dislike {
                                    display: none;
                                }
                            }
                        }
                    }
                }

                .calmradio-channels-slider-group {
                    display: flex;
                    width: calc(100% - 10rem);
                }

                &[data-mode="list"] {
                    flex-wrap: wrap;
                    width: 100%;
                }

                &[data-mode="slider"][data-groups="2"] {
                    width: 200%;

                    .calmradio-channels-slider-group {
                        width: calc(50% - 10rem);
                    }
                }

                &[data-mode="slider"][data-groups="3"] {
                    width: 300%;

                    .calmradio-channels-slider-group {
                        width: calc(33.333333% - 10rem);
                    }
                }

                &[data-mode="slider"][data-groups="4"] {
                    width: 400%;

                    .calmradio-channels-slider-group {
                        width: calc(25% - 10rem);
                    }
                }

                &[data-mode="slider"][data-groups="5"] {
                    width: 500%;

                    .calmradio-channels-slider-group {
                        width: calc(20% - 10rem);
                    }
                }

                &[data-mode="slider"][data-groups="6"] {
                    width: 600%;

                    .calmradio-channels-slider-group {
                        width: calc(16.666666% - 10rem);
                    }
                }

                &[data-mode="slider"][data-groups="7"] {
                    width: 700%;

                    .calmradio-channels-slider-group {
                        width: calc(14.2857142857% - 10rem);
                    }
                }

                &[data-mode="slider"][data-groups="8"] {
                    width: 800%;

                    .calmradio-channels-slider-group {
                        width: calc(12.5% - 10rem);
                    }
                }

                &[data-mode="slider"][data-groups="9"] {
                    width: 900%;

                    .calmradio-channels-slider-group {
                        width: calc(11.111111% - 10rem);
                    }
                }

                &[data-mode="slider"][data-groups="10"] {
                    width: 1000%;

                    .calmradio-channels-slider-group {
                        width: calc(10% - 10rem);
                    }
                }
            }
        }
    }

    &-slider-expanded {
        background-position: right center;
        background-repeat: no-repeat;
        background-size: auto 100%;
        height: 640px;
        opacity: 1;
        overflow: hidden;
        position: relative;
        transition: height .3s ease-out 0s, opacity .3s ease-out .3s;

        &.is-opened {
            transition: height .3s ease-out 0s, opacity .3s ease-out 0s;
        }

        &-content {
            &-play {
                font-size: 1.6rem;
                padding: 0.5rem 2.5rem 0.5rem 5rem!important;

                svg {
                    left: 1.8rem!important;
                }
            }
        }

        .calmradio-channels-slider-expanded-content-fav,
        .calmradio-channels-slider-expanded-content-like,
        .calmradio-channels-slider-expanded-content-dislike {
            svg {
                &.is-border {
                    display: block;
                    margin: 1px auto 0 auto;
                }

                &.is-solid {
                    display: none;
                }
            }
        }

        .calmradio-channels-slider-expanded-content-like {
            display: none;
        }

        &.is-favorited {
            .calmradio-channels-slider-expanded-content-fav {
                svg {
                    &.is-border {
                        display: none;
                    }

                    &.is-solid {
                        display: block;
                        margin: 4px auto 0 auto;
                    }
                }
            }
        }

        &.is-liked {
            .calmradio-channels-slider-expanded-content-like {
                cursor: auto;
                pointer-events: none;

                svg {
                    &.is-border {
                        display: none;
                    }

                    &.is-solid {
                        display: block;
                        margin: 1px auto 0 auto;
                    }
                }
            }
        }

        &.is-disliked {
            .calmradio-channels-slider-expanded-content-dislike {
                svg {
                    &.is-border {
                        display: none;
                    }

                    &.is-solid {
                        display: block;
                        margin: -1px auto 0 auto;
                    }
                }
            }
        }

        &.is-closed {
            height: 0;
            opacity: 0;
        }

        &-close {
            align-items: center;
            background: rgba(255, 255, 255, .5);
            border-radius: 50%;
            color: #555;
            display: flex;
            height: 3.2rem;
            justify-content: center;
            position: absolute;
            right: 2rem;
            top: 2rem;
            width: 3.2rem;
            z-index: 10;

            &:active,
            &:focus,
            &:hover {
                background: $white;
                color: $blue;
                text-decoration: none;
            }

            &:after {
                content: "×";
                font-size: 2.8rem;
                font-weight: 300;
                line-height: 1;
                position: relative;
                top: -2px;
            }
        }

        &-image {
            background-position: center center;
            background-repeat: no-repeat;
            background-size: cover;
            bottom: 0;
            opacity: 1;
            position: absolute;
            right: 0;
            top: 0;
            transition: opacity .3s ease-out;
            width: 60%;
            z-index: 1;

            &:after {
                background: linear-gradient(100deg, #fff 0%, #fff 15%, rgba(255, 255, 255, .66) 50%, rgba(255, 255, 255, .33) 75%, rgba(255,255,255,0) 100%);
                content: "";
                height: 100%;
                left: 0;
                position: absolute;
                top: 0;
                width: 100%;
                z-index: 2;
            }

            &.is-hidden {
                opacity: 0;
                visibility: hidden;
            }
        }

        &-content {
            left: 0;
            opacity: 1;
            padding-left: 9.2rem;
            position: absolute;
            top: 50%;
            transform: translateX(0px) translateY(-50%);
            transition: all .3s ease-out;
            z-index: 5;
            width: 100%;

            &.is-hidden {
                opacity: 0;
                transform: translateX(0px) translateY(-50%);
                visibility: hidden;
            }

            h4 {
                max-width: 40%;
            }

            &-description {
                margin-bottom: 4rem;
                margin-top: 2rem;
                max-height: 240px;
                max-width: 40%;
                overflow: auto;
            }

            &-play {
                padding-left: 7rem;
                position: relative;

                svg {
                    fill: $white;
                    left: 3rem;
                    pointer-events: none;
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                }

                &:active,
                &:focus,
                &:hover {
                    color: $white;
                }

                .btn-label {
                    pointer-events: none;
                }

                &-atmosphere {
                    display: none;
                }
            }

            &-fav,
            &-like,
            &-dislike {
                border: 1px solid lighten($gray-medium, 25%);
                border-radius: 50%;
                cursor: pointer;
                display: inline-block;
                height: 36px;
                margin-left: 3rem;
                padding: .5rem 0 0 0;
                position: relative;
                text-align: center;
                transition: opacity .5s ease-out;
                top: 1.5rem;
                width: 36px;
                will-change: opacity;

                &:hover {
                    border: 1px solid $gray-medium;
                }

                & > svg {
                    pointer-events: none;
                }
            }

            &-dislike {
                margin-left: 1rem;
                padding: 1rem 0;

                svg {
                    opacity: .4;
                }
            }

            &-like {
                display: none;
                margin-left: .5rem;
            }
        }
    }

    #calmradio-row-template {
        display: none;
    }

    .calmradio-channels-slider-expanded.is-atmosphere {
        .calmradio-channels-slider-expanded-content {
            &-play {
                &-music {
                    display: none;
                }

                &-atmosphere {
                    display: inline-block;
                    position: relative;

                    &:before,
                    &:after {
                        background: $white;
                        content: "";
                        height: .2rem;
                        left: 3rem;
                        position: absolute;
                        top: 1.5rem;
                        width: 1.4rem;
                    }

                    &:after {
                        height: 1.4rem;
                        left: 3.6rem;
                        top: 0.9rem;
                        width: .2rem;
                    }
                }
            }

            &-fav,
            &-like,
            &-dislike {
                display: none!important;
            }
        }
    }

    .channels-loading-error {
        align-content: center;
        bottom: 0;
        display: none;
        justify-content: center;
        top: 0;
        position: absolute;
        width: 100%;
        z-index: 101;
    }

    &.has-loading-error {
        position: relative;

        .channels-loading-error {
            align-content: center;
            background: rgba(255, 255, 255, .95);
            display: flex;
            justify-content: center;
            left: 0;
            width: 100%;

            &-wrapper {
                align-content: center;
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
            }

            &-heading {
                display: block;
                font-weight: normal;
                margin: 0 0 3rem 0;
                text-align: center;
                width: 100%;
            }

            &-btn {

            }
        }
    }
}

.calmradio-channels-slider-inner[data-mode="list"] {
    .calmradio-channels-slider-expanded {
        height: 680px;
        margin: 0 -9.2rem;
        padding: 0;
        width: calc(100% + 18.4rem);

        &-wrapper {
            height: 640px;
            margin: 2rem 0;
            position: relative;
        }

        &.is-closed {
            height: 0;
        }

        &.is-story {
            height: 830px;

            &.is-closed {
                height: 0;
            }

            .calmradio-channels-slider-expanded-wrapper {
                height: 790px;
            }

            .calmradio-channels-slider-expanded-image {
                bottom: 210px;

                &:before {
                    background: linear-gradient(to top,#fff 0,#fff 15%,rgba(255,255,255,.66) 50%,rgba(255,255,255,.33) 75%,rgba(255,255,255,0) 100%);
                    bottom: 0;
                    content: "";
                    height: 180px;
                    left: 0;
                    position: absolute;
                    width: 100%;
                }
            }

            .calmradio-channels-slider-expanded-content-description {
                margin-bottom: 25rem;
            }

            .calmradio-channels-slider-expanded-content-chapters {
                margin-bottom: 4rem;
                margin-top: -20rem;
            }
        }
    }
}

.calmradio-channels-groups,
.calmradio-channels-rows {
    opacity: 1;
}

.calmradio-channels-groups-wrapper,
.calmradio-channels-rows,
.calmradio-channels-search-results {
    transition: opacity .5s ease-out;

    &.to-show {
        opacity: 1;
    }

    &.to-hide {
        opacity: 0;
    }
}

@keyframes channelsSpinner {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

@keyframes loadingItem {
  0%, 100% {
      background-color: $color-bg-light;
  }

  50% {
    background-color: lighten($gray-medium, 37%);
  }
}

@keyframes loadingTitle {
  0%, 100% {
      color: lighten($gray-medium, 35%);
  }

  50% {
    color: lighten($gray-medium, 45%);
  }
}

body.player-not-loaded {
    .calmradio-channels .calmradio-channels-rows .calmradio-channels-row .calmradio-channels-slider-item-play,
    .calmradio-channels .calmradio-channels-rows .calmradio-channels-row .calmradio-channels-slider-item-play-big,
    .calmradio-channels .calmradio-channels-rows .calmradio-channels-row .calmradio-channels-slider-item-dislike,
    .calmradio-channels .calmradio-channels-rows .calmradio-channels-row .calmradio-channels-slider-item-fav,
    .calmradio-channels .calmradio-channels-rows .calmradio-channels-row .calmradio-channels-slider-item-like {
        display: none;
    }

    .calmradio-channels-slider-expanded-content-play {
        display: none;
    }
}

@media (max-width: 1800px) {
    .calmradio-channels {
        &-filter {
            &-dropdown {
                &-col {
                    width: 25%;

                    &:first-child {
                        width: 25%;
                    }
                }
            }
        }
    }

    .calmradio-channels-filter-dropdown {
        left: 0;
        padding: 3rem 2rem;
    }

    .calmradio-channels-filter-dropdown-col {
        padding: 0 1rem;
    }
}

@media (max-width: 1600px) {
    .calmradio-channels {
        padding: 3.2rem 6rem 8rem 6rem;

        .calmradio-channels-rows {
            .calmradio-channels-row {
                .calmradio-channels-slider {
                    margin: 0 -6rem;
                    padding: 0 0 0 5.4rem;

                    &-empty {
                        width: calc(100% - 6.2rem);
                    }
                }

                .calmradio-channels-slider-inner .calmradio-channels-slider-group {
                    width: calc(100% - 6rem);
                }

                @for $i from 1 to 100 {
                    .calmradio-channels-slider-inner[data-mode="slider"][data-groups="#{$i}"] {
                        width: calc(100% * #{$i});

                        .calmradio-channels-slider-group {
                            width: calc((100% / #{$i}) - 6rem);
                        }
                    }
                }

                .calmradio-channels-slider-item {
                    &.is-active {
                        &-next {
                            &:before,
                            &:after {
                                height: 30px;
                                left: 20px;
                                transform: rotate(45deg) translateY(-7px);
                            }

                            &:after {
                                transform: rotate(-45deg) translateY(8px);
                                top: calc(50% - 30px);
                            }
                        }

                        &-prev {
                            &:before,
                            &:after {
                                height: 30px;
                                right: 20px;
                                transform: rotate(135deg) translateY(8px);
                            }

                            &:after {
                                transform: rotate(-135deg) translateY(-7px);
                            }
                        }
                    }
                }
            }
        }
    }

    .calmradio-channels-filter-dropdown-col>ul>li a {
        font-size: 1.4rem;
        padding: .5rem 0;
    }

    .calmradio-channels-filter-dropdown-col {
        width: 22%;
    }

    .calmradio-channels-filter-dropdown-col:first-child {
        width: 34%;
    }

    .calmradio-channels-slider-inner[data-mode="list"] {
        .calmradio-channels-slider-expanded {
            margin: 0 -5.2rem;
            width: calc(100% + 10.4rem);

            &-content {
                padding-left: 5.2rem;
            }
        }
    }
}

@media (max-width: 1280px) {
    .calmradio-channels .calmradio-channels-rows {
        padding-top: 3rem;
    }

    .calmradio-channels-filter {
        padding: 1.4rem 0 0;
    }
}

@media (max-width: 1180px) {
    .calmradio-channels-filter-dropdown {
        flex-wrap: wrap;
        height: 320px;
        overflow: auto;
        -webkit-overflow-scrolling: touch;
        padding-top: 0;
    }

    .calmradio-channels-filter-dropdown-col {
        margin-top: 3rem;
        width: 50%;
    }

    .calmradio-channels-filter-dropdown-col:first-child {
        width: 50%;
    }

    .calmradio-channels header {
        margin-right: 4rem;
    }

    .calmradio-channels-filter-input {
        flex-wrap: wrap;
    }

    .calmradio-channels-search-wrapper {
        margin-bottom: 2rem;
        margin-right: 1rem;
        width: 100%;
    }
}

@media (max-width: 1240px) {
    .calmradio-channels-slider-inner[data-mode="list"] {
        .calmradio-channels-slider-expanded {
            &.is-story {
                .calmradio-channels-slider-expanded-image {
                    bottom: 210px;
    
                    &:before {
                        height: 180px;
                    }
                }
    
                .calmradio-channels-slider-expanded-content-description {
                    margin-bottom: 25rem;
                }
    
                .calmradio-channels-slider-expanded-content-chapters {
                    margin-bottom: 4rem;
                    margin-top: -20rem;
                }
            }
        }
    }
}

@media (max-width: 1040px) {
    .calmradio-channels-filter {
        padding: 0;
    }

    .calmradio-channels-slider-expanded-content-fav {
        margin-left: 0;
    }

    .calmradio-channels-slider-expanded {
        height: 420px;
    }

    .calmradio-channels-slider-inner[data-mode="list"] {
        .calmradio-channels-slider-expanded {
            height: 460px;
    
            &-wrapper {
                height: 420px;
            }

            &.is-story {
                height: 820px;
    
                .calmradio-channels-slider-expanded-wrapper {
                    height: 780px;
                }
            }
        }
    }
}

@media (max-width: 960px) {
    .calmradio-channels-filter {
        clear: both;
        margin-top: 1rem;
    }

    .calmradio-channels-groups {
        margin-top: 4rem;
    }

    .calmradio-channels-slider-inner[data-mode="list"] {
        .calmradio-channels-slider-expanded {
            &.is-story {
                .calmradio-channels-slider-expanded-content-chapters {
                    margin-bottom: 0rem;
                    margin-top: -23rem;
                }
            }
        }
    }
}

@media (max-width: 860px) {
    .calmradio-channels-filter-dropdown {
        left: -5rem;
        padding: 0 1rem 2rem 1rem;
        width: calc(100% + 10rem);
    }

    .calmradio-channels .calmradio-channels-rows .calmradio-channels-row h3 {
        font-size: 2rem;
        margin: 2.4rem 0 .5rem;
    }

    .calmradio-channels-filter-selected {
        padding: 0 1.5rem;
    }

    .calmradio-channels-filter-input .channels-favorites {
        // margin-left: 2rem;
    }
}

@media (max-width: 720px) {
    .calmradio-channels-filter-dropdown-col {
        width: 100%;
    }

    .calmradio-channels-filter-dropdown-col:first-child {
        width: 100%;
    }

    .calmradio-channels-filter-selected {
        display: inline-block;
        max-width: 180px;
        overflow: hidden;
        position: relative;
        text-overflow: ellipsis;
        top: 0;
        white-space: nowrap;
    }

    .calmradio-channels-slider-expanded {
        margin-left: -6rem;
    }
}

@media (max-width: 640px) {
    .calmradio-channels {
        header {
            float: none;
            margin-right: 0;

            h2 {
                margin: 0 -4rem;
                text-align: center;
            }
        }
    }

    .calmradio-channels-filter {
        margin-top: 2rem;
        margin-left: -4rem;
        margin-right: -4rem;
        text-align: center;
    }

    .calmradio-channels .calmradio-channels-rows .calmradio-channels-row h3 {
        font-size: 1.5rem;
        margin: 2rem 0 .5rem;
    }

    .calmradio-channels-slider-expanded-content .calmradio-channels-slider-expanded-content-description,
    .calmradio-channels-slider-expanded-content h4 {
        max-width: 85%;
        padding-left: 2rem;
    }

    .calmradio-channels-slider-expanded-content-fav {
        margin-left: 2rem;
    }

    .calmradio-channels-slider-expanded-image {
        width: 100%;
    }

    .calmradio-channels-slider-expanded-image:after {
        background: rgba(255, 255, 255, .85);
    }

    .calmradio-channels-groups {
        margin-left: -3rem;
        margin-right: -3rem;
        padding: 0 1.6rem;
    }

    .calmradio-channels-filter-input {
        flex-wrap: wrap;
    }

    .calmradio-channels-search-wrapper {
        margin: 0 auto;
        width: 100%;
    }

    .calmradio-channels-filter-input .channels-favorites {
        margin-top: 2rem;
        margin-bottom: 2rem;
        width: 100%;
    }

    .calmradio-channels-rows {
        margin: 0 -2rem;
    }

    .calmradio-channels-slider-expanded-content-play {
        margin-left: 2rem;
        margin-right: 1rem;
    }

    .calmradio-channels-slider-inner[data-mode="list"] {
        .calmradio-channels-slider-expanded {
            margin: 0 -3.2rem;
            width: calc(100% + 6.4rem);

            &-content {
                padding-left: 3.2rem;
            }

            .calmradio-channels-slider-expanded-content .calmradio-channels-slider-expanded-content-description,
            .calmradio-channels-slider-expanded-content h4 {
                padding-left: 0;
            }

            .calmradio-channels-slider-expanded-content-fav {
                margin-left: 0;
            }
        }
    }
}

@media (max-width: 600px) {
    .calmradio-channels-filter-input .channels-favorites,
    .calmradio-channels-filter-input .channels-hidden {
        display: flex;
        margin: 1.5rem 0 0 0;
        width: 100%;
    }

    .calmradio-channels-filter-dropdown {
        left: -2rem;
        top: 40px;
        width: calc(100% + 4rem);
    }
}
