#k2Container.channels-guide-main .k2-header,
#k2Container.channels-guide-super-category .k2-header {
    height: auto;
}

#k2Container.channels-guide-main .k2-header .wrapper h2,
#k2Container.channels-guide-super-category .k2-header .wrapper h2 {
    font-size: 6.4rem;
    font-weight: 400;
}

#k2Container.channels-guide-main .channels-guide-desc,
#k2Container.channels-guide-super-category .channels-guide-desc {
    position: relative;
    z-index: 1;

    h1 {
        font-size: 4rem;
        font-weight: 400;
        text-align: center;
    }

    p {
        font-size: 2rem;
        margin: 0 auto;
        max-width: 1440px;
        text-align: center;
    }
}

#k2Container.channels-guide-main .k2-header-bg,
#k2Container.channels-guide-super-category .k2-header-bg {
    top: 150px;
}

#k2Container.channels-guide-main .channels-guide {
    margin-bottom: 120px;
}

.channels-guide {
    &-wrapper {
        margin: 100px auto;
        max-width: calc(100% - 20rem);
        width: 100%;

        .calmradio-channels-groups {
            margin: 0 -1rem!important;
            padding: 0!important;

            &-item {
                align-content: center;
                background: $color-bg-light;
                border-radius: $border-radius-small;
                cursor: pointer;
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                min-height: 6vw;
                max-height: 10rem;
                padding: 2rem 0;
                position: relative;
                transition: box-shadow .3s ease-out;
                z-index: 0;

                &-label {
                    display: block;
                    font-weight: 500;
                    opacity: 1;
                    padding: 0 .5rem;
                    pointer-events: none;
                    text-align: center;
                    transition: all .3s ease-out;
                    width: 100%;
                }

                &:hover {
                    box-shadow: 0 5px 12px rgba(0, 0, 0, .2) ;
                    z-index: 1;
                }

                &.is-loading {
                    background: $color-bg-light;
                    pointer-events: none;

                    & > * {
                        opacity: 0;
                    }
                }

                &:active,
                &:focus,
                &:hover {
                    text-decoration: none;
                }

                &.is-inactive {
                    filter: grayscale(1);
                    opacity: .5;
                }

                &-label {
                    color: $white;
                    font-weight: 400!important;
                    left: 20px;
                    position: absolute;
                    top: 15px;
                    z-index: 10;
                }

                &-bg {
                    background-repeat: no-repeat;
                    background-position: center center;
                    background-size: cover;
                    height: 100%;
                    left: 0;
                    position: absolute;
                    top: 0;
                    width: 100%;
                    z-index: 0;
                }
            }
        }
    }

    &-channels {
        margin-top: -80px;
        
        &-row {
            padding: 2rem 0 0 0;
            transition: opacity .5s ease-out;
            will-change: opacity;

            &-title {
                color: $gray-medium;
                font-size: 2.4rem;
                margin: 4.4rem 0 0.5rem 0;
            }
        }

        &-rows {
            .calmradio-channels-slider-inner {
                .calmradio-channels-slider-group {
                    display: flex;
                    width: calc(100% - 10rem);
                }
            }
        }

        &-slider {
            margin: 0 -0.8rem;
            padding: 0;

            &-inner {
                display: grid;
                grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
                padding: 2rem 0;
                position: relative;
                transform: translateX(0);
                transition: transform .5s ease-out;
                width: 100%;
            }
        }

        &-item {
            background: $color-bg-light;
            box-shadow: 0 5px 12px rgba(0, 0, 0, .2);
            border-radius: $border-radius-small;
            cursor: pointer;
            margin: .8rem;
            opacity: 1;
            position: relative;
            transition: all .5s ease-out;
            transform-origin: 49.95% 49.95%;
            transform: scale(1);
            width: 100%;
            will-change: transform;

            &-wrapper {
                align-items: flex-start;
                display: flex;
                flex-wrap: wrap;
                position: relative;
                width: 100%;
            }

            &-title {
                color: $gray-medium;
                display: block;
                font-size: 1.6rem!important;
                margin: .5rem 0!important;
                text-align: center!important;
                width: 100%;

                & > a {
                    color: $gray-medium;

                    &:active,
                    &:focus,
                    &:hover {
                        color: $blue;
                    }
                }
            }

            &:active,
            &:focus,
            &:hover {
                opacity: .66;
            }

            &.is-loading {
                animation: loadingItem;
                animation-iteration-count: infinite;
                animation-duration: 2s;
                animation-timing-function: linear;
                box-shadow: 0 5px 12px rgba(0, 0, 0, 0);

                &:nth-child(6n) {
                    animation-delay: .2s;
                }

                &:nth-child(6n + 1) {
                    animation-delay: .4s;
                }

                &:nth-child(6n + 2) {
                    animation-delay: .6s;
                }

                &:nth-child(6n + 3) {
                    animation-delay: .8s;
                }

                &:nth-child(6n + 4) {
                    animation-delay: 1s;
                }

                &:nth-child(6n + 5) {
                    animation-delay: 1.2s;
                }
            }
            
            &-bg {
                background-color: transparent;
                background-position: center center;
                background-repeat: no-repeat;
                background-size: cover;
                border-radius: $border-radius-small;
                height: auto;
                left: 0;
                opacity: 0;
                object-fit: cover;
                top: 0;
                transition: opacity .5s ease-out;
                width: 100%;
                
                &.is-loaded {
                    opacity: 1;
                }
            }
            
        }
    }
}

.channel-lp-wrapper .channels-guide-wrapper {
    max-width: 100%;
    width: 100%;
}

.channels-guide-super-category ~ .special-page-bg {
    display: none;
}

@media (max-width: 1440px) {
    .channels-guide-wrapper {
        max-width: calc(100% - 8rem);
        padding-left: 0;
        padding-right: 0;
    }
}

@media (max-width: 1240px) {
    .channels-guide-channels-slider-inner {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }

    .channels-guide-channels-item {
        margin: .5rem;
    }
}

@media (max-width: 1040px) {
    .channels-guide-channels-slider-inner {
        grid-template-columns: 1fr 1fr 1fr;
    }

    .channels-guide-channels-item {
        margin: .6rem;
    }
}

@media (max-width: 780px) {
    #k2Container .k2-header .channels-guide-wrapper {
        margin-top: 15rem;
        margin-bottom: -8rem;

        & ~ .channels-guide-wrapper {
            margin-top: 0rem;
            margin-bottom: 4rem;
        }

        &:last-child {
            margin-bottom: 4rem;
        }
    }
}

@media (max-width: 720px) {
    .channels-guide-wrapper {
        max-width: calc(100% - 4rem);
    }

    .channels-guide-channels-slider-inner {
        grid-template-columns: 1fr 1fr 1fr;
    }

    .channels-guide-channels-item {
        margin: .5rem;
    }
}

@media (max-width: 480px) {
    .channels-guide-channels-slider-inner {
        grid-template-columns: 1fr 1fr;
    }

    .channels-guide-channels-item {
        margin: .4rem;
    }
}