html {
    font-size: 62.5%;
}

body {
    background: $color-bg-light;
    color: $gray;
    font-family: $font-base;
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 1.6;

    &.is-special-page {
        overflow-x: hidden!important;
    }
}

*,
*:before,
*:after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

article,
aside,
footer,
header,
hgroup,
main,
nav,
section {
    display: block;
}

body {
    ul,
    ol {
        & > li {
            list-style: none;
            padding: 0;
        }
    }

    &.is-special-page {
        background: $white;
        height: 100vh;
        overflow: hidden;
    }

    .special-page-bg {
        left: 50%;
        max-width: 1500px;
        position: absolute;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);
    }
}

img {
    height: auto;
    max-width: 100%;
    vertical-align: top;
}

.wrapper {
    margin: 0 auto;
    max-width: 100%;
    width: $width-page;
}

a {
    transition: all .25s ease-out;

    &.with-underline {
        text-decoration: underline;
    }
}

button,
input,
select,
textarea {
    font: inherit;
}

input[type="text"],
input[type="password"],
input[type="search"],
input[type="email"],
input[type="www"],
input[type="tel"] {
    background: transparent;
    border: none;
    border-bottom: 1px solid $border-medium;
    padding: 1rem 0;
    width: 100%;

    &::-webkit-input-placeholder { color: $color-text-light; }
    &::-moz-placeholder { color: $color-text-light; }
    &:-ms-input-placeholder { color: $color-text-light; }
    &:-moz-placeholder { color: $color-text-light; }

    &:focus {
        outline: none;
        border-bottom-color: $blue;
    }

    &:disabled {
        background: $gray-light;
        cursor: not-allowed;
    }

    &.has-error {
        border-bottom-color: $color-danger;
    }
}

textarea {
    background: transparent;
    border: none;
    border-bottom: 1px solid $border-medium;
    width: 100%;

    &::-webkit-input-placeholder { color: $color-text-light; }
    &::-moz-placeholder { color: $color-text-light; }
    &:-ms-input-placeholder { color: $color-text-light; }
    &:-moz-placeholder { color: $color-text-light; }

    &:focus {
        outline: none;
        border-bottom-color: $blue;
    }

    &.has-error {
        border-bottom-color: $color-danger;
    }
}

input[type="radio"],
input[type="checkbox"] {
    display: inline-block;
    height: 1.8rem;
    margin-right: 1rem;
    width: 1.8rem;
}

.checkbox {
    position: relative;

    input[type="checkbox"] {
        cursor: pointer;
        position: absolute;
        opacity: 0;
        top: .1rem;
        z-index: 1;

        &:checked + label {
            &:before {
                background: $blue;
                border: none;
                content: "✓";
            }
        }
    }

    label {
        cursor: pointer;
        padding-left: 3.2rem;
        position: relative;
        user-select: none;

        &:before {
            border: 1px solid $border;
            border-radius: 2px;
            color: $white;
            content: "";
            display: inline-block;
            font-size: 1.4rem;
            font-weight: bold;
            left: 0;
            line-height: 1.6rem;
            height: 1.8rem;
            pointer-events: none;
            position: absolute;
            text-align: center;
            top: .1rem;
            width: 1.8rem;
        }
    }

    &.is-invalid {
        label {
            color: $color-danger;

            &:before {
                border: 1px solid $color-danger;
            }
        }
    }

    &.is-loading {
        opacity: .5;
        pointer-events: none;
    }
}

.radio-button {
    position: relative;

    input[type="radio"] {
        cursor: pointer;
        position: absolute;
        opacity: 0;
        top: .1rem;
        z-index: 1;

        &:checked + label {
            &:before {
                background: $blue;
                border: none;
                content: "";
            }

            &:after {
                background: $white;
                border-radius: 50%;
                content: "";
                left: .6rem;
                height: .6rem;
                position: absolute;
                top: .7rem;
                width: .6rem;
            }
        }
    }

    label {
        cursor: pointer;
        padding-left: 3.2rem;
        position: relative;
        user-select: none;

        &:before {
            border: 1px solid $border;
            border-radius: 50%;
            color: $white;
            content: "";
            display: inline-block;
            font-size: 1.4rem;
            font-weight: bold;
            left: 0;
            line-height: 1.6rem;
            height: 1.8rem;
            pointer-events: none;
            position: absolute;
            text-align: center;
            top: .1rem;
            width: 1.8rem;
        }
    }

    &.is-invalid {
        label {
            color: $color-danger;

            &:before {
                border: 1px solid $color-danger;
            }
        }
    }

    &.is-loading {
        opacity: .5;
        pointer-events: none;
    }
}

.italic {
    font-family: Georgia, Times, 'Times New Roman', serif;
    font-style: italic;
}

/**
 * Hide joomla edit menus in articles
 */
div[itemtype="https://schema.org/Article"] > .icons {
    display: none;
}

@media (max-height: 1100px) and (min-width: 1280px) {
    body {
        &.is-special-page {
            min-height: 1100px;
            overflow: auto;
        }
    }
}

@media (max-height: 1100px) and (max-width: 1280px) {
    body {
        &.is-special-page {
            overflow: auto;
        }
    }
}

@media (max-width: 1440px) {
    .wrapper {
        max-width: 100%;
        padding-left: 2rem;
        padding-right: 2rem;
        width: 100%;
    }
}

@media (max-width: 1040px) {
    body {
        font-size: 1.6rem;
    }
}

@media (max-width: 780px) {
    #k2Container .k2-header-bg,
    .page-header-bg,
    .cr-player-album:after,
    body .special-page-bg {
        display: none!important;
    }
}
