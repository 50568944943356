.giftcards-page {
    .page-header {
        margin: 0;
    }

    #giftcards {
        margin: 10rem auto 0 auto;
        max-width: 1400px;

        .preview-placeholder {
            font-weight: 400;
        }

        .preview-photo {
            border-radius: 5px;
            overflow: hidden;
        }

        .header {
            background: transparent;
            color: $color-text;
            font-size: 3.2rem;
            font-weight: 400!important;
            margin: 0;
            padding: 0;
        }

        .preview-message {
            font-weight: 400;
        }

        .loader {
            background: rgba(0, 0, 0, .333333);

            .ripple {
                animation: giftcards-loader 0.8s ease-in-out infinite;
                background: none;
                border-radius: 50%;
                border: 3px solid rgba(255, 255, 255, .25);
                border-top-color: $white;
                content: '';
                height: 64px;
                margin: 0;
                transform: translateX(-50%) translateY(-50%) rotate(0deg);
                width: 64px;
            }
        }

        .giftcards-content {
            .header-step {
                border-bottom: none;
                border-top: none;
                margin: 1rem 0 0 0;
                padding: 2rem 0 2rem 2.4rem;
                position: relative;
                text-indent: 0;


                &.header-step-1,
                &.header-step-2 {
                    &:before {
                        align-items: center;
                        content: "";
                        display: flex;
                        font-size: 1.8rem;
                        font-weight: bold;
                        height: 2.4rem;
                        justify-content: center;
                        left: 0;
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        width: 2.4rem;
                    }
                }

                &.header-step-1 {
                    &:before {
                        content: "1.";
                    }
                }

                &.header-step-2 {
                    &:before {
                        content: "2.";
                    }
                }
            }

            .thumbs-wrapper {
                border: 0;
                margin: 0;
                text-align: center;

                &-list {
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 2rem;

                    .giftcard-picker {
                        border: none;
                        border-radius: $border-radius-small;
                        height: auto;
                        margin: 0 2px;
                        max-width: 100%;
                        padding-bottom: 8.25%;
                        position: relative;
                        width: 15%;

                        img {
                            border-radius: $border-radius-small;
                            height: 100%;
                            left: 50%;
                            position: absolute;
                            top: 50%;
                            transform: translateX(-50%) translateY(-50%);
                            width: auto;
                        }

                        &.active {
                            border: none;
                            box-shadow: none;
                        }
                    }
                }

                .btn {
                    display: inline-block;
                    float: right;
                    margin: 1rem auto;
                    max-width: 100%;
                    width: 140px;
                }
            }

            .plans-wrapper {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;

                &:before,
                &:after {
                    display: none;
                }

                .plan-wrapper {
                    display: flex;
                    flex-wrap: wrap;
                    padding: 0;
                    width: 49%;

                    .plans {
                        background: $color-bg-light;
                        border: none;
                        display: flex;
                        margin: 0;
                        order: 2;
                        padding: 0;
                        width: 100%;

                        li {
                            border: none;
                            display: block;
                            margin: 0;
                            width: calc(100% / 3);

                            label {
                                background: $white;
                                border: 2px solid $border-medium;
                                border-radius: 24px;
                                font-size: 1.3rem;
                                font-weight: 300;
                                margin: 0 2px;
                                padding: 1rem 0;

                                &:active,
                                &:focus,
                                &:hover {
                                    background: $white;
                                    border-color: $blue;
                                    box-shadow: none;
                                    color: $blue;
                                }
                            }

                            input[type=radio]:checked + label {
                                background: $blue;
                                border-color: $blue;
                                box-shadow: none;
                                color: $white;
                            }
                        }
                    }

                    .plan-message {
                        background: transparent;
                        color: $color-text-light;
                        font-weight: 300;
                        margin: .5rem 0;
                        order: 2;
                        width: 100%;
                    }
                }
            }
        }

        .inner {
            margin-left: 0!important;
            transition: none!important;
            width: 100%;

            .step {
                display: block;
                width: 100%;

                & + .step {
                    display: none;
                }
            }
        }

        &.checkout {
            .inner {
                .step {
                    display: none;

                    & + .step {
                        display: block;
                    }

                    .right-half {
                        .checkout-header {
                            display: none;
                        }

                        label {
                            text-align: left;
                        }

                        .purchase-info {
                            margin-top: 5.5rem;
                        }
                    }

                    .left-half {
                        .checkout-header {
                            border-bottom: none;
                        }

                        .line-through,
                        .remember-forgot-links {
                            display: none;
                        }

                        .giftcards-login {
                            .message-placeholder.action-error {
                                color: $color-danger;
                            }

                            .loader {
                                background: rgba(255,255,255,.75);

                                .ripple {
                                    border: 3px solid rgba(0, 0, 0, .125);
                                    border-top-color: $gray-medium;
                                }
                            }

                            button {
                                margin-top: 1.5rem;
                                text-align: center;

                                i {
                                    display: none;
                                }
                            }
                        }

                        .giftcards-register {
                            .loader {
                                background: rgba(255,255,255,.75);

                                .ripple {
                                    border: 3px solid rgba(0, 0, 0, .125);
                                    border-top-color: $gray-medium;
                                }
                            }
                        }

                        #giftcards_register_form {
                            .tac-wrapper {
                                position: relative;

                                input[type="checkbox"] {
                                    cursor: pointer;
                                    position: absolute;
                                    opacity: 0;
                                    top: -7px;
                                    z-index: 1;

                                    &:checked + label {
                                        &:before {
                                            background: $blue;
                                            border: none;
                                            content: "✓";
                                        }
                                    }
                                }

                                label {
                                    cursor: pointer;
                                    font-weight: normal;
                                    padding-left: 3.2rem;
                                    position: relative;
                                    user-select: none;

                                    &:before {
                                        background: $white;
                                        border: 1px solid $border;
                                        border-radius: 2px;
                                        color: $white;
                                        content: "";
                                        display: inline-block;
                                        font-size: 1.4rem;
                                        font-weight: bold;
                                        left: 0;
                                        line-height: 1.6rem;
                                        height: 1.8rem;
                                        pointer-events: none;
                                        position: absolute;
                                        text-align: center;
                                        top: .1rem;
                                        width: 1.8rem;
                                    }
                                }
                            }

                            .message-placeholder.action-error {
                                color: $color-danger;
                                text-align: center;
                            }

                            .radio-wrapper {
                                text-align: left;

                                input[type="radio"] {
                                    cursor: pointer;
                                    position: absolute;
                                    opacity: 0;
                                    top: .1rem;
                                    z-index: 1;

                                    &:checked + label {
                                        &:before {
                                            background: $blue;
                                            border: none;
                                            content: "";
                                        }

                                        &:after {
                                            background: $white;
                                            border-radius: 50%;
                                            content: "";
                                            left: .6rem;
                                            height: .6rem;
                                            position: absolute;
                                            top: .7rem;
                                            width: .6rem;
                                        }
                                    }
                                }

                                label {
                                    cursor: pointer;
                                    font-size: 1.2rem;
                                    padding-left: 2.4rem;
                                    padding-right: 1rem;
                                    position: relative;
                                    user-select: none;

                                    &:before {
                                        border: 1px solid $border;
                                        border-radius: 50%;
                                        color: $white;
                                        content: "";
                                        display: inline-block;
                                        font-size: 1.4rem;
                                        font-weight: bold;
                                        left: 0;
                                        line-height: 1.6rem;
                                        height: 1.8rem;
                                        pointer-events: none;
                                        position: absolute;
                                        text-align: center;
                                        top: .1rem;
                                        width: 1.8rem;
                                    }
                                }
                            }
                        }

                        .input-wrapper {
                            input[type="text"],
                            input[type="password"],
                            input[type="search"],
                            input[type="email"],
                            input[type="www"],
                            input[type="tel"],
                            textarea {
                                background: transparent!important;
                                border: none;
                                border-bottom: 2px solid $border-medium;
                                box-shadow: none!important;
                                font-size: 1.6rem;
                                font-style: normal!important;
                                height: auto!important;
                                line-height: 1.3!important;
                                padding: 1rem 0!important;
                                width: 100%;

                                &::-webkit-input-placeholder { color: $color-text-light; }
                                &::-moz-placeholder { color: $color-text-light; }
                                &:-ms-input-placeholder { color: $color-text-light; }
                                &:-moz-placeholder { color: $color-text-light; }

                                &:focus {
                                    outline: none;
                                    border-bottom-color: $blue;
                                }
                            }
                        }
                    }
                }
            }
        }

        #plan_data {
            margin: 0;
            min-height: auto;
            padding: 0;

            .plan-data-title,
            .plan-data-desc {
                font-style: normal;
                font-weight: 400;
                text-align: left;
            }

            .plan-data-total {
                border-top: 1px solid $border-medium;
            }
        }

        .purchase-info {
            min-height: auto;
        }

        .recurring-note {
            font-size: 1.2rem;
            font-style: normal;
        }

        .form-terms label {
            font-style: normal;
            font-weight: 400;
        }

        #form_terms {
            position: relative;

            input[type="checkbox"] {
                cursor: pointer;
                position: absolute;
                opacity: 0;
                top: -7px;
                z-index: 1;

                &:checked + label {
                    &:before {
                        background: $blue;
                        border: none;
                        content: "✓";
                    }
                }
            }

            label {
                cursor: pointer;
                padding-left: 3.2rem;
                position: relative;
                user-select: none;

                &:before {
                    background: $white;
                    border: 1px solid $border;
                    border-radius: 2px;
                    color: $white;
                    content: "";
                    display: inline-block;
                    font-size: 1.4rem;
                    font-weight: bold;
                    left: 0;
                    line-height: 1.6rem;
                    height: 1.8rem;
                    pointer-events: none;
                    position: absolute;
                    text-align: center;
                    top: .1rem;
                    width: 1.8rem;
                }
            }

            .help-block.form-error {
                color: $color-danger;
                font-style: normal;
            }
        }

        .main-buttons-wrapper {
            .step-switch {
                float: left;

                &.btn-1 {
                    float: right;
                }
            }

            #btn_submit + .step-switch {
                &.btn-1 {
                    float: left;
                }
            }
        }
    }

    & ~ .modal-backdrop {
        height: 100%;
        left: 0;
        position: fixed;
        top: 0;
        width: 100%;
    }

    #giftcards_modal {
        background: $white;
        box-shadow: 0 10px 10px rgba(0, 0, 0, .25);
        border-radius: $border-radius-small;

        .header {
            padding: 3rem 4rem;
        }

        .giftcards-cats ul {
            margin: 0;
        }

        .ps-scrollbar-x-rail,
        .ps-scrollbar-y-rail {
            display: none;
        }

        .loader {
            background: rgba(255,255,255,.75);

            .ripple {
                border: 3px solid rgba(0, 0, 0, .125);
                border-top-color: $gray-medium;
            }
        }
    }

    #giftcards_general_form {
        .input-control {
            padding-left: 0;

            &.message-wrapper {
                margin: 4rem 0;
            }

            label {
                display: none;

                &[for="renew1"] {
                    display: inline-block;
                }
            }

            .input-note {
                color: $color-text-light;
                font-style: italic;
                left: auto;
                right: 0;
            }

            .input-wrapper {
                input[type="text"],
                input[type="password"],
                input[type="search"],
                input[type="email"],
                input[type="www"],
                input[type="tel"],
                textarea {
                    background: transparent!important;
                    border: none;
                    border-bottom: 2px solid $border-medium;
                    box-shadow: none!important;
                    font-size: 1.6rem;
                    font-style: normal!important;
                    height: auto!important;
                    line-height: 1.3!important;
                    padding: 1rem 0!important;
                    width: 100%;

                    &::-webkit-input-placeholder { color: $color-text-light; }
                    &::-moz-placeholder { color: $color-text-light; }
                    &:-ms-input-placeholder { color: $color-text-light; }
                    &:-moz-placeholder { color: $color-text-light; }

                    &:focus {
                        outline: none;
                        border-bottom-color: $blue;
                    }
                }

                .datepicker-trigger {
                    background-color: transparent;
                    left: 0px;
                    top: 6px;
                }

                ul {
                    display: flex;
                    margin: 2rem 0;
                    padding: 0;

                    li {
                        label {
                            left: -5px;
                            padding-right: 2rem;
                            position: relative;
                            top: -2px;
                        }
                    }
                }
            }
        }

        & ~ .price-preview {
            font-size: 2rem;
            font-weight: 400;

            .price-preview-value-wrapper {
                color: $blue;
                font-weight: bold;
            }
        }
    }

    .checkout-viewport {
        background: $white;
        box-shadow: 0 -0.5rem 4rem rgba(0, 0, 0, .065);
        border-radius: $border-radius-small;
        padding: 4rem;
    }

    .inner-buttons-wrapper button,
    .main-buttons-wrapper button {
        background: $blue!important;
    }
}

.modal-giftcards > div .giftcard-picker img {
    border-radius: 5px;
}

@keyframes giftcards-loader {
    to {
        transform: translateX(-50%) translateY(-50%) rotate(360deg);
    }
}

@media (max-width: 1440px) {
    .giftcards-page #giftcards {
        max-width: calc(100% - 40px);
    }
}

@media (max-width: 1280px) {
    .giftcards-page #giftcards .giftcards-content .plans-wrapper .plan-wrapper {
        width: 100%;
    }

    #giftcards .header.desktop-hide {
        margin: -40px 0 40px 0;
    }
}

@media (max-width: 800px) {
    #giftcards {
        padding: 0 0 50px 0;
    }

    .giftcards-page #giftcards_modal .giftcards-cats ul {
        padding: 0;
    }

    .giftcards-page #giftcards_modal .header {
        padding: 2rem 1.5rem;
    }
}

@media (max-width: 560px) {
    .page-header .wrapper h1 {
        font-size: 2.6rem;
    }

    .giftcards-page #giftcards .giftcards-content .thumbs-wrapper-list {
        flex-wrap: wrap;
    }

    .giftcards-page #giftcards .giftcards-content .plans-wrapper .plan-wrapper .plans {
        flex-wrap: wrap;

        li {
            margin: 5px 0;
            width: 100%;
        }
    }

    .giftcards-page .checkout-viewport {
        padding: 2rem;
    }

    .giftcards-page #giftcards .giftcards-content .thumbs-wrapper-list .giftcard-picker {
        height: 110px;
        padding: 0;
        width: 31%;
    }

    #giftcards .thumbs-wrapper .giftcard-picker img, #giftcards .thumbs-wrapper .see-more img {
        height: auto!important;
        max-height: 150px;
        max-width: 150px;
        width: 100%!important;
    }

    .giftcards-page #giftcards .giftcards-content .thumbs-wrapper-list {
        justify-content: center;
    }

    #giftcards .inner-buttons-wrapper.inner-buttons-wrapper button,
    #giftcards .main-buttons-wrapper.inner-buttons-wrapper button {
        margin-top: 10px;
    }
}
